<template>
    <div class="switch-button-control">
    <div class="switch-button" :class="{ enabled: isEnabled }" @click="toggle">
        <div class="button">
          <slot name="inside-button"></slot>
        </div>
    </div>
    <div class="switch-button-label ml-4">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'switch-button',
  props: {
    isEnabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.isEnabled);
    }
  }
}
</script>
<style lang="scss">

.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;

  .switch-button-label {
    flex: 1;
  }

  .switch-button {
    $switch-button-height: 1.75rem;
    $switch-button-color: #2300BC;
    $switch-button-border-thickness: 0.2rem;
    $switch-transition: all 0.25s ease-in-out;

    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2);
    border: $switch-button-border-thickness solid $switch-button-color;
    border-radius: $switch-button-height;

    transition: $switch-transition;
    opacity: 0.4;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      height: $button-side-length;
      width: $button-side-length;
      border-radius: $button-side-length;
      background: $switch-button-color;

      transition: $switch-transition;
      display: flex;
      align-items: center;
    }

    background-color: #dddddd;

    &.enabled {
      opacity: 1;
      background-color: $switch-button-color;
      box-shadow: none;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }
}
</style>
