import union from 'lodash.union';

import {
  COMPETITION_FORM_DIRTY,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_FAIL,
  FETCH_TIMEZONE,
  FETCH_STRIPE_ACCOUNTS,
  FETCH_COMPETITIONS,
  FETCH_GUEST_SCORING_LINKS,
  FETCH_GUEST_TICKET_LINKS,
  FETCH_COMPETITION,
  CREATE_SCORING_POLICY,
  LOGOUT,
  SET_LOADED,
  TOGGLE_NAV,
  FETCH_SCORE_SET,
  CREATE_SCORE_SET,
  SAVE_SCORE,
  SAVE_ATHLETE_SCORE,
  FETCH_REGISTRATION,
  FETCH_DIVISION,
  FETCH_WORKOUT,
  FETCH_SCORING_POLICY,
  FETCH_COUPON,
  FETCH_PUBLIC_COMPETITION_FIELDS,
  DELETE_WORKOUT,
  CREATE_WORKOUT,
  DELETE_DIVISION,
  CREATE_DIVISION,
  CREATE_COUPON,
  DELETE_COUPON,
  CREATE_FIELD,
  DELETE_FIELD,
  DELETE_SCORING_POLICY,
  CHANGE_REGISTRATION_DIVISION,
  FETCH_SCORING_LINK,
  FETCH_TICKET_LINK,
  SHOW_NEW_COMP_MODAL,
  HIDE_NEW_COMP_MODAL,
  FETCH_COMPETITION_FEES,
  CLEAR_COMPETITION,
  SET_REGISTRATION_ERROR,
  SET_TICKET_PURCHASE_ERROR,
  CLEAR_TICKET_PURCHASE_ERROR,
  CLEAR_REGISTRATION_ERROR,
  FETCH_COMPETITION_TRIFECTA,
  SET_FETCHING_ACCOUNT,
  SET_FETCHING_COMPETITIONS,
  DELETE_SALE_ITEM,
  NETWORK_ERROR,
  PAGE_NOT_FOUND_ERROR,
  FETCH_STAGES,
  FETCH_EVENT_CONFIGS,
  DELETE_EVENT_CONFIG,
  FETCH_DIVISION_REGISTRATIONS,
  ACCEPT_TERMS,
  FETCH_INVITATION,
  FETCH_PUBLIC_COMPETITION,
  FETCH_PUBLIC_COMPETITION_DIVISIONS,
  FETCH_PUBLIC_COMPETITION_WORKOUTS,
  FETCH_PUBLIC_COMPETITION_SCORING_POLICIES,
  FETCH_PUBLIC_COMPETITION_SALE_ITEMS,
  CLOSE_NAV,
  FETCH_PUBLIC_PROFILE,
  FETCH_SPONSORS,
  DELETE_SPONSOR,
  FETCH_AD_CAROUSELS,
  DELETE_AD_CAROUSEL,
  FETCH_AD_CAMPAIGNS,
  DELETE_AD_CAMPAIGN,
  FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS,
  UPDATE_ATHLETE_ASSIGNMENT,
  FETCH_PUBLIC_COMPETITION_JUDGES,
  ASSIGN_JUDGE,
  REMOVE_JUDGE,
  FETCH_REGISTRATION_EXPORT_STATUS,
  FETCH_SPECTATORS_EXPORT_STATUS,
  SET_NEW_VERSION_AVAILABLE,
  FETCH_PUBLIC_DIVISION,
  FETCH_DIVISION_GROUPS,
  DELETE_DIVISION_GROUP,
  SWAP_ATHLETE_ASSIGNMENTS,
  SWAPPING_ATHLETE_ASSIGNMENTS,
  DELETE_TEAM_MEMBER,
  FETCH_DAYS,
  DELETE_DAY,
  DELETE_STAGE,
  FETCH_TIME_BLOCKS,
  GENERATE_HEATS,
  DELETE_TIME_BLOCK,
  COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS,
} from '../constants';

const createPlaceholders = (state, data, dataKey) => {
  return {
    ...data[dataKey].reduce((acc, id) => {
      acc[id] = {};
      return acc;
    }, {}),
    ...state[dataKey]
  };
};

const loadCompetition = (data, full) => {
  const comp = {};

  comp.title = data.title;
  comp.id = data.id;
  comp.bannerImageUrl = data.bannerImageUrl;
  comp.mobileBannerImageUrl = data.mobileBannerImageUrl;
  comp.bannerImagePosterUrl = data.bannerImagePosterUrl;
  comp.logoImageUrl = data.logoImageUrl;
  comp.brandUrl = data.brandUrl;
  comp.logoImagePosterUrl = data.logoImagePosterUrl;
  comp.content = data.content;
  comp.competitionEmail = data.competitionEmail ? data.competitionEmail : data.ownerEmail;
  comp.competitionEmailSenderName = data.competitionEmailSenderName;
  comp.preferredCurrency = data.preferredCurrency;
  comp.stripeAccount = data.stripeAccount ? data.stripeAccount.stripeId : '';
  comp.stripeAccountCanCharge = data.stripeAccount ? data.stripeAccount.chargesEnabled : data.published; // if stripe account present, check charges enabled, otherwise if it's published that means we've allowed opted out charging
  comp.virtual = data.virtual;
  comp.published = data.published;
  comp.publishRequested = data.publishRequested;
  comp.passOnFees = data.passOnFees;
  comp.fullLink = data.fullLink;
  comp.theme = data.theme;
  comp.themeOverrides = data.themeOverrides;
  comp.themeVariables = data.themeVariables || {};
  comp.canEditPaymentsConfiguration = data.canEditPaymentsConfiguration;
  comp.userhash = data.userhash;
  comp.ownerName = data.ownerName;
  comp.ownerEmail = data.ownerEmail;
  comp.ownerUsername = data.ownerUsername;
  comp.registrationCount = data.registrationCount;
  comp.place = data.place;
  comp.venueName = data.venueName;
  comp.venueAddress = data.venueAddress;
  comp.startDate = data.dateTimeStart;
  comp.endDate = data.dateTimeEnd;
  comp.isRegistrationOpen = data.isRegistrationOpen;
  comp.registrationOpens = data.registrationOpens;
  comp.registrationCloses = data.registrationCloses;
  comp.registrationContent = data.registrationContent;
  comp.registrationWaiverContent = data.registrationWaiverContent;
  comp.distributionContent = data.distributionContent;
  comp.distributionLeaderboardContent = data.distributionLeaderboardContent;
  comp.timezone = data.timezone;
  comp.revealLeaderboards = data.revealLeaderboards;
  comp.programmingWizardComplete = data.programmingWizardComplete;
  comp.registrationWizardComplete = data.registrationWizardComplete;
  comp.created = data.created;
  comp.heatSchedulingEnabled = data.heatSchedulingEnabled;
  comp.heatScoringEnabled = data.heatScoringEnabled;
  comp.test = data.test;
  comp.permitScheduling = data.permitScheduling;

  comp.registrantsExportUrl = data.registrantsExportUrl;
  comp.registrantsLastExportedAt = data.registrantsLastExportedAt;
  comp.registrantsLastExportRequestedAt = data.registrantsLastExportRequestedAt;

  comp.hasSeenPublishRequestModal = data.hasSeenPublishRequestModal;
  comp.registrationCap = data.registrationCap;
  comp.registrationCapped = data.registrationCapped;
  comp.maxRegistrations = data.maxRegistrations;
  comp.allowRegistrationUpdates = data.allowRegistrationUpdates;
  comp.restrictCustomLeaderboardNames = data.restrictCustomLeaderboardNames;
  comp.registrationRequiresInvites = data.registrationRequiresInvites;
  comp.leaderboardFlagEnabled = data.leaderboardFlagEnabled;
  comp.sponsorshipEnabled = data.sponsorshipEnabled;
  comp.refundsEnabled = data.refundsEnabled;
  comp.ticketingEnabled = data.ticketingEnabled;
  comp.devKitEnabled = data.devKitEnabled;
  comp.platformFeeFlat = data.platformFeeFlat;
  comp.platformFeePercentage = data.platformFeePercentage;
  comp.staffSetCurrency = data.staffSetCurrency;
  comp.autoPublishEnabled = data.autoPublishEnabled;
  comp.showVideoOnLeaderboard = data.showVideoOnLeaderboard;
  comp.chargeSalesTax = data.chargeSalesTax;

  // Child Data
  comp.divisions = full ? data.divisions.map(o => o.id) : data.divisions;
  comp.workouts = full ? data.workouts.map(o => o.id) : data.workouts;
  comp.days = full ? data.days.map(o => o.id) : data.days;
  comp.eventConfigs = full ? data.eventConfigs.map(o => o.id) : data.eventConfigs;
  comp.stages = full ? data.stages.map(o => o.id) : data.stages;
  comp.scoringPolicies = full ? data.scoringPolicies.map(o => o.id) : data.scoringPolicies;
  comp.coupons = full ? data.coupons.map(o => o.id) : data.coupons;
  comp.registrationFields = full ? data.registrationFields.map(o => o.id) : data.registrationFields;
  comp.registrations = full ? data.registrations.map(o => o.id) : (data.registrations || []);
  comp.scoringLinks = full ? data.scoringLinks.map(o => o.id) : data.scoringLinks;
  comp.ticketLinks = full ? data.ticketLinks.map(o => o.id) : data.ticketLinks;
  comp.sponsors = full ? data.sponsors.map(o => o.id) : data.sponsors;
  comp.adCarousels = full ? data.adCarousels.map(o => o.id) : data.adCarousels;
  comp.adCampaigns = full ? data.adCampaigns.map(o => o.id) : data.adCampaigns;
  comp.trials = data.trials || [];
  comp.divisionGroups = data.divisionGroups;
  comp.saleItems = data.saleItems;
  comp.schedule = data.schedule;
  // comp.schedules = data.schedules;
  // comp.segments = data.segments;
  comp.hashtags = data.hashtags;

  comp.managerHashes = data.managerHashes || [];

  comp.ticketHeaderContent = data.ticketHeaderContent;
  comp.ticketSalesOpen = data.ticketSalesOpen;
  comp.ticketSalesClose = data.ticketSalesClose;

  comp.invitations = data.invitations;

  comp.metrics = {};

  comp.logs = data.logs;

  comp.customLabels = data.customLabels;
  comp.sendOrganizationRegistrationNotifications = data.sendOrganizationRegistrationNotifications;

  return comp;
};

const deleteObject = (state, dataKey, objId) => {
  const compId = state[dataKey][objId].competitionId || state[dataKey][objId].competition;
  const comp = state.competitions[compId];
  comp[dataKey].splice(comp[dataKey].indexOf(objId), 1);
  state[dataKey] = comp[dataKey].reduce((acc, id) => {
    acc[id] = state[dataKey][id];
    return acc;
  }, {});
};

const updateDivisions = (state, data) => {
  state.divisions = {
    ...state.divisions,
    [data.id]: data,
  };
};

const updateWorkouts = (state, data) => {
  state.workouts = {
    ...state.workouts,
    [data.id]: {
      ...data,
      releaseDate: data.releaseTime ? data.releaseTime.split(' ')[0] : null,
    },
  };
};

const updateStages = (state, data) => {
  const comp = state.competitions[data.competitionId];
  if (comp.stages.indexOf(data.id) === -1) {
    state.competitions = {
      ...state.competitions,
      [data.competitionId]: {
        ...comp,
        stages: [...comp.stages, data.id],
      },
    };
  }
  state.stages = {
    ...state.stages,
    [data.id]: data,
  };
};

const updateDays = (state, data) => {
  const { day, events } = data;
  const comp = state.competitions[day.competitionId];
  if (comp.days.indexOf(day.id) === -1) {
    state.competitions = {
      ...state.competitions,
      [day.competitionId]: {
        ...comp,
        days: [...comp.days, day.id],
      },
    };
  }
  state.days = {
    ...state.days,
    [day.id]: day,
  };
  const updatedEvents = events.reduce((acc, obj) => ({...acc, [obj.id]: obj}), {});
  state.eventConfigs = {
    ...state.eventConfigs,
    ...updatedEvents,
  };
};

const updateScoringPolicies = (state, data) => {
  state.scoringPolicies = {
    ...state.scoringPolicies,
    [data.id]: data,
  };
};

export default {
  [SET_NEW_VERSION_AVAILABLE]: (state) => {
    state.newVersionAvailable = true;
  },
  [CLEAR_COMPETITION]: (state, id) => {
    state.competitionOrder = [...state.competitionOrder.filter(compId => compId !== id)];
    state.competitions = state.competitionOrder.reduce((map, id) => {
      map[id] = state.competitions[id];
      return map;
    }, {});
  },

  [FETCH_COMPETITION_FEES]: (state, {id, data}) => {
    state.competitions[id] = {
      ...state.competitions[id],
      ...data,
    };
  },

  [FETCH_REGISTRATION_EXPORT_STATUS]: (state, data) => {
    state.competitions[data.id] = {
      ...state.competitions[data.id],
      registrantsExportUrl: data.registrantsExportUrl,
      registrantsLastExportedAt: data.registrantsLastExportedAt,
      registrantsLastExportRequestedAt: data.registrantsLastExportRequestedAt,
    };
  },

  [FETCH_SPECTATORS_EXPORT_STATUS]: (state, data) => {
    state.competitions[data.id] = {
      ...state.competitions[data.id],
      spectatorsExportUrl: data.spectatorsExportUrl,
      spectatorsLastExportedAt: data.spectatorsLastExportedAt,
      spectatorsLastExportRequestedAt: data.spectatorsLastExportRequestedAt,
    };
  },

  [FETCH_TIME_BLOCKS]: (state, {data, day = null, stage = null}) => {
    const timeBlocks = {...state.timeBlocks};

    // if day and/or stage are not null, then delete all existing timeblocks that
    // match as they could have been deleted by another tab
    Object.keys(timeBlocks).forEach(blockId => {
      if (day && timeBlocks[blockId].day === day) delete timeBlocks[blockId];
      if (stage && timeBlocks[blockId] && timeBlocks[blockId].stage === stage) delete timeBlocks[blockId];
    });

    // Match interface with event configs
    const decoratedData = data.map(b => ({...b, configStart: b.start, configEnd: b.end}));
    state.timeBlocks = {
      ...timeBlocks,
      ...decoratedData.reduce((m, t) => ({
        ...m,
        [t.id]: t,
      }), {}),
    };
  },
  [DELETE_TIME_BLOCK]: (state, id) => {
    state.timeBlocks = Object.keys(state.timeBlocks)
      .filter(blockId => parseInt(blockId, 10) !== id)
      .reduce((m, t) => ({
        ...m,
        [t]: state.timeBlocks[t],
      }), {});
  },

  [GENERATE_HEATS]: (state, data) => {
    state.timeBlocks = data.reduce((m, t) => ({
      ...m,
      [t.id]: t,
    }), {});
  },

  [SHOW_NEW_COMP_MODAL]: state => state.showNewCompModal = true,
  [HIDE_NEW_COMP_MODAL]: state => state.showNewCompModal = false,

  [COMPETITION_FORM_DIRTY]: (state, dirty) => {
    state.competitionSetupFormDirty = dirty;
  },
  [FETCH_COMPETITION_TRIFECTA]: (state, data) => {
    const { divisions, workouts, scoringPolicies } = data;
    divisions.forEach(division => updateDivisions(state, division));
    workouts.forEach(workout => updateWorkouts(state, workout));
    scoringPolicies.forEach(policy => updateScoringPolicies(state, policy));
  },
  [FETCH_DIVISION]: (state, data) => {
    if (data !== null) {
      updateDivisions(state, data);
    }
  },
  [CREATE_DIVISION]: (state, data) => {
    state.competitions[data.competitionId].divisions.push(data.id);
  },
  [DELETE_DIVISION]: (state, id) => {
    const compId = state.divisions[id].competitionId;
    const comp = state.competitions[compId];
    deleteObject(state, 'divisions', id);
    comp.scoringPolicies = comp.scoringPolicies
      .filter(policyId => state.scoringPolicies[policyId] && state.scoringPolicies[policyId].division !== id);
    state.scoringPolicies = Object.keys(state.scoringPolicies)
      .filter(policyId => state.scoringPolicies[policyId].division !== id)
      .reduce((map, policyId) => {
        map[id] = state.scoringPolicies[policyId];
        return map;
      }, {});
  },
  [FETCH_WORKOUT]: (state, data) => {
    if (data !== null) {
      updateWorkouts(state, data);
    }
  },
  [FETCH_DAYS]: (state, data) => {
    if (data !== null && data.length > 0) {
      data.forEach(d => updateDays(state, d));
    }
  },
  [DELETE_DAY]: (state, id) => {
    const day = state.days[id];
    const comp = state.competitions[day.competitionId];
    const days = [...comp.days];
    days.splice(days.indexOf(id), 1);
    state.competitions = {
      ...state.competitions,
      [day.competitionId]: {
        ...comp,
        days,
      },
    };
    state.days = state.competitions[day.competitionId].days.reduce((map, id) => ({
      ...map,
      [id]: state.days[id],
    }), {});
  },
  [FETCH_EVENT_CONFIGS]: (state, data) => {
    if (data !== null && data.length > 0) {
      data.forEach(eventConfig => {
        const comp = state.competitions[eventConfig.competitionId];
        if (comp.eventConfigs.indexOf(eventConfig.id) === -1) {
          state.competitions = {
            ...state.competitions,
            [eventConfig.competitionId]: {
              ...comp,
              eventConfigs: [...comp.eventConfigs, eventConfig.id],
            },
          };
        }
        state.eventConfigs = {
          ...state.eventConfigs,
          [eventConfig.id]: eventConfig,
        };
      });
    }
  },
  [DELETE_EVENT_CONFIG]: (state, id) => {
    const eventConfig = state.eventConfigs[id];
    const comp = state.competitions[eventConfig.competitionId];
    const eventConfigs = [...comp.eventConfigs];
    eventConfigs.splice(eventConfigs.indexOf(id), 1);
    state.competitions = {
      ...state.competitions,
      [eventConfig.competitionId]: {
        ...comp,
        eventConfigs,
      },
    };
    state.eventConfigs = state.competitions[eventConfig.competitionId].eventConfigs.reduce((map, id) => ({
      ...map,
      [id]: state.eventConfigs[id],
    }), {});
  },
  [DELETE_STAGE]: (state, id) => {
    const stage = state.stages[id];
    const comp = state.competitions[stage.competitionId];
    const stages = [...comp.stages];
    stages.splice(stages.indexOf(id), 1);
    state.competitions = {
      ...state.competitions,
      [stage.competitionId]: {
        ...comp,
        stages,
      },
    };
    state.stages = state.competitions[stage.competitionId].stages.reduce((map, id) => ({
      ...map,
      [id]: state.stages[id],
    }), {});
  },
  [FETCH_STAGES]: (state, data) => {
    if (data !== null && data.length > 0) {
      data.forEach(d => updateStages(state, d));
    }
  },
  [CREATE_WORKOUT]: (state, data) => {
    state.competitions[data.competitionId].workouts.push(data.id);
  },
  [DELETE_WORKOUT]: (state, id) => {
    const compId = state.workouts[id].competitionId;
    const comp = state.competitions[compId];
    deleteObject(state, 'workouts', id);
    comp.scoringPolicies = comp.scoringPolicies
      .filter(policyId => state.scoringPolicies[policyId] && state.scoringPolicies[policyId].workout !== id);
    state.scoringPolicies = Object.keys(state.scoringPolicies)
      .filter(policyId => state.scoringPolicies[policyId].workout !== id)
      .reduce((map, policyId) => {
        map[id] = state.scoringPolicies[policyId];
        return map;
      }, {});
  },
  [FETCH_SCORING_POLICY]: (state, data) => {
    if (data !== null) {
      updateScoringPolicies(state, data);
    }
  },
  [CREATE_SCORING_POLICY]: (state, data) => {
    state.competitions[data.competitionId].scoringPolicies.push(data.id);
  },
  [DELETE_SCORING_POLICY]: (state, id) => {
    deleteObject(state, 'scoringPolicies', id);
  },
  [FETCH_COUPON]: (state, data) => {
    state.coupons = {
      ...state.coupons,
      [data.id]: data
    };
  },
  [CREATE_COUPON]: (state, data) => {
    state.competitions[data.competitionId].coupons.push(data.id);
  },
  [DELETE_COUPON]: (state, id) => {
    deleteObject(state, 'coupons', id);
  },
  [FETCH_PUBLIC_COMPETITION_FIELDS]: (state, data) => {
    const fields = data.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    state.registrationFields = {
      ...state.registrationFields,
      ...fields
    };
  },
  [CREATE_FIELD]: (state, data) => {
    state.competitions[data.competitionId].registrationFields.push(data.id);
  },
  [DELETE_FIELD]: (state, id) => {
    deleteObject(state, 'registrationFields', id);
  },

  [FETCH_REGISTRATION]: (state, data) => {
    state.registrations = {
      ...state.registrations,
      [data.id]: data
    };
    if (data.scoreSets) {
      data.scoreSets.forEach(ss => {
        if (state.scoreSets[ss] && state.scoreSets[ss].athlete !== data.name) {
          state.scoreSets[ss].athlete = data.name;
        }
      });
    }
  },
  [CHANGE_REGISTRATION_DIVISION]: (state, data) => {
    if (state.divisions[data.oldDivisionId].registrations) {
      state.divisions[data.oldDivisionId].registrations = [
        ...state.divisions[data.oldDivisionId].registrations.filter(id => id !== data.id)
      ];
    }
    if (state.divisions[data.division].registrations) {
      state.divisions[data.division].registrations = [
        data.id,
        ...state.divisions[data.division].registrations
      ];
    } else {
      state.divisions[data.division].registrations = [data.id];
    }
  },

  [FETCH_GUEST_SCORING_LINKS]: (state, data) => {
    const links = data.scoringLinks.reduce((map, o) => {
      map[o.id] = o;
      return map;
    }, {});
    state.guestScoringLinks = {
      ...state.guestScoringLinks,
      ...links
    };
  },

  [FETCH_SCORING_LINK]: (state, data) => {
    state.scoringLinks = {
      ...state.scoringLinks,
      [data.id]: data
    };
  },
  [FETCH_SCORE_SET]: (state, data) => {
    // API Delivers nested Scores in each ScoreSet.
    // Normalize this data
    if (data.scores === undefined) {
      return;
    }
    const scoreSet = {
      ...data,
      scores: data.scores.map(s => s.id),
    };
    const scores = data.scores.reduce((acc, s) => {
      acc[s.id] = s;
      return acc;
    }, {});
    state.scoreSets = {
      ...state.scoreSets,
      [scoreSet.id]: scoreSet
    };
    state.scores = {
      ...state.scores,
      ...scores
    };
  },
  [CREATE_SCORE_SET]: (state, data) => {
    if (state.divisions[data.division].scoreSets.indexOf(data.id) === -1) {
      state.divisions[data.division].scoreSets.push(data.id);
    }
  },
  [SAVE_SCORE]: (state, data) => {
    state.divisions[data.division].newScores = data.newScores;
    state.scores = {
      ...state.scores,
      ...data.scores.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {}),
    };
  },

  [LOGOUT]: state => state.isAuthed = false,
  [FETCH_ACCOUNT]: (state, data) => {
    state.isAuthed = true;
    state.account = {
      ...state.account,
      ...data
    };
  },
  [FETCH_PUBLIC_PROFILE]: (state, data) => {
    state.publicProfile = data;
  },
  [FETCH_ACCOUNT_FAIL]: state => {
    state.isAuthed = false;
    state.account = {};
  },
  [FETCH_STRIPE_ACCOUNTS]: (state, data) => {
    if (data.stripeAccounts) {
      state.stripeAccountOrder = data.stripeAccounts.map(f => f.stripeId);
      state.stripeAccounts = data.stripeAccounts.reduce((map, obj) => {
        map[obj.stripeId] = obj;
        return map;
      }, {});
      state.stripeAccountOrder.forEach(stripeAccountId => {
        Object.keys(state.competitions)
          .filter(id => state.competitions[id].stripeAccount === stripeAccountId)
          .forEach(id => {
            state.competitions = {
              ...state.competitions,
              [id]: {
                ...state.competitions[id],
                stripeAccountCanCharge: state.stripeAccounts[stripeAccountId].chargesEnabled,
              }
            };
          });
      });
    }
    state.stripeConnectUrl = data.connectUrl;
  },

  [FETCH_PUBLIC_COMPETITION]: (state, data) => {
    if (state.competitionOrder.indexOf(data.id) === -1) {
      state.competitionOrder.push(data.id);
    }
    state.competitions = {
      ...state.competitions,
      [data.id]: loadCompetition(data, false)
    };

    state.divisions = createPlaceholders(state, data, 'divisions');
    state.workouts = createPlaceholders(state, data, 'workouts');
    state.scoringPolicies = createPlaceholders(state, data, 'scoringPolicies');
    // state.coupons = createPlaceholders(state, data, 'coupons');
    // state.registrationFields = createPlaceholders(state, data, 'registrationFields');
    // state.saleItems = createPlaceholders(state, data, 'saleItems');
    // state.schedules = createPlaceholders(state, data, 'schedules')

    // state.selectedTimezone = '';
  },
  [FETCH_PUBLIC_COMPETITION_DIVISIONS]: (state, data) => {
    data.forEach(division => updateDivisions(state, division));
  },
  [FETCH_PUBLIC_COMPETITION_WORKOUTS]: (state, data) => {
    data.forEach(workout => updateWorkouts(state, workout));
  },
  [FETCH_PUBLIC_COMPETITION_SCORING_POLICIES]: (state, data) => {
    data.forEach(policy => updateScoringPolicies(state, policy));
  },
  [FETCH_PUBLIC_DIVISION]: (state, data) => {
    updateDivisions(state, data);
  },

  [FETCH_COMPETITION]: (state, data) => {
    if (state.competitionOrder.indexOf(data.id) === -1) {
      state.competitionOrder.push(data.id);
    }
    state.competitions = {
      ...state.competitions,
      [data.id]: loadCompetition(data, false)
    };

    state.divisions = createPlaceholders(state, data, 'divisions');
    state.workouts = createPlaceholders(state, data, 'workouts');
    state.scoringPolicies = createPlaceholders(state, data, 'scoringPolicies');
    state.coupons = createPlaceholders(state, data, 'coupons');
    state.registrationFields = createPlaceholders(state, data, 'registrationFields');
    state.saleItems = createPlaceholders(state, data, 'saleItems');
    // state.schedules = createPlaceholders(state, data, 'schedules');
    state.divisionGroups = createPlaceholders(state, data, 'divisionGroups');

    state.selectedTimezone = '';
  },

  [FETCH_DIVISION_GROUPS]: (state, data) => {
    state.divisionGroups = {
      ...state.divisionGroups,
      ...data.reduce((map, group) => {
        return {
          ...map,
          [group.id]: group,
        };
      }, {}),
    };
  },
  [DELETE_DIVISION_GROUP]: (state, id) => {
    const compId = state.divisionGroups[id].competitionId;
    const comp = state.competitions[compId];
    deleteObject(state, 'divisionGroups', id);
    comp.divisionGroups = [...comp.divisionGroups.filter(itemId => itemId !== id)];
    state.competitions = {
      ...state.competitions,
      [comp.id]: { ...comp },
    };
  },

  [DELETE_TEAM_MEMBER]: (state, data) => {
    state.registrations = {
      ...state.registrations,
      [data.id]: data,
    };
  },

  [FETCH_PUBLIC_COMPETITION_SALE_ITEMS]: (state, data) => {
    const items = data.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    state.saleItems = {
      ...state.saleItems,
      ...items
    };
    if (data.length > 0) {
      const comp = state.competitions[data[0].competitionId];
      if (comp) {
        data.forEach(saleItem => {
          if (comp.saleItems.indexOf(saleItem.id) === -1) {
            comp.saleItems = [...comp.saleItems, saleItem.id];
          }
        });
        state.competitions = {
          ...state.competitions,
          [comp.id]: comp,
        };
      }
    }
  },
  [ASSIGN_JUDGE]: (state, data) => {
    state.judges = {
      ...state.judges,
      [data.id]: {...data},
    };
  },
  [REMOVE_JUDGE]: (state, data) => {
    state.judges = Object.keys(state.judges)
      .filter(id => id !== data.deleted)
      .reduce((m, id) => ({
        ...m,
        [id]: state.judges[id],
      }), {});
  },
  [SWAPPING_ATHLETE_ASSIGNMENTS]: (state, { id, swapWith }) => {
    state.swappingAssignments = [id, swapWith ? swapWith.id : null];
  },
  [SWAP_ATHLETE_ASSIGNMENTS]: (state, data) => {
    const athleteAssignments = data.reduce((acc, obj) => {
      acc[obj.id] = {...obj, heat: obj.heat.id};
      return acc;
    }, {});
    state.athleteAssignments = {
      ...state.athleteAssignments,
      ...athleteAssignments,
    };

    if (data[0].heat.id !== data[1].heat.id) {
      const timeBlock1 = data[0].heat.timeBlock;
      const timeBlock2 = data[1].heat.timeBlock;
      state.timeBlocks = {
        ...state.timeBlocks,
        [timeBlock1]: {
          ...state.timeBlocks[timeBlock1],
          heat: data[0].heat,
        },
        [timeBlock2]: {
          ...state.timeBlocks[timeBlock2],
          heat: data[1].heat,
        },
      };
    }

    state.swappingAssignments = [];
  },
  [FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS]: (state, data) => {
    const athleteAssignments = data.reduce((acc, obj) => {
      acc[obj.id] = obj;
      return acc;
    }, {});
    state.athleteAssignments = {
      ...state.athleteAssignments,
      ...athleteAssignments,
    };
  },
  [COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS]: (state, data) => {
    const { assignments, timeBlocks } = data;

    const stateAssignments = {...state.athleteAssignments};
    assignments.forEach(pk => delete stateAssignments[pk]);
    state.athleteAssignments = {...stateAssignments};

    const stateBlocks = {...state.timeBlocks};
    timeBlocks.forEach(pk => {
      delete stateBlocks[pk];
    });
    state.timeBlocks = {...stateBlocks};
  },
  [UPDATE_ATHLETE_ASSIGNMENT]: (state, {updated, removed, created}) => {
    state.athleteAssignments = {
      ...state.athleteAssignments,
      [updated.id]: updated,
    };
    if (created) {
      state.athleteAssignments = {
        ...state.athleteAssignments,
        [created.id]: created,
      };
    }
    if (removed && removed.length > 0) {
      state.athleteAssignments = Object.keys(state.athleteAssignments)
        .filter(id => removed.indexOf(id) === -1)
        .reduce((acc, obj) => ({...acc, [obj.id]: obj}), {});
    }
  },
  [FETCH_PUBLIC_COMPETITION_JUDGES]: (state, data) => {
    state.judges = data.reduce((acc, judge) => {
      acc[judge.id] = judge;
      return acc;
    }, {});
  },

  [DELETE_SALE_ITEM]: (state, { id, error }) => {
    if (error) {
      state.saleItemDeleteError = error;
    } else {
      state.saleItemDeleteError = null;
      const compId = state.saleItems[id].competitionId;
      const comp = state.competitions[compId];
      deleteObject(state, 'saleItems', id);
      comp.saleItems = [...comp.saleItems.filter(itemId => itemId !== id)];
      state.competitions = {
        ...state.competitions,
        [comp.id]: {...comp},
      };
    }
  },

  [FETCH_DIVISION_REGISTRATIONS]: (state, { data, division }) => {
    const registrations = { ...state.registrations };
    const div = state.divisions[division];
    const ids = div.registrations ? [ ...div.registrations ] : [];
    const { results, ...paging } = data.registrations;

    for (const registration of results) {
      registrations[registration.id] = registration;
      if (ids.indexOf(registration.id) === -1) {
        ids.push(registration.id);
      }
    }

    state.registrations = registrations;
    state.divisions[division] = {
      ...div,
      registrations: ids,
      registrationPaging: paging
    };
  },

  [SAVE_ATHLETE_SCORE]: (state, data) => {
    const index = state.account.scoreSets.findIndex(ss => ss.id === data.id);
    if (index > -1) {
      state.account.scoreSets.splice(index, 1);
    }
    state.account.scoreSets = [...state.account.scoreSets, data];

    // general scoreSets, copied from FETCH_SCORE_SET
    const scoreSet = {
      ...data,
      scores: data.scores.map(s => s.id),
    };
    const scores = data.scores.reduce((acc, s) => {
      acc[s.id] = s;
      return acc;
    }, {});
    state.scoreSets = {
      ...state.scoreSets,
      [scoreSet.id]: scoreSet
    };
    state.scores = {
      ...state.scores,
      ...scores
    };
  },
  [FETCH_COMPETITIONS]: (state, data) => {
    state.competitionOrder = union(state.competitionOrder, data.competitions);
  },
  [FETCH_TIMEZONE]: (state, timezone) => state.selectedTimezone = timezone,
  [SET_LOADED]: state => state.loaded = true,
  [TOGGLE_NAV]: state => state.navOpen = !state.navOpen,
  [CLOSE_NAV]: state => state.navOpen = false,
  [SET_REGISTRATION_ERROR]: (state, error) => state.registrationError = error,
  [CLEAR_REGISTRATION_ERROR]: (state) => state.registrationError = null,
  [SET_TICKET_PURCHASE_ERROR]: (state, error) => state.ticketPurchaseError = error,
  [CLEAR_TICKET_PURCHASE_ERROR]: (state) => state.ticketPurchaseError = null,
  [SET_FETCHING_ACCOUNT]: (state, fetching) => state.fetchingAccount = fetching,
  [SET_FETCHING_COMPETITIONS]: (state, fetching) => state.fetchingCompetitions = fetching,
  [NETWORK_ERROR]: (state) => state.networkErrored = true,
  [PAGE_NOT_FOUND_ERROR]: (state) => state.pageNotFound = true,
  // [CREATE_HEAT_SCHEDULE]: (state, data) => {
  //   state.competitions = {
  //     ...state.competitions,
  //     [data.competition]: {
  //       ...state.competitions[data.competition],
  //       schedules: [...state.competitions[data.competition].schedules, data.id],
  //     }
  //   };
  //   state.schedules = {
  //     ...state.schedules,
  //     [data.id]: data,
  //   };
  // },
  // [FETCH_SEGMENTS]: (state, data) => {
  //   const segments = data.reduce((map, obj) => {
  //     map[obj.id] = obj;
  //     map[obj.id].startDate = obj.start;
  //     map[obj.id].endDate = obj.end;
  //     return map;
  //   }, {});
  //   state.segments = {
  //     ...state.segments,
  //     ...segments,
  //   };
  //   const segmentIds = Object.keys(segments);
  //   if (segmentIds.length > 0) {
  //     const comp = state.competitions[data[0].competition];
  //     const segmentsToAdd = segmentIds.filter(id => comp.segments.indexOf(id) === -1);
  //     if (segmentsToAdd > 0) {
  //       state.competitions = {
  //         ...state.competitions,
  //         [comp.id]: {
  //           ...comp,
  //           segments: [...comp.segments, ...segmentsToAdd],
  //         }
  //       };
  //     }
  //   }
  // },
  // [SAVE_SEGMENT_ORDER]: (state, {competitionId, data}) => {
  //   const comp = state.competitions[competitionId];
  //   state.competitions = {
  //     ...state.competitions,
  //     [competitionId]: {
  //       ...comp,
  //       segments: data.segments,
  //       schedules: data.schedules,
  //     }
  //   };
  // },
  // [DELETE_SEGMENT]: (state, id) => {
  //   deleteObject(state, 'segments', id);
  //   Object.keys(state.schedules).forEach(scheduleId => {
  //     const schedule = state.schedules[scheduleId];
  //     if (schedule.segment === id) {
  //       deleteObject(state, 'schedules', schedule.id);
  //     }
  //   });
  // },

  [ACCEPT_TERMS]: (state) => {
    state.account = {
      ...state.account,
      acceptedTerms: true
    };
  },

  [FETCH_INVITATION]: (state, data) => {
    const { invitees, ...invitationData } = data;

    if (invitees === undefined) {
      state.invitations = {
        ...state.invitations,
        [invitationData.id]: invitationData,
      };
    } else {
      state.invitations = {
        ...state.invitations,
        [invitationData.id]: {
          ...invitationData,
          invitees: invitees.map(i => i.code)
        }
      };
      state.invitees = {
        ...state.invitees,
        ...invitees.reduce((map, i) => {
          map[i.code] = i;
          return map;
        }, {}),
      };
    }

    const comp = state.competitions[data.competition];
    if (comp.invitations.indexOf(data.id) === -1) {
      state.competitions = {
        ...state.competitions,
        [data.competition]: {
          ...comp,
          invitations: [...comp.invitations, invitationData.id],
        }
      };
    }
  },

  [FETCH_SPONSORS]: (state, data) => {
    state.sponsors = {
      ...state.sponsors,
      ...data.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {})
    };

    if (data.length) {
      const comp = state.competitions[data[0].competitionId];
      data.forEach(sponsor => {
        if (comp.sponsors.indexOf(sponsor.id) === -1) {
          comp.sponsors = [...comp.sponsors, sponsor.id];
        }
      });
    }
  },
  [DELETE_SPONSOR]: (state, id) => {
    deleteObject(state, 'sponsors', id);
  },

  [FETCH_AD_CAROUSELS]: (state, data) => {
    state.adCarousels = {
      ...state.adCarousels,
      ...data.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {})
    };

    if (data.length) {
      const comp = state.competitions[data[0].competitionId];
      data.forEach(adCarousel => {
        if (comp.adCarousels && comp.adCarousels.indexOf(adCarousel.id) === -1) {
          comp.adCarousels = [...comp.adCarousels, adCarousel.id];
        }
      });
    }
  },
  [DELETE_AD_CAROUSEL]: (state, id) => {
    deleteObject(state, 'adCarousels', id);
    const associatedAdCampaign = Object.values(state.adCampaigns).find(c => c.carousel.id === id);
    if (associatedAdCampaign) {
      deleteObject(state, 'adCampaigns', associatedAdCampaign.id);
    }
  },

  [FETCH_AD_CAMPAIGNS]: (state, data) => {
    state.adCampaigns = {
      ...state.adCampaigns,
      ...data.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {})
    };

    if (data.length) {
      const comp = state.competitions[data[0].competitionId];
      data.forEach(adCampaign => {
        if (comp.adCampaigns.indexOf(adCampaign.id) === -1) {
          comp.adCampaigns = [...comp.adCampaigns, adCampaign.id];
        }
      });
    }
  },
  [DELETE_AD_CAMPAIGN]: (state, id) => {
    deleteObject(state, 'adCampaigns', id);
  },

  [FETCH_GUEST_TICKET_LINKS]: (state, data) => {
    const links = data.ticketLinks.reduce((map, o) => {
      map[o.id] = o;
      return map;
    }, {});
    state.guestTicketLinks = {
      ...state.guestTicketLinks,
      ...links
    };
  },
  [FETCH_TICKET_LINK]: (state, data) => {
    state.ticketLinks = {
      ...state.ticketLinks,
      [data.id]: data
    };
  },
};
