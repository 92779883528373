<template>
    <span class="formatted-currency">{{ formatted }}</span>
</template>
<script>
import VueI18n from 'vue-i18n';

export default {
    name: 'formatted-currency',
    props: ['amount', 'currency'],
    methods: {
        convertAmountForDisplay(amount) {
            return this.isZeroBasedCurrency ? amount : amount / 100;
        }
    },
    computed: {
        isZeroBasedCurrency() {
            return this.$store.state.constants.zeroCurrencies && this.$store.state.constants.zeroCurrencies.indexOf(this.currency) > -1;
        },
        formatted() {
            if (this.amount === undefined) {
                return "";
            }
            const amount = this.convertAmountForDisplay(this.amount);
            if (VueI18n.availabilities.numberFormat) {
                return this.$n(amount, { key: 'currency', currency: this.currency.toUpperCase() });
            } else {
                return amount;
            }
        }
    }
}
</script>

