<template>
  <span class="score-display">
    {{ scoreDisplay }}
  </span>
</template>

<script>
  import { scoreFormatter } from '../utils';

  export default {
    name: 'score-display',
    props: ['score', 'policy'],
    computed: {
      scoreDisplay() {
        return scoreFormatter(this.score, this.policy);
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
