<template>
    <modal>
        <div class="launch-comp-modal">
            <h1>{{ title }}</h1>
            <h4>{{ $t("launchModalHeader") }}</h4>
            <div class="massive-icon">
                <icon name="circle-check" class="text-success" />
            </div>
            <p class="lead">{{ $t("launchModalLead1") }}</p>
            <p class="lead">{{ $t("launchModalLead2") }}</p>
            <ol>
                <li>{{ $t("launchModalList1") }}</li>
                <li>{{ $t("launchModalList2") }}</li>
                <li>{{ $t("launchModalList3") }}</li>
            </ol>
            <p class="lead">{{ $t("launchModalLead3") }}</p>
            <button class="btn btn-primary" @click="$emit('close')">{{ $t("launchModalButton") }}</button>
        </div>
    </modal>
</template>

<script>
export default {
  name: 'launch-comp-modal',
  props: ['title'],
}
</script>

<style lang="scss">
  @import '../../../scss/config';

  .launch-comp-modal {
    .massive-icon {
      text-align: center;
      font-size: 5em;
    }
    button {
        display: block;
        width: 100%;
    }

    @media(min-width: 550px) {
        padding: 15px 25px;
        button {
            font-size: 125%;
            margin-top: 40px;
        }
    }
  }
</style>
