<template>
  <div class="throwdowns-editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
          <icon name="bold" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <icon name="italic" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
          <icon name="strikethrough" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
          <icon name="underline" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
          <icon name="paragraph" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">
          <icon name="h1" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
          <icon name="h2" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
          <icon name="h3" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
          <icon name="list-ul" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
          <icon name="list-ol" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
          <icon name="quote-left" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.horizontal_rule() }" @click="commands.horizontal_rule">
          <icon name="horizontal-rule" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.link() }" @click="handleLink(commands.link)">
          <icon name="link" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.image() }" @click="handleImage(commands.image)">
          <icon name="image" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.youtube() }" @click="openYouTubeModal(commands.youtube)">
          <icon name="youtube" />
        </button>
        <button class="menubar__button" @click="commands.undo">
          <icon name="rotate-right" />
        </button>
        <button class="menubar__button" @click="commands.redo">
          <icon name="rotate-left" />
        </button>
        <input type="file" @change="fileChange" id="up" ref="file" accept="image/*" />
      </div>
    </editor-menu-bar>
    <editor-content class="editor" :editor="editor" />
    <LinkModal ref="linkmodal" @onConfirm="addCommand" />
    <YouTubeEmbedModal ref="ytmodal" @onConfirm="addCommand" />
  </div>
</template>
<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Image,
  } from 'tiptap-extensions';
  import YouTubeEmbed from './YouTubeEmbed';
  import YouTubeEmbedModal from './YouTubeEmbedModal.vue';
  import api from '../api';
  import { NETWORK_ERROR } from '../constants';
  import LinkModal from './LinkModal.vue';

  export default {
    name: 'throwdowns-editor',
    props: ['value', 'competition', 'contentSlug', 'placeholder', 'disabled'],
    watch: {
      imageSrc() {
        if (this.imageSrc) {
          this.insertImage();
        }
      },
    },
    methods: {
      handleImage(command) {
        this.command = command;
        this.$refs.file.click();
      },
      fileChange(e) {
        const file = this.$refs.file.files[0];
        this.upload(file);
      },
      upload(file) {
        let func = api.uploadCompetitionImage;
        let arg = this.competition && this.competition.id;
        func(arg, file)
          .then(r => this.imageSrc = r.data.data.url)
          .catch(error => this.$store.dispatch(NETWORK_ERROR, { error }));
      },
      insertImage() {
        this.command({ src: this.imageSrc });

        this.command = null;
        this.imageSrc = '';
      },
      handleLink(command) {
        this.$refs.linkmodal.showModal(command);
      },
      openYouTubeModal(command) {
        this.$refs.ytmodal.showModal(command);
      },
      addCommand(data) {
        if (data.command !== null) {
          data.command(data.data);
        }
      }
    },
    components: {
        EditorContent,
        EditorMenuBar,
        YouTubeEmbedModal,
        LinkModal,
    },
    data() {
      return {
        editor: null,
        imageSrc: '',
        command: null,
      };
    },
    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(null, null, this.upload),
          new YouTubeEmbed(),
        ],
        onUpdate: ({ getHTML }) => {
          this.$emit('input', getHTML());
        },
      });
    },
    beforeDestroy() {
      this.editor.destroy();
    },
  }
</script>
<style lang="scss">
@import "../../../scss/config";

:disabled .throwdowns-editor {
    pointer-events: none;
}

.editor {
  padding: .5rem 1rem;

  img {
    max-width: 100%;
  }
}

.throwdowns-editor {
  border: 1px solid $gray-200;
}

.menubar {
  border-bottom: 1px solid $gray-200;

  input[type=file] {
    display: none;
  }
}

.ProseMirror {
  outline: none;
}
</style>
