import AddItem from './AddItem.vue';
import DateTimePicker from './DateTimePicker.vue';
import GoogleAutocomplete from './GoogleAutocomplete.vue';
import GoogleMap from './GoogleMap.vue';
import Item from './Item.vue';
import ItemList from './ItemList.vue';
import SubmittingButton from './SubmittingButton.vue';
import FileUpload from './FileUpload.vue';
import LazyImage from './LazyImage.vue';
import Loader from './Loader.vue';
import Icon from './Icon.vue';
import DivisionPager from './DivisionPager.vue';
import FormattedCurrency from './FormattedCurrency.vue';
import FormattedDate from './FormattedDate.vue';
import DateTimeRangePicker from './DateTimeRangePicker.vue';
import DivisionSwitch from './DivisionSwitch.vue';
import SocialShare from './SocialShare.vue';
import CurrencyInput from './CurrencyInput.vue';
import YouTubeModal from './YouTubeModal.vue';
import CommentsModal from './CommentsModal.vue';
import ThrowdownsEditor from './ThrowdownsEditor.vue';
import PublishedScheduleWarning from './PublishedScheduleWarning.vue';
import PublishedCompetitionWarning from './PublishedCompetitionWarning.vue';
import StripeCardSCA from './StripeCardSCA.vue';
import OptionsSelector from './OptionsSelector.vue';
import CurrencySelector from './CurrencySelector.vue';
import ScoreDisplay from './ScoreDisplay.vue';

import ScoreImageModal from './ScoreImageModal.vue';

import Modal from './Modal.vue';
import LaunchCompModal from './LaunchCompModal.vue';
import SearchCompetitionCard from './SearchCompetitionCard.vue';
import SegmentedTimeInput from './SegmentedTimeInput.vue';
import SwitchButton from './SwitchButton.vue';
import ScrollToMe from './ScrollToMe.vue';
import Ticket from './Ticket.vue';
import Wait from './Wait.vue';
import Carousel from './Carousel.vue';
import AdCampaignCarousel from './AdCampaignCarousel.vue';

export default [
  AddItem,
  Wait,
  ScoreImageModal,
  ScrollToMe,
  CurrencyInput,
  DateTimePicker,
  GoogleAutocomplete,
  GoogleMap,
  Item,
  ItemList,
  SubmittingButton,
  LazyImage,
  FileUpload,
  Loader,
  Icon,
  DivisionPager,
  FormattedCurrency,
  FormattedDate,
  DateTimeRangePicker,
  PublishedScheduleWarning,
  PublishedCompetitionWarning,
  DivisionSwitch,
  SocialShare,
  YouTubeModal,
  CommentsModal,
  ThrowdownsEditor,
  OptionsSelector,
  CurrencySelector,
  StripeCardSCA,
  Modal,
  LaunchCompModal,
  SearchCompetitionCard,
  SegmentedTimeInput,
  SwitchButton,
  Ticket,
  Carousel,
  AdCampaignCarousel,
  ScoreDisplay,
];
