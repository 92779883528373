<template>
    <b-input-group>
        <b-form-input
            v-for="(segment, index) in timeSegments"
            v-model="segment.value"
            :key="index"
            :placeholder="segment.placeholder"
            :formatter="enforceConstraint(segment.constraint)"
            :disabled="disabled"
            @change="handleChange"
            @keypress.space.prevent
            @keypress.e.prevent
            @keypress.-.prevent
            @keypress.+.prevent>
        </b-form-input>
    </b-input-group>
</template>
<script>
import { positiveIntegerFormatter } from '../utils';

export default {
    name: 'segmented-time-input',
    props: {
        value: {
            type: String
        },
        segments: {
            type: String,
            default: 'mm:ss'
        },
        disabled: {
          type: Boolean,
          default: false,
        },
    },
    data() {
      return {
        timeSegments: []
      };
    },
    methods: {
      /**
       * Update the time segments, then emit an input event
       */
      handleChange(value) {
        this.timeSegments = this.normalizeSegments();
        const forEmit = this.timeSegments.map(s => s.normalized).join(':');
        this.$emit('input', forEmit);
      },

      /**
       * Loop through the provided segments, update the normalized value,
       * then update the value.
       */
      normalizeSegments(segments = this.timeSegments) {
        let numberStarted = false
        for (const segment of segments) {
          const hasValue = parseFloat(segment.value) > 0

          segment.normalized = this.getNormalizedValue(segment)

          if (hasValue === false && numberStarted === false) {
            // Initial empty segment
            segment.value = ''
          } else if (hasValue === true && numberStarted === false) {
            // First numeric segment
            let trimmed = segment.normalized.replace(/^0+/, '') || '0'
            if (trimmed[0] === '.') trimmed = `0${trimmed}`
            segment.value = trimmed
            numberStarted = true
          } else {
            segment.value = segment.normalized
          }
        }

        return segments
      },

      /**
       * Parse a string like so 02:45:10.400 into time segments.
       */
      parseIntoSegments(value) {
        const values = value ? value.replace(/[^\d:\.]/g, '').replace(/^[0:]+/, '').split(':') : [];
        const segments = this.segments.split(':');
        const offset = values.length - segments.length;

        const rawSegments = segments
          .filter(seg => seg)
          .map((seg, index) => {
            const value = values[index + offset] || ''
            return {
                key: seg,
                placeholder: seg,
                value,
                normalized: value,
                constraint: this.segmentConstraint(seg)
            }
          });

        return this.normalizeSegments(rawSegments);
      },

      /**
       * This returns a formatter that disallows any non-allowed characters.
       *
       * It should be noted that this _only trims out input_ and shouldn't be used
       * to validate the data, as partially entered input (say )
       */
      enforceConstraint(constraint) {
        if (constraint.type === 'float') return value => {
          // Only allow one decimal
          const [a, b] = value.split('.').map(i => i.replace(/[^\d.]/g, ''));
          return a + (b === undefined ? '' : '.' + b);
        }

        // Only allow int
        return value => value.replace(/[^\d]/g, '');
      },

      segmentConstraint(segment) {
        switch (segment[0]) {
          case 'd':
            return {
              min: 0,
              max: 365,
              type: 'int'
            };
          case 'h':
            return {
              min: 0,
              max: 24,
              length: 2,
              type: 'int'
            };
          case 'm':
            return {
              min: 0,
              max: 59,
              length: 2,
              type: 'int'
            };
          case 's':
            const ms = segment.indexOf('.') > -1

            return {
              // Support Milliseconds conditionally
              min: 0,
              max: ms ? 59.999 : 59,
              length: 2,
              type: ms ? 'float' : 'int',
              precision: ms ? 3 : 0
            };
          default:
            return {
              min: -Infinity,
              max: Infinity,
              type: 'int'
            };
        }
      },

      /**
       * Convert segment.value to an appropriate normalized value (with leading zeros)
       * given the segment's constraints.
       *
       * Enforces min / maxing, enforces segment type, enforces precision and ensures
       * length.
       */
      getNormalizedValue(segment) {
        const {length, precision, type, max, min} = segment.constraint

        // Convert to number
        const value = parseFloat(segment.value || '0')

        // Constrain value
        const clamped = Math.max(min, Math.min(max, value))
        const [whole, decimal] = clamped.toFixed(precision || 0).split('.');

        // Ensure we have the full length of the number
        let normalized = length === undefined ? whole : whole.padStart(length, '0').substring(0, length);
        if (type === 'float' && decimal) {
          const trimmed = decimal.substring(0, precision).replace(/0+$/, '');
          if (trimmed) normalized += `.${trimmed}`
        }

        return normalized;
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.timeSegments = this.parseIntoSegments(value);
        }
      }
    }
}

</script>
