<template>
  <VueSelect :options="currencyOptions" :value="currency" @input="onInput" :clearable="false" />
</template>

<script>
  import VueSelect from 'vue-select';

  export default {
    name: 'currency-selector',
    props: ['value'],
    components: {
      VueSelect
    },
    computed: {
      currencies() {
        return this.$store.state.constants && this.$store.state.constants.currencies;
      },
      currency() {
        const index = this.currencyOptions.findIndex(opt => opt.value === this.value);
        if (index > -1) {
          return this.currencyOptions[index];
        }
      },
      currencyOptions() {
        return this.currencies && this.currencies.map(val => {
          return { value: val[0], label: val[1] };
        });
      }
    },
    methods: {
      onInput(value) {
        this.$emit('input', value.value);
        this.$emit('change');
      }
    }
  }
</script>

<style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
</style>
