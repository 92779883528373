<template>
    <div class="lazy-image" :class="{'with-height': !loaded}">
        <loader :loaded="loaded"></loader>
        <transition name="fade">
            <img :class="classes" :src="src" @load="onLoaded" v-show="loaded" />
        </transition>
    </div>
</template>
<script>
export default {
    name: 'lazy-image',
    props: ['classes', 'src'],
    methods: {
        onLoaded() {
            this.loaded = true;
        }
    },
    data() {
        return {
            loaded: false
        }
    }
}
</script>
<style scoped>
    .with-height {
        min-height: 175px;
    }
</style>

