<template>
    <div class="division-switcher">
        <b-button :block="true" @click.prevent="toggleDivisionsMenu">
            <slot>{{ $t("switchDivisions") }}</slot>
            <icon name="caret-down" />
        </b-button>
        <div class="divisions-menu" v-show="showDivisionsMenu">
            <division-switch-item
                v-for="division in divisions"
                :key="division.id"
                :division="division"
                :selected="value === division"
                @selected="selectDivision" />
        </div>
    </div>
</template>
<script>
import DivisionSwitchItem from './DivisionSwitchItem.vue';

export default {
    name: 'division-switch',
    props: ['value', 'divisions'],
    components: {
        DivisionSwitchItem
    },
    data() {
        return {
            showDivisionsMenu: false,
        }
    },
    methods: {
        selectDivision(division) {
            this.$emit('input', division);
            this.showDivisionsMenu = false;
        },
        toggleDivisionsMenu() {
            this.showDivisionsMenu = !this.showDivisionsMenu;
        },
    }
}
</script>

