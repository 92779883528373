<template functional>
    <font-awesome-icon :icon="props.iconMap[props.name]" :fixed-width="!props.inline" :spin="props.spin" />
</template>
<script>
import iconMap from './icons';

export default {
    name: 'icon',
    props: {
      name: String,
      inline: {
        type: Boolean,
        default: false,
      },
      spin: {
        type: Boolean,
        default: false,
      },
      iconMap: {
        type: Object,
        default: () => iconMap
      }
    },
}
</script>
