<template>
  <div class="ticket-wrapper">
    <div class="ticket">
      <div class="ticket--code" v-html="ticket.qrCode"></div>
      <div class="ticket--body">
        <div class="ticket--event">
          <div class="ticket--event-title">{{ event.title }}</div>
          <div class="ticket--event-date"><formatted-date :date="event.date" :endDate="event.endDate" /></div>
          <div class="ticket--event-location">{{ event.location }}</div>
        </div>
        <div class="ticket--type-price">
          <div class="ticket--type">{{ type }}</div>
          &ndash;
          <div class="ticket--price"><formatted-currency :amount="price" :currency="currency" /></div>
        </div>
        <div class="ticket--meta">
          <div class="ticket--identifier">{{ ticket.id }}</div>
          <div class="ticket--purchased-date">
            Purchased On
            <formatted-date :date="purchasedAt" />
          </div>
        </div>
      </div>
    </div>

    <div class="print-url">
      <a :href="ticket.publicPrintUrl" target="_blank">
        <icon name="arrow-up-right-from-square" />
        {{ ticket.publicPrintUrl }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ticket',
    props: ['ticket', 'event', 'type', 'purchasedAt', 'price', 'currency'],
  }
</script>

<style lang="scss">
  @import '../../../scss/config';

</style>
