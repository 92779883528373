<template>
  <transition name="custom-modal-fade">
    <div class="custom-modal-backdrop">
      <div class="custom-modal-container">
        <div class="custom-modal">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    name: 'modal',
  }
</script>

<style scoped>
.custom-modal-fade-enter,
.custom-modal-fade-leave-active {
  opacity: 0;
}
.custom-modal-fade-enter-active,
.custom-modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.custom-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}
.custom-modal-container {
  max-height: 100vh;
  overflow-y: auto;
}
.custom-modal {
  background: var(--modal-background-color, var(--secondary-background-color, #FFF));
  box-shadow: 2px 2px 20px 1px var(--modal-box-shadow-color, var(--secondary-background-color, #000));
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 20px 5px;
  align-items: center;
  justify-content: center;
}

@media(min-width: 401px) {
  .custom-modal {
    padding: 20px;
  }
}
</style>
