<template>
    <div v-if="hasPublishedSchedule && !isStaff" class="published-schedule-warning-overlay">
        <div class="message">
            <p class="lead">{{ $t('publishedScheduleWarningHeader') }}</p>
            <p>{{ $t('publishedScheduleWarningBody1') }} <router-link :to="{name: 'compSetupPreviewSchedule', params: {competitionId: competition.id}}">{{ $t('publishedScheduleWarningBody2') }}</router-link> {{ $t('publishedScheduleWarningBody3') }}</p>
        </div>
    </div>
</template>

<script>
  import { FETCH_EVENT_CONFIGS, FETCH_TIME_BLOCKS } from '../constants';

  export default {
    name: 'published-schedule-warning',
    props: ['competition'],
    data() {
      return {
        loading: false,
      };
    },
    created() {
      this.loading = true;
      const promises = [
        // this.$store.dispatch(FETCH_DAYS, { id: this.competition.id }),
        // this.$store.dispatch(FETCH_STAGES, { id: this.competition.id }),
        this.$store.dispatch(FETCH_EVENT_CONFIGS, { id: this.competition.id }),
        this.$store.dispatch(FETCH_TIME_BLOCKS, { id: this.competition.id }),
      ];
      Promise.all(promises).then(() => this.loading = false);
    },
    computed: {
      hasPublishedSchedule() {
        return this.$store.getters.publishedBlocks(this.competition).length > 0;
      },
      isStaff() {
        return this.$store.state.account.staff;
      }
    },
  }
</script>

<style lang="scss">
  @import '../../../scss/config';

  .published-schedule-warning-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($gray-400, 0.6);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message {
        padding: 20px 30px;
        margin: 50px auto;
        background: $gray-100;
        display: inline-block;
        width: 400px;
        border-radius: 5px;
        box-shadow: 0 50px 150px rgba(0, 0, 0, 0.2);
    }
  }
</style>
