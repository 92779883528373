<template>
    <b-form-group :label="label" :description="description">
        <b-input-group>
            <b-input-group-text slot="prepend">
                <span>{{ currency.toUpperCase() }}</span>
            </b-input-group-text>
            <b-form-input v-model="model.value" :state="state" @input="onInput"></b-form-input>
        </b-input-group>
    </b-form-group>
</template>
<script>
export default {
    name: 'currency-input',
    props: ['currency', 'value', 'label', 'description'],
    data() {
        return {
            model: {
                value: ''
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.model.value = v;
            }
        },
        currency: {
            handler() {
                this.$emit('state', this.state);
            }
        }
    },
    methods: {
        onInput() {
            if (this.state === null) {
                this.$emit('input', this.model.value);
            }
            this.$emit('state', this.state);
        }
    },
    computed: {
        state() {
            if (isNaN(this.model.value)) {
                return false;
            }
            if (this.zeroBased && (this.model.value + '').indexOf('.') > -1) {
                return false;
            }
            return null;
        },
        zeroBased() {
            return this.zeroCurrencies.indexOf(this.currency) > -1;
        },
        zeroCurrencies() {
            return this.$store.state.constants && this.$store.state.constants.zeroCurrencies;
        },
    }
}
</script>
