<template>
    <div class="date-time-range-picker">
      <template v-if="!cleared || !canClear">
        <div class="row">
            <div class="start col">
                <label>{{ startLabel }}</label>
                <date-time-picker :initialValue="model.startDate" :timeIncrement="timeIncrements" @changed="onStartDateChange" />
            </div>
            <div class="end col">
                <label>{{ endLabel }}</label>
                <date-time-picker :initialValue="model.endDate" :timeIncrement="timeIncrements" @changed="onEndDateChange" />
            </div>
        </div>
        <div class="error-message text-danger text-center" v-if="invalid">
            {{ $t("invalidDate") }}
        </div>
      </template>
      <template v-if="canClear">
        <b-button v-if="cleared" variant="light" block @click="onSetDateRange">{{$t('setADateRange')}}</b-button>
        <b-button v-else variant="light" block @click="onClearDateRange">{{$t('clearDateRange')}}</b-button>
      </template>
    </div>
</template>
<script>
export default {
    name: 'date-time-range-picker',
    props: {
        startLabel: {
            type: String,
            default() {
                return this.$t('start')
            }
        },
        endLabel: {
            type: String,
            default() {
                return this.$t('end')
            }
        },
        value: {
            required: true,
            type: Object,
            validator: function (value) {
                return value.startDate !== undefined && value.endDate !== undefined;
            }
        },
        canClear: {
          type: Boolean,
          default() {
            return false;
          }
        },
        timeIncrements: {
          type: Number,
          default: 15
        }
    },
    data() {
        return {
          cleared: false,
          model: {
            startDate: this.value.startDate,
            endDate: this.value.endDate
          }
        }
    },
    created() {
      if (this.canClear && (this.value.startDate === null || this.value.endDate === null)) {
        this.cleared = true;
      }
    },
    computed: {
      range() {
        return { ...this.model }
      },
      invalid() {
        return this.model.startDate > this.model.endDate;
      },
    },
    methods: {
      onSetDateRange() {
        this.cleared = false;
      },
      onClearDateRange() {
        this.model = {
          startDate: null,
          endDate: null,
        }
        this.cleared = true;
        this.$emit('input', { startDate: null, endDate: null });
      },
      onStartDateChange(newStartDate) {
          this.model = {
              ...this.model,
              startDate: newStartDate
          };
          this.$emit('input', this.invalid ? { startDate: null, endDate: null } : this.range);
      },
      onEndDateChange(newEndDate) {
          this.model = {
              ...this.model,
              endDate: newEndDate
          };
          this.$emit('input', this.invalid ? { startDate: null, endDate: null } : this.range);
      }
    }
}
</script>
