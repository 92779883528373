<template>
    <div class="meta">
        <h4 class="data-title">{{ $t("shareWithFriends") }}</h4>
        <div class="share">
            <a class="social" :href="twitterLink" target="_blank"><icon name="twitter"></icon></a>
            <a class="social" :href="facebookLink" target="_blank"><icon name="facebook-f"></icon></a>
            <a class="social" :href="emailLink" target="_blank"><icon name="envelope"></icon></a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'social-share',
    props: ['competition'],
    computed: {
        twitterLink() {
            const { href } = window.location;
            const message = this.$t("twitterMessage", {compName: this.competition.title, href: href})
            const encodedMessage = window.encodeURI(message);
            return `https://twitter.com/home?status=${encodedMessage}`;
        },
        facebookLink() {
            const encodedUrl = window.encodeURI(window.location.href);
            return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        },
        emailLink() {
            const { href } = window.location;
            const subject = this.$t('shareEmailSubject', {compName: this.competition.title});
            const message = this.$t("shareEmailMessage", {compName: this.competition.title, href: href})
            const encodedMessage = window.encodeURI(message);
            return `mailto:?&subject=${subject}&body=${encodedMessage}`;
        }
    }
}
</script>
