const install = Vue => {
  if (install.installed) return;
  install.installed = true;

  Object.defineProperty(Vue.prototype, '$competition', {
    get() {
      return this.$store.state.competitions[this.$route.params.competitionId];
    }
  });
};

const CompetitionPlugin = { install };

export default {
  CompetitionPlugin
};
