<template>
    <div class="item-add-form">
        <TitleInput :description="itemDescription" :label="itemLabel" v-model="value.title" />

        <template v-if="itemDescription">
          <b-form-group v-if="richText === false" :description="descriptionDescription || $t('addItemDescrDescr')" :label="descriptionLabel || $t('addItemDescrHeader')">
              <b-form-input v-model="value.content"></b-form-input>
          </b-form-group>
          <throwdowns-editor v-else :competition="competition" v-model="value.content" output="html"></throwdowns-editor>
        </template>

        <slot name="extra"></slot>

        <submitting-button
            @click="save"
            @cancel="$emit('cancel')"
            :disabled="canSubmit === false"
            :with-cancel="true">
            {{ $t('save') }}
        </submitting-button>
    </div>
</template>
<script>
import SubmittingButton from './SubmittingButton.vue';
import TitleInput from './TitleInput.vue';

export default {
    name: 'add-item',
    props: ['itemDescription', 'itemLabel', 'descriptionLabel', 'descriptionDescription', 'competition', 'canSubmit', 'value', 'richText'],
    methods: {
        save (done) {
            this.$emit('saveItem', () => {
                done();
            });
        }
    },
    components: {
        SubmittingButton,
        TitleInput,
    }
}
</script>
