import axios from 'axios';
import axiosRetry from 'axios-retry';

const {
  API_BASE_URL,
  PUBLIC_API_BASE_URL,
  RELEASE_VERSION,
} = window.ThrowdownsConfig;

const versionHeaders = (config) => {
  config.headers['X-Throwdowns-Release'] = RELEASE_VERSION;
  return config;
};

const DOWNLOADS = axios.create({
  responseType: 'blob',
});

const HTTP = axios.create({
  baseURL: API_BASE_URL,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});

const PUBLIC_HTTP = axios.create({
  baseURL: PUBLIC_API_BASE_URL,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken'
});

DOWNLOADS.interceptors.request.use(versionHeaders, error => Promise.reject(error));
HTTP.interceptors.request.use(versionHeaders, error => Promise.reject(error));

//  PUBLIC_HTTP.interceptors.response.use(versionResponse, error => Promise.reject(error));

axiosRetry(HTTP, { retries: 3 }); // Looks to have sane default options: https://github.com/softonic/axios-retry#options
axiosRetry(PUBLIC_HTTP, { retries: 3 });

export default {
  HTTP,
  PUBLIC_HTTP,
  DOWNLOADS,
};
