import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import es from './es';
import pt from './pt';
import nb from './nb';
import merge from 'lodash.merge';

// if adding langs, update date picker:
//    static/src/js/app/components/DateTimePicker.vue,
//    static/src/js/app/modules/setup/registrationCoupons/components/EditCouponForm.vue
//    static/src/js/app/modules/setup/registrationCoupons/components/AddCouponForm.vue
//    static/src/js/app/modules/setup/scheduling/scheduling-config-panel/modals/DayModal.vue
// @todo move datepicker locale selection into a getter / utility fn.
export const supportedLangs = [en,es,pt,nb];
export const browserBaseLanguage = navigator.language.split('-')[0];
export const defaultLanguage = supportedLangs.map(l => l.key).includes(browserBaseLanguage) ? browserBaseLanguage : 'en';

Vue.use(VueI18n);

const messages = {};
const dateTimeFormats = {};
const numberFormats = {};

function mergeOverrides(key, messages) {
  const overrides = (window.translations || {})[key] || {};
  return merge(messages, overrides);
}

supportedLangs.forEach(l => {
  messages[l.key] = mergeOverrides(l.key, l.messages);
});
supportedLangs.forEach(l => {
  dateTimeFormats[l.key] = l.dateTimeFormats;
});
supportedLangs.forEach(l => {
  numberFormats[l.key] = l.numberFormats;
});

const i18n = new VueI18n({
  fallbackLocale: 'en',
  dateTimeFormats,
  numberFormats,
  messages,
  locale: defaultLanguage
});

export default i18n;
export const t = i18n.t.bind(i18n);
