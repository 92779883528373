Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fac';
var iconName = 'tie';
var width = 256;
var height = 512;
var ligatures = [];
var unicode = 'f27e';
var svgPathData = 'M237 386.356248 128.5 513 20 386.356248 100.5 123.645422 32 0 224.9 0 156.5 123.645422z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};

exports.faTie = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
