<template>
    <div class="stripe-card">
      <template v-if="showCardForm">
        <StripeElements v-if="stripeLoaded"
            ref="elms"
            :stripe-key="stripeKey"
            #default="{ elements }"
        >
          <StripeElement
            type="card"
            class="stripe-card mt-4"
            ref="card"
            :elements="elements"
            :class="{ complete }"
            :options="cardOptions"
            @change="handleCardChange($event)"
          />
        </StripeElements>
        <div v-else>{{$t('issueLoadingPaymentForm')}}</div>
        <b-alert variant="danger" id="card-errors" :show="error !== ''">{{ error }}</b-alert>
      </template>
      <slot></slot>
      <div v-if="!submitting" class="mt-4">
          <b-alert variant="warning" :show="computedDisabled">
              {{ computedDisabledWarning || $t('registrationRequiredFieldsWarning') }}
          </b-alert>
          <b-button variant="primary" :block="true" @click.prevent="purchase" :disabled="computedDisabled">
              {{ label }}
          </b-button>
      </div>
      <div v-else>
          {{ $t("submitting") }}
      </div>
    </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';

import { NETWORK_ERROR } from '../constants';
import api from '../api';

export default {
    name: 'stripe-card-sca',
    components: { StripeElements, StripeElement },
    props: {
      label: {
        type: String,
        required: true,
        default: 'Purchase',
      },
      disabled: {
        type: Boolean,
        required: true,
        default: false,
      },
      disabledWarning: {
        type: String,
        required: false
      },
      showCardForm: {
        type: Boolean,
        required: true,
      },
      competition: {
        type: Object,
        required: true,
      },
      registration: {
        type: Object,
        required: false,
        default: null,
      },
      division: {
        type: Object,
        required: false,
        default: null,
      },
      itemsToPurchase: {
        type: Array,
        required: false,
        default: () => [],
      },
      coupon: {
        type: String,
        required: false,
        default: null,
      },
      billingAddress: {
        type: String,
        required: false,
        default: '',
      },
    },
    created() {
      if (this.registration !== null || this.division !== null || this.itemsToPurchase !== null) {
        this.createUpdatePaymentIntent();
      }
    },
    watch: {
      registration() {
        if (this.registration) {
          this.createUpdatePaymentIntent();
        }
      },
      division() {
        if (this.division) {
          this.createUpdatePaymentIntent();
        }
      },
      itemsToPurchase() {
        this.createUpdatePaymentIntent();
      },
      coupon() {
        this.createUpdatePaymentIntent();
      },
      billingAddress: {
        immediate: true,
        handler() {
          if (this.billingAddress) {
            api.getAddress(this.billingAddress, data => {
              this.address = data.data;
              this.createUpdatePaymentIntent();
            });
          }
        }
      }
    },
    data() {
        return {
            error: '',
            complete: false,
            clientSecret: null,
            submitting: null,
            address: {},
        }
    },
    methods: {
        handleCardChange(event) {
            event.error ? this.error = event.error.message : this.error = '';
            this.complete = event.complete;
            if (event.complete) {
                this.$emit('complete');
            } else {
                this.$emit('incomplete');
            }
        },
        createUpdatePaymentIntent() {
          this.$emit('totalsCalculating');
          const items = [...this.itemsToPurchase];
          if (this.division !== null) {
            items.push({
              registration: this.registration ? this.registration.id : null,
              division: this.division.id
            });
          }
          api.paymentIntent(this.competition.id, items, this.coupon, data => {
            this.$emit('totalsCalculated', {
              subtotal: data.subtotal,
              processingFee: data.fees,
              discountApplied: data.discount,
              chargeFee: data.total,
              taxes: data.taxes,
            });
            this.clientSecret = data.clientSecret;
          }).catch(error => this.$store.dispatch(NETWORK_ERROR, { error }));
        },
        purchase() {
            if (this.clientSecret) {
              this.submitting = true;
              const onError = error => {
                this.error = error.message;
                this.submitting = false;
                this.$emit('paymentErrored', error);
              };
              const onSuccess = paymentIntentId => {
                this.error = '';
                this.$emit('confirmedPayment', paymentIntentId, () => this.submitting = false);
              };
              const groupComponent = this.$refs.elms;
              const cardComponent = this.$refs.card;

              groupComponent.instance.confirmCardPayment(this.clientSecret, {
                payment_method: {
                  card: cardComponent.stripeElement,
                  billing_details: this.billingDetails,
                }
              }).then(result => {
                if (result.error) {
                  // Show error to your customer
                  console.log("ERROR", result);
                  onError(result.error);
                } else {
                  // The payment has been processed!
                  console.log(result);
                  onSuccess(result.paymentIntent.id);
                }
              });
            } else {
              this.$emit('confirmedPayment', null, () => this.submitting = false);
            }
        },
    },
    computed: {
        incompleteAddress() {
          return this.address.valid === false;
        },
        computedDisabled() {
          if (this.disabled) {
            return true;
          }
          if (!this.chargeSalesTax) {
            return false;
          }
          return this.incompleteAddress && this.showCardForm;
        },
        computedDisabledWarning() {
          if (this.disabled) {
            return this.disabledWarning;
          }
          if (this.incompleteAddress) {
            return 'A complete billing address is required.';
          }
        },
        email() {
          const { account } = this.$store.state;
          if (account !== undefined && account.email!== undefined ) {
            return account.email;
          }
          return null;
        },
        billingDetails() {
          const { city, country, street, zip, state } = this.address;
          return {
            email: this.email,
            address: {
              line1: street,
              city,
              state,
              country,
              postal_code: zip,
            },
          };
        },
        cardOptions() {
          return {
            hidePostalCode: this.chargeSalesTax,
          };
        },
        chargeSalesTax() {
          return this.competition.chargeSalesTax;
        },
        stripeLoaded() {
            return window.Stripe !== undefined;
        },
        stripeKey() {
            return window.ThrowdownsConfig.PINAX_STRIPE_PUBLIC_KEY;
        },
    }
}
</script>
