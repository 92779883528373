<template>
  <div class="scroll-to-me"></div>
</template>

<script>
  import VueScrollTo from 'vue-scrollto';

  export default {
    name: 'scroll-to-me',
    mounted() {
      VueScrollTo.scrollTo(this.$el)
    }
  }
</script>

<style lang="scss" scoped>

</style>
