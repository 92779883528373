<template>
    <div :class="containerClass">
        <span v-if="submitting">{{ actionLabel }}</span>
        <div v-else>
            <b-button :variant="variant" :block="true" :disabled="disabled" @click="onClick">
                <slot></slot>
            </b-button>
            <b-button v-if="withCancel" variant="outline-secondary" :block="true" @click="$emit('cancel')">
              {{ $t("cancel") }}
            </b-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'submitting-button',
    props: {
        actionLabel: {
            type: String,
            default() {
              return this.$t('saving')
            }
        },
        variant: {
            type: String,
            default: 'primary'
        },
        containerClass: {
            type: String,
            default: 'mt-2 mb-2'
        },
        withCancel: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        onClick() {
            this.submitting = true;
            this.$emit('click', () => {
                this.submitting = false;
            });
        }
    }
}
</script>
