const SetupForm = () => import(/* webpackChunkName: "setup" */ '../modules/setup/setup/components/SetupForm.vue');
const ContentPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/content/components/ContentPanel.vue');
const TimePlacePanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/time/components/TimePlacePanel.vue');
const WorkoutsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/workouts/components/WorkoutsPanel.vue');
const DivisionsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/divisions/components/DivisionsPanel.vue');
const MerchandisePanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/merchandise/components/MerchandisePanel.vue');
const ScoreSubmissionPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scoreSubmission/components/ScoreSubmissionPanel.vue');
const RegistrationAccessPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationAccess/components/RegistrationAccessPanel.vue');
const RegistrationCapPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationCap/components/RegistrationCapPanel.vue');
const LeaderboardDistributionPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/leaderboardDistribution/LeaderboardDistributionPanel.vue');
const RegistrationNotificationsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationNotifications/RegistrationNotificationsPanel.vue');
const HeatScoringOptOutPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/advanced/HeatScoringOptOutPanel.vue');
const RegistrationContentPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationContent/components/RegistrationContentPanel.vue');
const RegistrationInvitePanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationInvite/components/RegistrationInvitePanel.vue');
const RegistrationWaiverPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationWaiver/components/RegistrationWaiverPanel.vue');
const RegistrationDatesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationDates/components/RegistrationDatesPanel.vue');
const RegistrationFieldsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationFields/components/RegistrationFieldsPanel.vue');
const RegistrationFeesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationFees/components/RegistrationFeesPanel.vue');
const ThemingPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/theming/components/ThemingPanel.vue');
const RegistrationCouponsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/registrationCoupons/components/RegistrationCouponsPanel.vue');
const DistributionPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/DistributionPanel.vue');
const CustomLabelsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/customLabels/components/CustomLabelsPanel.vue');
const TicketingContentPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/ticketing/components/TicketingContentPanel.vue');
const TicketingDatesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/ticketing/components/TicketingDatesPanel.vue');
const TicketingTicketsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/ticketing/components/TicketingTicketsPanel.vue');
const SponsorsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/sponsors/components/SponsorsPanel.vue');
const AdCarouselsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/sponsors/components/AdCarouselsPanel.vue');
const AdCampaignsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/sponsors/components/AdCampaignsPanel.vue');
const AdCampaignsReportPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/sponsors/components/AdCampaignsReportPanel.vue');
const PaymentsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/payments/components/PaymentsPanel.vue');
const ScheduleConfigPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/schedule-config-panel/ScheduleConfigPanel.vue');
const AssignmentsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/assignments-panel/AssignmentsPanel.vue');
// const WorkoutLocationsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/WorkoutLocationsPanel.vue');
const WorkoutHeatParamsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/WorkoutHeatParamsPanel.vue');
// const AthleteAssignmentsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/AthleteAssignmentsPanel.vue');
// const HeatTimesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/HeatTimesPanel.vue');
// const SchedulesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/SchedulesPanel.vue');
// const JudgesPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/JudgesPanel.vue');
// const SegmentsPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/SegmentsPanel.vue');
const PreviewPanel = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/PreviewPanel.vue');
const HeatSheets = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/HeatSheets.vue');
const ScoreCardLabels = () => import(/* webpackChunkName: "setup" */ '../modules/setup/scheduling/ScoreCardLabels.vue');
const HelpSetup = () => import(/* webpackChunkName: "setup" */ '../modules/setup/HelpSetup.vue');
const PreviewFeature = () => import(/* webpackChunkName: "setup" */ '../modules/setup/preview/PreviewFeature.vue');

const HeatScoring = () => import(/* webpackChunkName: "heat-scoring" */ '../modules/heat-scoring/HeatScoring.vue');

const CompetitionTransactions = () => import(/* webpackChunkName: "dashboard" */'../modules/transactions/CompetitionTransactions.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/Dashboard.vue');
const ScoringExportPanel = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/ScoringExportPanel.vue');
const CompetitionDashboard = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/CompetitionDashboard.vue');
const DivisionRegistrants = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/DivisionRegistrants.vue');
const DivisionScoring = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/DivisionScoring.vue');
const GuestScorerDashboard = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/GuestScorerDashboard.vue');
const MainHelp = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/MainHelp.vue');
const DistributeLeaderboard = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/DistributeLeaderboard.vue');
const ScoringLinks = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/ScoringLinks.vue');
const SpectatorsPage = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/SpectatorsPage.vue');
const RegistrantDetailPage = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/registrant-detail/RegistrantDetailPage.vue');
const GuestTicketerDashboard = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/GuestTicketerDashboard.vue');
const TicketLinks = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/TicketLinks.vue');
const ManageDelegates = () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/components/ManageDelegates.vue');

const ManageInvitationsPage = () => import(/* webpackChunkName: "invitations" */ '../modules/invitations/ManageInvitationsPage.vue');
const CreateInvitationPage = () => import(/* webpackChunkName: "invitations" */ '../modules/invitations/CreateInvitationPage.vue');
const EditInvitationPage = () => import(/* webpackChunkName: "invitations" */ '../modules/invitations/EditInvitationPage.vue');
const InvitationDetailPage = () => import(/* webpackChunkName: "invitations" */ '../modules/invitations/InvitationDetailPage.vue');

const AthleteRegistrationPage = () => import(/* webpackChunkName: "pages" */ '../views/AthleteRegistrationPage.vue');
const AthleteScoringPage = () => import(/* webpackChunkName: "pages" */ '../views/AthleteScoringPage.vue');
const CompetitionLandingPage = () => import(/* webpackChunkName: "pages" */ '../views/CompetitionLandingPage.vue');
const DivisionLandingPage = () => import(/* webpackChunkName: "pages" */ '../views/DivisionLandingPage.vue');
const WorkoutLandingPage = () => import(/* webpackChunkName: "pages" */ '../views/WorkoutLandingPage.vue');
const OrganizerQuickScorePage = () => import(/* webpackChunkName: "pages" */ '../views/OrganizerQuickScorePage.vue');
const SchedulePage = () => import(/* webpackChunkName: "pages" */ '../views/SchedulePage.vue');
const CompetitionSearchPage = () => import(/* webpackChunkName: "pages" */ '../views/CompetitionSearchPage.vue');
const TicketSalesPage = () => import(/* webpackChunkName: "pages" */ '../views/TicketSalesPage.vue');
const AthleteProfile = () => import(/* webpackChunkName: "pages" */ '../modules/athletes/components/AthleteProfile.vue');

const Account = () => import(/* webpackChunkName: "account" */ '../modules/account/components/Account.vue');
const AccountSettings = () => import(/* webpackChunkName: "account" */ '../modules/account/components/AccountSettings.vue');
const ProfileSettings = () => import(/* webpackChunkName: "account" */ '../modules/account/components/ProfileSettings.vue');

const CompetitionEmbedContainer = () => import(/* webpackChunkName: "leaderboard" */ '../containers/CompetitionEmbedContainer.vue');
const CompetitionLeaderboard = () => import(/* webpackChunkName: "leaderboard" */ '../modules/leaderboards/components/CompetitionLeaderboard.vue');
const RogueCompetitionLeaderboard = () => import(/* webpackChunkName: "rogue-leaderboard" */ '../modules/leaderboards/components/RogueCompetitionLeaderboard.vue');
const LeaderboardSettings = () => import(/* webpackChunkName: "leaderboard" */ '../modules/leaderboards/components/LeaderboardSettings.vue');
const DivisionGroupsPanel = () => import(/* webpackChunkName: "leaderboard" */ '../modules/leaderboards/components/DivisionGroupsPanel.vue');

const ApplicationContainer = () => import(/* webpackChunkName: "containers" */ '../containers/ApplicationContainer.vue');
const CompetitionContainer = () => import(/* webpackChunkName: "containers" */ '../containers/CompetitionContainer.vue');
const PublicCompetitionContainer = () => import(/* webpackChunkName: "containers" */ '../containers/PublicCompetitionContainer.vue');
const CompetitionChromeContainer = () => import(/* webpackChunkName: "containers" */ '../containers/CompetitionChromeContainer.vue');
const PublicPageContainer = () => import(/* webpackChunkName: "containers" */ '../containers/PublicPageContainer.vue');

const StaffDashboard = () => import(/* webpackChunkName: "staff" */ '../modules/staff/components/StaffDashboard.vue');
const CompetitionCalendar = () => import(/* webpackChunkName: "staff" */ '../modules/staff/components/CompetitionCalendar.vue');

const ProgrammingWizard = () => import(/* webpackChunkName: "wizards" */ '../modules/wizard/ProgrammingWizard.vue');
const RegistrationWizard = () => import(/* webpackChunkName: "wizards" */ '../modules/wizard/RegistrationWizard.vue');
const SetupWizard = () => import(/* webpackChunkName: "wizards" */ '../modules/wizard/SetupWizard.vue');

const NotFound = () => import(/* webpackChunkName: "errors" */ '../components/NotFound.vue');

import store from 'throwdowns/store'

function requirePermission(permission) {
  return (to, from, next) => {
    if (store.getters.permissions[permission] || !store.state.isAuthed) return next()
    next("/app/participant-dashboard/");
  }
}

export default [
  {
    path: '/app/',
    component: ApplicationContainer,
    children: [
      {
        path: 'my-account/',
        redirect: 'my-account/',
        component: Account,
        children: [
          { path: '/', component: AccountSettings, name: 'account-settings' },
          { path: 'profile/', component: ProfileSettings, name: 'profile-settings' },
        ]
      },
      { path: 'dashboard/', redirect: 'organizer-dashboard/' },
      { path: 'organizer-dashboard/', beforeEnter: requirePermission('organizer'), component: Dashboard, name: 'organizer-dashboard' },
      { path: 'participant-dashboard/', component: Dashboard, name: 'participant-dashboard' },
      { path: 'spectator-dashboard/', component: Dashboard, name: 'spectator-dashboard' },
      { path: 'competition-search/', component: CompetitionSearchPage, name: 'competition-search' },
      { path: 'staff/', component: StaffDashboard, name: 'staff-dashboard' },
      { path: 'staff/competition-calendar/', component: CompetitionCalendar, name: 'staff-competition-calendar' },
      { path: 'help/', component: MainHelp, name: 'top-level-help' },
      { path: 'competitions/setup-wizard/', beforeEnter: requirePermission('organizer'), component: SetupWizard, name: 'compCreatePanel' },

      {
        path: 'competitions/:competitionId/',
        component: CompetitionContainer,
        beforeEnter: requirePermission('organizer'),
        children: [
          { path: 'programming-wizard/', component: ProgrammingWizard, name: 'compProgrammingWizard' },
          { path: 'registration-wizard/', component: RegistrationWizard, name: 'compRegistrationWizard' },
          { path: 'scoring-links/:scoringLinkId/', component: GuestScorerDashboard, name: 'staffScoring' },
          { path: 'ticket-links/:ticketLinkId/', component: GuestTicketerDashboard, name: 'staffTicketing' },

          {
            path: 'preview/',
            component: CompetitionChromeContainer,
            children: [
              { path: '', component: PublicCompetitionContainer, children: [
                { path: '', component: CompetitionLandingPage, name: 'compLandingPagePreview', props: true },
                { path: 'divisions/:divisionId/', component: DivisionLandingPage, name: 'compDivisionLandingPagePreview' },
                { path: 'workouts/:workoutId/', component: WorkoutLandingPage, name: 'compWorkoutLandingPagePreview' },
                { path: 'leaderboard/', component: CompetitionLeaderboard, name: 'compLeaderboardPreview' },
                { path: 'registration/', component: AthleteRegistrationPage, name: 'athleteRegistrationPreview' },
                { path: 'tickets/', component: TicketSalesPage, name: 'ticketSalesPreview' },
                { path: 'scoring/', component: AthleteScoringPage, name: 'athleteScoringPreview' },
                { path: 'scoring/:scoringLinkId/:divisionId/', component: DivisionScoring, name: 'staffDivisionScoringPreview' },
                { path: 'schedule/', component: SchedulePage, name: 'compSchedulePreview' },
              ]}
            ],
          },

          {
            path: 'setup/',
            component: CompetitionChromeContainer,
            children: [
              { path: 'support/', component: HelpSetup, name: 'compSupport' },
              { path: 'marketing/help/', component: HelpSetup, name: 'compSetupMarketing' },
              { path: 'marketing/setup/', component: SetupForm, name: 'compSetupPanel' },
              { path: 'marketing/content/', component: ContentPanel, name: 'compSetupContentPanel' },
              { path: 'marketing/time-place/', component: TimePlacePanel, name: 'compSetupTimePlacePanel' },
              { path: 'marketing/theming/', component: ThemingPanel, name: 'compSetupTheming' },

              { path: 'programming/help/', component: HelpSetup, name: 'compSetupProgramming' },
              { path: 'programming/workouts/', component: WorkoutsPanel, name: 'compSetupWorkoutsPanel' },
              { path: 'programming/divisions/', component: DivisionsPanel, name: 'compSetupDivisionsPanel' },
              { path: 'programming/online-scoring/', component: ScoreSubmissionPanel, name: 'compSetupScoreSubmission' },
              { path: 'programming/registration-access/', component: RegistrationAccessPanel, name: 'compSetupAdvancedRegistrationAccess' },
              { path: 'programming/registration-invites/', component: RegistrationInvitePanel, name: 'compSetupAdvancedRegistrationInvites' },
              { path: 'programming/registration-cap/', component: RegistrationCapPanel, name: 'compSetupAdvancedRegistrationCap' },
              { path: 'programming/leaderboard-distribution/', component: LeaderboardDistributionPanel, name: 'compSetupLeaderboardDistribution' },
              { path: 'programming/registrant-notifications/', component: RegistrationNotificationsPanel, name: 'compSetupRegistrationNotifications' },

              { path: 'advertising/', component: SponsorsPanel, name: 'compSponsors'},
              { path: 'advertising/ad-carousels/', component: AdCarouselsPanel, name: 'compAdCarousels' },
              { path: 'advertising/ad-campaigns/', component: AdCampaignsPanel, name: 'compAdCampaigns' },
              { path: 'advertising/ad-campaigns-report/', component: AdCampaignsReportPanel, name: 'compAdCampaignsReport' },

              { path: 'registration/help/', component: HelpSetup, name: 'compSetupRegistration' },
              { path: 'registration/content/', component: RegistrationContentPanel, name: 'compSetupRegistrationContentPanel' },
              { path: 'registration/fees/', component: RegistrationFeesPanel, name: 'compSetupRegistrationFeesPanel' },
              { path: 'registration/merchandise/', component: MerchandisePanel, name: 'compSetupMerchandisePanel' },
              { path: 'registration/dates/', component: RegistrationDatesPanel, name: 'compSetupRegistrationDatesPanel' },

              { path: 'ticketing/help/', component: HelpSetup, name: 'compSetupTicketing' },
              { path: 'ticketing/content/', component: TicketingContentPanel, name: 'compSetupTicketingContentPanel' },
              { path: 'ticketing/dates/', component: TicketingDatesPanel, name: 'compSetupTicketingDatesPanel' },
              { path: 'ticketing/tickets/', component: TicketingTicketsPanel, name: 'compSetupTicketingTicketsPanel' },

              { path: 'heat-scheduling/help/', component: HelpSetup, name: 'compSetupHeatScheduling' },
              { path: 'heat-scheduling/workouts/', component: WorkoutHeatParamsPanel, name: 'compWorkoutHeatParams' },
              { path: 'heat-scheduling/schedule/', component: ScheduleConfigPanel, name: 'compSetupSchedule' },
              { path: 'heat-scheduling/assignments/', component: AssignmentsPanel, name: 'compAssignments' },
              // { path: 'heat-scheduling/heat-times/', component: HeatTimesPanel, name: 'compSetupHeatTimes' },
              // { path: 'heat-scheduling/athlete-assignments/', component: AthleteAssignmentsPanel, name: 'compSetupAthleteAssignments'},
              // { path: 'heat-scheduling/schedules/', component: SchedulesPanel, name: 'compSetupSchedules' },
              // { path: 'heat-scheduling/judges/', component: JudgesPanel, name: 'compSetupJudges' },
              { path: 'heat-scheduling/preview/', component: PreviewPanel, name: 'compSetupPreviewSchedule' },
              { path: 'heat-scheduling/heat-sheets/', component: HeatSheets, name: 'compSetupHeatSheets' },
              { path: 'heat-scheduling/distribution/', component: DistributionPanel, name: 'compSetupDistributionPanel' },
              { path: 'heat-scheduling/score-card-labels/', component: ScoreCardLabels, name: 'compSetupScheduleScoreCardLabels' },

              { path: 'advanced/help/', component: HelpSetup, name: 'compSetupAdvanced' },
              { path: 'advanced/registration-coupons/', component: RegistrationCouponsPanel, name: 'compSetupRegistrationCouponsPanel' },
              { path: 'advanced/registration-waiver/', component: RegistrationWaiverPanel, name: 'compSetupRegistrationWaiverPanel' },
              { path: 'advanced/registration-fields/', component: RegistrationFieldsPanel, name: 'compSetupRegistrationFieldsPanel' },
              { path: 'advanced/custom-labels/', component: CustomLabelsPanel, name: 'compSetupCustomLabels' },
              { path: 'advanced/heat-scoring-opt-out/', component: HeatScoringOptOutPanel, name: 'compSetupHeatScoringOptOut' },

              { path: 'payments/', component: PaymentsPanel, name: 'compSetupPaymentsPanel' },

              { path: 'preview/advertising', component: PreviewFeature, name: 'previewSponsorship', props: { feature: 'sponsorship' } },
              { path: 'preview/scheduling', component: PreviewFeature, name: 'previewScheduling', props: { feature: 'scheduling' } },
              { path: 'preview/precision-timing', component: PreviewFeature, name: 'previewPrecisionTiming', props: { feature: 'precisionTiming',  } },
              { path: 'preview/live-scoring', component: PreviewFeature, name: 'previewLiveScoring', props: { feature: 'liveScoring' } },
              { path: 'preview/broadcasing', component: PreviewFeature, name: 'previewBroadcasting', props: { feature: 'broadcasting' } },
            ],
          },

          {
            path: '',
            component: CompetitionChromeContainer,
            children: [
              { path: 'dashboard/', component: CompetitionDashboard, name: 'compDashboard' },
              { path: 'transactions/', component: CompetitionTransactions, name: 'compTransactions' },
              { path: 'registrants/help/', component: HelpSetup, name: 'compRegistrants' },
              { path: 'registrant-divisions/:divisionId/', component: DivisionRegistrants, name: 'compRegistrantsDivision' },
              { path: 'registrants/:registrationId/', component: RegistrantDetailPage, name: 'compRegistrantDetail' },
              { path: 'spectators/help/', component: HelpSetup, name: 'compSpectators' },
              { path: 'spectators/', component: SpectatorsPage, name: 'compSpectatorsTickets' },
              { path: 'spectators/links/', component: TicketLinks, name: 'compSpectatorsTicketLinks' },
              { path: 'scoring/leaderboard/', component: DistributeLeaderboard, name: 'compDistributeLeaderboard' },
              { path: 'scoring/export/', component: ScoringExportPanel, name: 'compExportScoring' },
              { path: 'scoring/help/', component: HelpSetup, name: 'compScoring' },
              { path: 'scoring/links/', component: ScoringLinks, name: 'compScoringLinks' },
              { path: 'delegates/help/', component: HelpSetup, name: 'compDelegates' },
              { path: 'delegates/manage/', component: ManageDelegates, name: 'compManageDelegates' },
              { path: 'scoring/by-heat/', component: HeatScoring, name: 'compScoringByHeat' },
              { path: 'scoring/:divisionId/', component: DivisionScoring, name: 'compScoringDivision' },
              { path: 'invitations/help/', component: HelpSetup, name: 'compInvitations' },
              { path: 'invitations/manage/', component: ManageInvitationsPage, name: 'compManageInvitations' },
              { path: 'invitations/create/', component: CreateInvitationPage, name: 'compInvitationCreate' },
              { path: 'invitations/:invitationId/edit/', component: EditInvitationPage, name: 'compInvitationEdit' },
              { path: 'invitations/:invitationId/', component: InvitationDetailPage, name: 'compInvitationDetail' },
              { path: 'leaderboards/help/', component: HelpSetup, name: 'compLeaderboards' },
              { path: 'leaderboards/groups/', component: DivisionGroupsPanel, name: 'compLeaderboardGroups' },
              { path: 'leaderboards/settings/', component: LeaderboardSettings, name: 'compLeaderboardSettings' },
            ]
          }
        ],
      },
    ]
  },

  {
    path: '/competition-embeds/:competitionId/',
    component: CompetitionEmbedContainer,
    children: [
      { path: 'leaderboard/', component: CompetitionLeaderboard, name: 'embeddedLeaderboard', meta: { public: true }},
      { path: 'leaderboard/:divisionId/', component: CompetitionLeaderboard, name: 'embeddedDivisionLeaderboard', meta: { public: true }},
    ],
  },

  {
    path: '/rogue-competition-embeds/:competitionId/',
    component: CompetitionEmbedContainer,
    children: [
      { path: 'leaderboard/', component: RogueCompetitionLeaderboard, name: 'embeddedRogueLeaderboard', meta: { public: true }},
      { path: 'leaderboard/:divisionId/', component: RogueCompetitionLeaderboard, name: 'embeddedRogueDivisionLeaderboard', meta: { public: true }},
    ],
  },

  {
    path: '/athletes/:username/',
    component: AthleteProfile,
    name: 'publicAthleteProfile',
    meta: { public: true }
  },

  {
    path: '/',
    component: PublicPageContainer,
    children: [{
      path: 'competitions/:competitionId/',
      component: PublicCompetitionContainer,
      children: [
        { path: '', component: CompetitionLandingPage, name: 'compLandingPage', meta: { public: true }},
        { path: 'divisions/:divisionId/', component: DivisionLandingPage, name: 'compDivisionLandingPage', meta: { public: true }},
        { path: 'workouts/:workoutId/', component: WorkoutLandingPage, name: 'compWorkoutLandingPage', meta: { public: true }},
        { path: 'leaderboard/', component: CompetitionLeaderboard, name: 'compLeaderboard', meta: { public: true }},
        { path: 'leaderboard/:divisionId/', component: CompetitionLeaderboard, name: 'compDivisionLeaderboard', meta: { public: true }},
        { path: 'registration/', component: AthleteRegistrationPage, name: 'athleteRegistration', meta: { public: true }},
        { path: 'tickets/', component: TicketSalesPage, name: 'ticketSales', meta: { public: true }},
        { path: 'schedule/', component: SchedulePage, name: 'schedulePage', meta: { public: true }},
        { path: 'scoring/', component: AthleteScoringPage, name: 'athleteScoring', meta: { public: true }},
        { path: 'quick-scoring/', component: OrganizerQuickScorePage, name: 'quickScoring', meta: { public: true }},
      ]
    },
    { path: '*', component: NotFound, name: 'notFound' }
    ]
  },
];
