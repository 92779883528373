// Import Alert to use the wrapper with defaults
// Import alertBase to get access to the whole alert functionality
// see docs here: https://alertifyjs.com/alert.html
import alertify from 'alertifyjs'
import "alertifyjs/build/css/alertify.min.css"
import "alertifyjs/build/css/themes/semantic.min.css"

export function alertBase() {
  return alertify.alert();
}

export function Alert(options) {
  options.closable = false
  options.transition = 'fade'
  if(!options.onok) {
    options.onok = () => window.location.reload();
  }
  alertify.alert()
  .setting(options).show();
}
