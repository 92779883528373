const { CONSTANTS_JSON } = window.ThrowdownsConfig;
window.ThrowdownsConfig.CONSTANTS = JSON.parse(CONSTANTS_JSON);

export default {
  isAuthed: false,
  account: {
    managedCompetitions: [],
    registrations: [],
    scoreSets: [],
    private: true
  },
  publicProfile: {registrations: []},

  fetchingAccount: null,
  fetchingCompetitions: null,
  swappingAssignments: [],

  competitionOrder: [],

  // Domain Data
  competitions: {},
  compMetrics: {},
  divisions: {},
  divisionGroups: {},
  workouts: {},
  stages: {},
  days: {},
  coupons: {},
  registrationFields: {},
  scoringPolicies: {},
  scoringLinks: {},
  ticketLinks: {},
  guestScoringLinks: {},
  guestTicketLinks: {},
  scoreSets: {},
  scores: {},
  registrations: {},
  saleItems: {},
  timeBlocks: {},
  // schedules: {},
  // heats: {},
  athleteAssignments: {},
  judges: {},
  eventConfigs: {},
  invitations: {},
  invitees: {},
  sponsors: {},
  adCarousels: {},
  adCampaigns: {},

  // non domain data
  registrationError: null,
  ticketPurchaseError: null,
  saleItemDeleteError: null,
  staffMetrics: {},
  selectedTimezone: '',
  loaded: false,
  constants: window.ThrowdownsConfig.CONSTANTS,
  stripeAccountOrder: [],
  stripeAccounts: {},
  stripeConnectUrl: '',
  navOpen: true,
  competitionSetupFormDirty: false,
  showNewCompModal: false,
  networkErrored: false,
  pageNotFound: false,

  newVersionAvailable: false,
};
