<template>
  <modal>
    <div v-if="videoId" class="youtube">
        <youtube :width="width" :height="height" :video-id="videoId" ref="youtube" @playing="playing"></youtube>
    </div>
    <div class="no-video" v-else>{{ $t('invalidVideo') }}: {{ url }}</div>
    <div class="comments-container m-2" v-if="videoComments" >
      <h4>{{ $t('athleteComment') }}</h4>
      <span>{{ comments }}</span>
    </div>
    <div class="button-container m-2">
      <button class="btn btn-primary btn-block" @click.prevent="onClose">{{ $t('scoresubModalClose') }}</button>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'youtube-modal',
    props: ['url', 'comments'],
    data() {
      return {
        width: 640,
        height: 360,
      };
    },
    computed: {
      videoId() {
         return this.$youtube.getIdFromUrl(this.url);
      },
      videoComments() {
        return this.comments.charAt(0).toUpperCase() + this.comments.slice(1);
      },
    },
    mounted() {
      if (window.screen.width < 640) {
        this.width = window.screen.width;
        this.height = this.width / 1.7778;
      }
    },
    methods: {
      playing(a) {
        console.log('playing', a);
      },
      onClose() {
        this.$emit('youtubeClose');
      }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../scss/config';
  .btn-primary {
    background: var(--primary-button-background-color, $primary);
    border-color: var(--primary-button-background-color, $primary);
    color: var(--primary-button-text-color, $white);
  }
</style>
