<template>
  <b-form-group :label="label">
    <b-form-checkbox-group v-model="selected" :options="options" />
    <div class="selects">
      <a v-if="!allSelected" href @click.prevent="selectAll">{{$t('selectAll')}}</a>
      <a v-if="!noneSelected" href @click.prevent="selectNone">{{$t('selectNone')}}</a>
    </div>
  </b-form-group>
</template>

<script>
  export default {
    name: 'options-selector',
    props: ['label', 'value', 'options'],
    data() {
      return {
        selected: [],
      }
    },
    created() {
      this.selected = [...this.value];
    },
    watch: {
      selected() {
        this.$emit('input', [...this.selected]);
      }
    },
    computed: {
      allSelected() {
        return this.value.length === this.options.length;
      },
      noneSelected() {
        return this.value.length === 0;
      }
    },
    methods: {
      selectAll() {
        this.selected = [...this.options.map(o => o.value)];
        this.$emit('input', this.options.map(o => o.value));
      },
      selectNone() {
        this.selected = [];
        this.$emit('input', []);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .selects {
    font-size: 90%;
    margin-left: -10px;
    margin-top: 5px;
    a {
      padding: 0 10px;
    }
  }
</style>
