export const ACCEPT_TERMS = 'acceptTerms';

export const SCORING_POLICY_PLACEMENT = 'placement_based_scoring';
export const SCORING_POLICY_MAX_POINTS = 'cumulative_points_scoring';
export const SCORING_POLICY_MIN_POINTS = 'cumulative_points_desc_scoring';
export const SCORING_POLICY_POINTS_TABLE = 'points_table_scoring';

export const LEADERBOARD_POLICY_CHOICES = [
  { title: 'Placement', value: SCORING_POLICY_PLACEMENT },
  { title: 'Points Table', value: SCORING_POLICY_POINTS_TABLE },
  { title: 'Max Points', value: SCORING_POLICY_MAX_POINTS },
  { title: 'Min Points', value: SCORING_POLICY_MIN_POINTS },
];

export const TIE_POLICY_TIES_STAND = 'ties_stand';
export const TIE_POLICY_TIES_STAND_AVERAGE = 'ties_stand_average';
export const HIGHER_IS_BETTER = 'higher_is_better';
export const LOWER_IS_BETTER = 'lower_is_better';

export const BEST_PLACEMENT_WINS = 'best_placement_wins';
export const WORST_PLACEMENT_LOSES = 'worst_placement_loses';
export const MOST_FIRST_PLACES = 'most_first_places';
export const HEAD_TO_HEAD = 'head_to_head';
export const MOST_HIGHEST_PLACEMENTS = 'most_highest_placements';

export const LEADERBOARD_TIEBREAKER_POLICY_CHOICES = [
  { title: 'Best Placement Wins', value: BEST_PLACEMENT_WINS },
  { title: 'Worst Placement Loses', value: WORST_PLACEMENT_LOSES },
  { title: 'Most First Places Wins', value: MOST_FIRST_PLACES },
  { title: 'Head to Head', value: HEAD_TO_HEAD },
  { title: 'Most Highest Placements', value: MOST_HIGHEST_PLACEMENTS }
];

export const POINTS_TABLE_200 = 'games_2015_200_points_table';
export const POINTS_TABLE_100 = 'games_2015_100_points_table';
export const POINTS_TABLE_50 = 'games_2015_50_points_table';
export const POINTS_TABLE_CUSTOM = 'custom_points_table';

export const POINTS_TABLES = {
  [POINTS_TABLE_50]: '50,47,44,42,40,38,36,34,32,30,29,28,27,26,25,24,23,22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0',
  [POINTS_TABLE_100]: '100,94,88,84,80,76,72,68,64,60,58,56,54,52,50,48,46,44,42,40,38,36,34,32,30,28,26,24,22,20,18,16,14,12,10,8,6,4,2,0',
  [POINTS_TABLE_200]: '200,188,176,168,160,152,144,136,128,120,116,112,108,104,100,96,92,88,84,80,76,72,68,64,60,56,52,48,44,40,36,32,28,24,20,16,12,8,4,0'
};

export const TEXT_FIELD = 0;
export const TEXT_AREA = 1;
export const RADIO_CHOICES = 2;
export const SELECT_FIELD = 4;
export const CHECKBOX_FIELD = 5;

export const CHOICE_FIELDS = [
  CHECKBOX_FIELD,
  SELECT_FIELD,
  RADIO_CHOICES
];

export const POLLING_TAPER_OFF_FACTOR = 1.618;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FETCH_REGISTRATION_EXPORT_STATUS = 'fetchRegistrationExportStatus';
export const FETCH_PUBLIC_COMPETITION = 'fetchPublicCompetition';
export const FETCH_PUBLIC_COMPETITION_DIVISIONS = 'fetchPublicCompetitionDivisions';
export const FETCH_PUBLIC_COMPETITION_WORKOUTS = 'fetchPublicCompetitionWorkouts';
export const FETCH_PUBLIC_COMPETITION_SCORING_POLICIES = 'fetchPublicCompetitionScoringPolicies';
export const FETCH_PUBLIC_COMPETITION_SALE_ITEMS = 'fetchPublicSaleItems';

export const FETCH_PUBLIC_COMPETITION_STAGES = 'fetchPublicCompetitionStages';
export const FETCH_PUBLIC_COMPETITION_DAYS = 'fetchPublicCompetitionDays';
export const FETCH_PUBLIC_COMPETITION_EVENT_CONFIGS = 'fetchPublicCompetitionEventConfigs';
export const FETCH_PUBLIC_COMPETITION_TIME_BLOCKS = 'fetchPublicCompetitionTimeBlocks';
export const FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS = 'fetchPublicCompetitionAthleteAssignments';
export const FETCH_PUBLIC_COMPETITION_JUDGES = 'fetchPublicCompetitionJudges';

export const FETCH_PUBLIC_COMPETITION_FIELDS = 'fetchFields';
export const FETCH_PUBLIC_COMPETITION_AD_CAMPAIGNS = 'fetchPublicCompetitionAdCampaigns';
export const FETCH_PUBLIC_DIVISION = 'fetchPublicDivision';
export const FETCH_COMPETITION_FIELDS = 'fetchCompetitionFields';
export const COMPETITION_FORM_DIRTY = 'competitionFormDirty';
export const FETCH_ACCOUNT = 'fetchAccount';
export const FETCH_ACCOUNT_FAIL = 'fetchAccountFail';
export const FETCH_TIMEZONE = 'fetchTimezone';
export const FETCH_STRIPE_ACCOUNTS = 'fetchStripeAccounts';
export const FETCH_COMPETITIONS = 'fetchCompetitions';
export const FETCH_GUEST_SCORING_LINKS = 'fetchGuestScoringLinks';
export const FETCH_GUEST_TICKET_LINKS = 'fetchGuestTicketLinks';
export const FETCH_COMPETITION = 'fetchCompetition';
export const FETCH_INITIAL_DATA = 'fetchInitialData';
export const FETCH_LANDING_PAGE_INITIAL_DATA = 'fetchLandingPageInitialData';
export const FETCH_SCORE_SET = 'fetchScoreSet';
export const FETCH_COMPETITION_TRIFECTA = 'fetchCompetitionTrifecta';
export const FETCH_DIVISION = 'fetchDivision';
export const FETCH_DIVISION_REGISTRATIONS = 'fetchDivisionRegistrations';
export const FETCH_WORKOUT = 'fetchWorkout';
export const FETCH_COUPON = 'fetchCoupon';
export const FETCH_SCORING_POLICY = 'fetchScoringPolicy';
export const FETCH_REGISTRATION = 'fetchRegistration';
export const FETCH_SCORING_LINK = 'fetchScoringLink';
export const FETCH_TICKET_LINK = 'fetchTicketLink';
export const FETCH_STAGES = 'fetchStages';
export const FETCH_DAYS = 'fetchDays';
export const FETCH_EVENT_CONFIGS = 'fetchEventConfigs';
export const FETCH_INVITATION = 'fetchInvitation';
export const FETCH_SALE_ITEMS = 'fetchSaleItems';
export const FETCH_PUBLIC_PROFILE = 'fetchPublicProfile';
export const FETCH_SPONSORS = 'fetchSponsors';
export const FETCH_AD_CAROUSELS = 'fetchAdCarousels';
export const FETCH_AD_CAMPAIGNS = 'fetchAdCampaigns';

export const FETCH_TIME_BLOCKS = 'fetchTimeBlocks';
export const CREATE_TIME_BLOCK = 'createTimeBlock';
export const UPDATE_TIME_BLOCK = 'updateTimeBlock';
export const DELETE_TIME_BLOCK = 'deleteTimeBlock';

export const CREATE_HEAT = 'createHeat';
export const GENERATE_HEATS = 'generateHeats';
export const GENERATE_EVENT_HEATS = 'generateEventHeats';

export const SAVE_THEME = 'saveTheme';

export const RECORD_PUBLIC_PROFILE_HIT = 'recordPublicProfileHit';
export const CREATE_COMPETITION = 'createCompetition';
export const CREATE_COUPON = 'createCoupon';
export const CREATE_WORKOUT = 'createWorkout';
export const CREATE_SALE_ITEM = 'createSaleItem';
export const CREATE_DIVISION = 'createDivision';
export const CREATE_FIELD = 'createField';
export const CREATE_SCORE_SET = 'createScoreSet';
export const CREATE_SCORING_POLICY = 'createScoringPolicy';
export const CREATE_STAGE = 'createStage';
export const CREATE_DAY = 'createDay';
export const CREATE_EVENT_CONFIG = 'createEventConfig';
export const CREATE_INVITATION = 'createInvitation';
export const CREATE_SPONSOR = 'createCompetitionSponsor';
export const CREATE_AD_CAROUSEL = 'createAdCarousel';
export const CREATE_AD_CAMPAIGN = 'createAdCampaign';
export const CREATE_TICKET_LINK = 'createTicketLink';

export const FETCH_DIVISION_GROUPS = 'fetchDivisionGroups';
export const SAVE_DIVISION_GROUP = 'saveDivisionGroup';
export const CREATE_DIVISION_GROUP = 'createDivisionGroup';
export const DELETE_DIVISION_GROUP = 'deleteDivisionGroup';
export const SAVE_DIVISION_GROUP_ORDER = 'saveDivisionGroupOrder';

export const SET_NEW_VERSION_AVAILABLE = 'setNewVersionAvailable';

export const TURN_ON_CUSTOM_REGISTRATION_NAMES = 'turnOnCustomRegistrationNames';
export const TURN_OFF_CUSTOM_REGISTRATION_NAMES = 'turnOffCustomRegistrationNames';

export const TURN_ON_LEADERBOARD_VIDEO_LINKS = 'turnOnLeaderboardVideoLinks';
export const TURN_OFF_LEADERBOARD_VIDEO_LINKS = 'turnOffLeaderboardVideoLinks';

export const SAVE_INVITATION = 'saveInvitation';
export const SAVE_SCORING_MATRIX_ROW = 'saveScoringMatrixRow';
export const SAVE_ACCOUNT = 'saveAccount';
export const SAVE_PROFILE = 'saveProfile';
export const SAVE_COMPETITION_SETUP = 'saveCompetitionSetup';
export const SAVE_COMPETITION_CONTENT = 'saveCompetitionContent';
export const SAVE_COMPETITION_HASHTAGS = 'saveCompetitionHashtags';
export const SAVE_SCORING_POLICY = 'saveScoringPolicy';
export const SAVE_INVERTED_SCORING = 'saveInvertedScoring';
export const SAVE_TIME_AND_PLACE = 'saveTimeAndPlace';
export const SAVE_STRIPE_ACCOUNT = 'saveStripeAccount';
export const SAVE_REGISTRATION_DATES = 'saveRegistrationDates';
export const SAVE_REGISTRATION_FEES = 'saveRegistrationFees';
export const SAVE_COUPON = 'saveCoupon';
export const SAVE_WORKOUT = 'saveWorkout';
export const SAVE_SALE_ITEM = 'saveSaleItem';
export const SAVE_WORKOUT_ORDER = 'saveWorkoutOrder';
export const SAVE_DIVISION = 'saveDivision';
export const SAVE_DIVISION_ORDER = 'saveDivisionOrder';
export const SAVE_SEGMENT_ORDER = 'saveSegmentOrder';
export const SAVE_FIELD = 'saveField';
export const SAVE_FIELD_ORDER = 'saveFieldOrder';
export const SAVE_SCORE = 'saveScore';
export const SAVE_ATHLETE_SCORE = 'saveAthleteScore';
export const SAVE_LEADERBOARD_SCORING_POLICY = 'saveLeaderboardScoringPolicy';
export const SAVE_REGISTRATION_CONTENT = 'saveRegistrationContent';
export const SAVE_REGISTRATION_WAIVER_CONTENT = 'saveRegistrationWaiverContent';
export const SAVE_DISTRIBUTION_CONTENT = 'saveDistributionContent';
export const SAVE_LEADERBOARD_DISTRIBUTION_CONTENT = 'saveLeaderboardDistributionContent';
export const SAVE_ORGANIZATION_REGISTRATION_NOTIFICATIONS = 'saveOrganizationRegistrationNotifications';
export const SAVE_STAGE = 'saveStage';
export const SAVE_DAY = 'saveDay';
export const PUBLISH_DAY = 'publishDay';
export const UNPUBLISH_DAY = 'unpublishDay';
export const SAVE_EVENT_CONFIG = 'saveEventConfig';
export const SAVE_SPONSOR = 'saveCompetitionSponsor';
export const SAVE_AD_CAROUSEL = 'saveAdCarousel';
export const SAVE_AD_CAMPAIGN = 'saveAdCampaign';
export const SAVE_HEAT = 'saveHeat';
export const SAVE_CUSTOM_LABELS = 'saveCustomLabels';

export const MOVE_HEATS_TO_EVENT_CONFIG = 'moveHeats';

export const ATHLETE_REGISTRATION_CREATE = 'athleteRegistrationCreate';
export const ATHLETE_REGISTRATION_UPDATE = 'athleteRegistrationUpdate';
export const ATHLETE_REGISTRATION_CHANGE_DIVISION = 'athleteRegistrationChangeDivision';
export const ATHLETE_REGISTRATION_BUY_MERCH = 'athleteRegistrationBuyMerch';

export const DELETE_SCORING_POLICY = 'deleteScoringPolicy';
export const DELETE_COUPON = 'deleteCoupon';
export const DELETE_WORKOUT = 'deleteWorkout';
export const DELETE_SALE_ITEM = 'deleteSaleItem';
export const DELETE_DIVISION = 'deleteDivision';
export const DELETE_FIELD = 'deleteField';
export const DELETE_STAGE = 'deleteStage';
export const DELETE_DAY = 'deleteDay';
export const DELETE_EVENT_CONFIG = 'deleteEventConfig';
export const DELETE_SPONSOR = 'deleteCompetitionSponsor';
export const DELETE_AD_CAROUSEL = 'deleteAdCarousel';
export const DELETE_AD_CAMPAIGN = 'deleteAdCampaign';

export const DEACTIVATE_SCORING_LINK = 'deactivateScoringLink';
export const LOGOUT = 'logout';
export const SET_LOADED = 'setLoaded';
export const TOGGLE_NAV = 'toggleNav';
export const CLOSE_NAV = 'closeNav';
export const DUPLICATE_COMPETITION = 'duplicateCompetition';
export const PUBLISH_COMPETITION = 'publishCompetition';
export const UNPUBLISH_COMPETITION = 'unpublishCompetition';
export const ENABLE_SCHEDULING = 'enableScheduling';
export const DISABLE_SCHEDULING = 'disableScheduling';
export const ENABLE_SPONSORSHIP = 'enableSponsorship';
export const DISABLE_SPONSORSHIP = 'disableSponsorship';
export const ENABLE_REFUNDS = 'enableRefunds';
export const DISABLE_REFUNDS = 'disableRefunds';

export const SCORING_EXPORT_QUEUE = 'scoringExportQueue';

export const SPECTATORS_EXPORT_QUEUE = 'spectatorsExportQueue';
export const FETCH_SPECTATORS_EXPORT_STATUS = 'fetchSpectatorsExportStatus';

export const COMPETITION_EXPORT_QUEUE = 'competitionExportQueue';
export const COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS = 'scheduleRecalcAssignments';
export const COMPETITION_SCHEDULE_UPDATE_FROM_LEADERBOARD = 'scheduleUpdateFromLeaderboard';

export const FIT_EVENT_CONFIG_TO_HEATS = 'fitEventConfigToHeats';

export const SWAPPING_ATHLETE_ASSIGNMENTS = 'swappingAthleteAssignments';
export const SWAP_ATHLETE_ASSIGNMENTS = 'swapAthleteAssignments';
export const REQUEST_PUBLISH_COMPETITION = 'requestPublishCompetition';
export const STRIPE_CONNECT = 'stripeConnect';
export const WITHDRAW_SCORE = 'withdrawScore';
export const CHANGE_REGISTRATION_DIVISION = 'changeRegistrationDivision';
export const UPDATE_REGISTRATION = 'updateRegistration';
export const DELETE_REGISTRATION = 'deleteRegistration';
export const UNDELETE_REGISTRATION = 'unDeleteRegistration';
export const CHANGE_TEAM_MEMBERS = 'changeTeamMembers';
export const ACTIVATE_SCORING_LINK = 'activateScoringLink';
export const SET_DEFAULT_SCORING_CONFIG = 'setDefaultScoringConfig';
export const SHOW_NEW_COMP_MODAL = 'showNewCompModal';
export const HIDE_NEW_COMP_MODAL = 'hideNewCompModal';
export const SET_PROGRAMMING_WIZARD_COMPLETE = 'setProgrammingWizardComplete';
export const SET_REGISTRATION_WIZARD_COMPLETE = 'setRegistrationWizardComplete';
export const UPDATE_SCORE_VERIFICATION = 'updateScoreVerification';
export const FETCH_COMPETITION_FEES = 'fetchCompetitionFees';
export const UPDATE_COMPETITION_FEES = 'updateCompetitionFees';
export const CLEAR_COMPETITION = 'clearCompetition';
export const SET_REGISTRATION_ERROR = 'setRegistrationError';
export const SET_TICKET_PURCHASE_ERROR = 'setTicketPurchaseError';
export const CLEAR_TICKET_PURCHASE_ERROR = 'clearTicketPurchaseError';
export const CLEAR_REGISTRATION_ERROR = 'clearRegistrationError';
export const SAVE_WORKOUT_HEAT_PARAMS = 'saveWorkoutHeatParams';
export const IMPORT_ATHLETES = 'importAthletes';
export const ENABLE_AUTO_PUBLISH = 'enableAutoPublish';
export const DISABLE_AUTO_PUBLISH = 'disableAutoPublish';
export const SET_REGISTRATION_CAP = 'setRegistrationCap';
export const TURN_ON_UPDATE_REGISTRATION = 'turnOnUpdateRegistration';
export const TURN_OFF_UPDATE_REGISTRATION = 'turnOffUpdateRegistration';
export const TURN_ON_REGISTRATION_INVITE_REQUIRED = 'turnOnRegistrationInviteRequired';
export const TURN_OFF_REGISTRATION_INVITE_REQUIRED = 'turnOffRegistrationInviteRequired';
export const SET_LEADERBOARD_DISPLAY_PREFERENCES = 'setLeaderboardDisplayPreferences';
export const ADD_INVITEES = 'addInvitees';
export const SAVE_INVITEE_ORDER = 'saveInviteeOrder';
export const DELETE_INVITEE = 'deleteInvitee';
export const PUBLISH_INVITATION = 'publishInvitation';
export const STOP_INVITATION = 'stopInvitation';
export const PURCHASE_COMPETITION_TICKETS = 'purchaseTickets';

export const DELETE_TEAM_MEMBER = 'deleteTeamMember';

export const SET_FETCHING_ACCOUNT = 'setFetchingAccount';
export const SET_FETCHING_COMPETITIONS = 'setFetchingCompetitions';

export const CREATE_HEAT_SCHEDULE = 'createHeatSchedule';
export const FETCH_COMPETITION_SEGMENT_SCHEDULES = 'fetchCompetitionSegmentSchedules';
export const FETCH_COMPETITION_HEATS = 'fetchCompetitionHeats';
export const FETCH_COMPETITION_LANES = 'fetchCompetitionLanes';
export const FETCH_COMPETITION_ATHLETE_ASSIGNMENTS = 'fetchCompetitionAthleteAssignments';
export const FETCH_COMPETITION_JUDGES = 'fetchCompetitionJudges';
export const UPDATE_HEAT_SCHEDULE = 'updateHeatSchedule';
export const PUBLISH_HEAT_SCHEDULE = 'publishHeatSchedule';
export const UNPUBLISH_HEAT_SCHEDULE = 'unpublishHeatSchedule';
export const PUBLISH_HEAT_ASSIGNMENTS = 'publishHeatAssignments';
export const UNPUBLISH_HEAT_ASSIGNMENTS = 'unpublishHeatAssignments';
export const ASSIGN_JUDGE = 'assignJudge';
export const REMOVE_JUDGE = 'removeJudge';
export const CLEAR_PUBLISH_REQUEST_MODAL = 'clearPublishRequestModal';
export const ENABLE_DEV_KIT = 'enableDevKit';
export const DISABLE_DEV_KIT = 'disableDevKit';
export const ENABLE_TEST = 'enableTest';
export const DISABLE_TEST = 'disableTest';
export const UPDATE_ATHLETE_ASSIGNMENT = 'updateAthleteAssignment';

export const ENABLE_HEAT_SCORING = 'enableHeatScoring';
export const DISABLE_HEAT_SCORING = 'disableHeatScoring';

export const SAVE_TICKETING_HEADER_CONTENT = 'saveTicketingHeaderContent';
export const SAVE_TICKETING_DATES = 'saveTicketingDates';

export const ACTIVATE_TICKET_LINK = 'activateTicketLink';
export const DEACTIVATE_TICKET_LINK = 'deactivateTicketLink';
export const START_TRIAL = 'startCompetitionTrial';

export const REGISTRATION_CATEGORY_ATHLETE = 'athlete';
export const REGISTRATION_CATEGORY_VOLUNTEER = 'volunteer';
export const REGISTRATION_CATEGORY_SPECTATOR = 'spectator';
export const REGISTRATION_CATEGORY_JUDGE = 'judge';

export const NETWORK_ERROR = 'networkError';

export const PAGE_NOT_FOUND_ERROR = 'pageNotFoundError';

export const COUNTRIES = {
  'AF': 'Afghanistan',
  'AX': 'Åland Islands',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua and Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia (Plurinational State of)',
  'BQ': 'Bonaire, Sint Eustatius and Saba',
  'BA': 'Bosnia and Herzegovina',
  'BW': 'Botswana',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'CV': 'Cabo Verde',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CD': 'Congo (the Democratic Republic of the)',
  'CG': 'Congo',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': 'Côte d\'Ivoire',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CW': 'Curaçao',
  'CY': 'Cyprus',
  'CZ': 'Czechia',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'SZ': 'Eswatini',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands  [Malvinas]',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard Island and McDonald Islands',
  'VA': 'Holy See',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran (Islamic Republic of)',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KP': 'Korea (the Democratic People\'s Republic of)',
  'KR': 'Korea (the Republic of)',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': 'Lao People\'s Democratic Republic',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macao',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia (Federated States of)',
  'MD': 'Moldova (the Republic of)',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'MK': 'North Macedonia',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestine, State of',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Réunion',
  'RO': 'Romania',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'BL': 'Saint Barthélemy',
  'SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'KN': 'Saint Kitts and Nevis',
  'LC': 'Saint Lucia',
  'MF': 'Saint Martin (French part)',
  'PM': 'Saint Pierre and Miquelon',
  'VC': 'Saint Vincent and the Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome and Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SX': 'Sint Maarten (Dutch part)',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia and the South Sandwich Islands',
  'SS': 'South Sudan',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard and Jan Mayen',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TW': 'Taiwan (Province of China)',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania, the United Republic of',
  'TH': 'Thailand',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad and Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks and Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom of Great Britain and Northern Ireland',
  'GB-ENG': 'England',
  'GB-NIR': 'Northern Ireland',
  'GB-SCT': 'Scotland',
  'GB-WLS': 'Wales',
  'UM': 'United States Minor Outlying Islands',
  'US': 'United States of America',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VE': 'Venezuela (Bolivarian Republic of)',
  'VN': 'Viet Nam',
  'VG': 'Virgin Islands (British)',
  'VI': 'Virgin Islands (U.S.)',
  'WF': 'Wallis and Futuna',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
};
