import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import qs from 'qs';

import routes from './routes';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: 'history',
  base: '',
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
      var result = qs.stringify(query);
      return result ? ('?' + result) : '';
  },
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (router.app.$store.state.id && to.params.competitionId === undefined) {
    next({ name: to.name, params: { competitionId: router.app.$store.state.id } });
  } else {
    next();
  }
});

export default router;
