<template>
    <h3 class="mb-3">
        <b-button @click="onPrevClick" variant="light" size="sm">
            <icon name="caret-left"></icon>
        </b-button>
        <span v-if="currentDivision">{{ currentDivision.title }}</span>
        <b-button @click="onNextClick" variant="light" size="sm">
            <icon name="caret-right"></icon>
        </b-button>
    </h3>
</template>
<script>
export default {
    name: 'division-pager',
    props: ['divisions', 'value'],
    data() {
        return {
            index: 0
        }
    },
    created() {
        if (this.value) {
            this.index = this.divisions.indexOf(this.value);
        }
    },
    computed: {
        currentDivision() {
            return this.divisions[this.index];
        }
    },
    methods: {
        onPrevClick() {
            this.index === 0 ? this.index = this.divisions.length - 1 : this.index--;
            this.$emit('input', this.currentDivision);
        },
        onNextClick() {
            this.index === this.divisions.length - 1 ? this.index = 0 : this.index++;
            this.$emit('input', this.currentDivision);
        }
    }
}
</script>
