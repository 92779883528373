import 'regenerator-runtime/runtime';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Clipboard from 'v-clipboard';
import Toasted from 'vue-toasted';
import VueYoutube from 'vue-youtube';
import VueIntercom from 'vue-intercom';
import VueAnalytics from 'vue-analytics';
import VueObserveVisibility from 'vue-observe-visibility';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

import store from './store';
import router from './router';
import i18n from './translations/i18n';
import App from './App.vue';

import plugins from './plugins';

import globalComponents from './components';

require('intersection-observer');

const {
  INTERCOM_APP_ID,
  GOOGLE_ANALYTICS_ID,
  // PINAX_STRIPE_PUBLIC_KEY,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  RELEASE_VERSION,
} = window.ThrowdownsConfig;

const cookiesAreEnabled = () => {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
  }
  return cookieEnabled;
};

if (SENTRY_DSN) {
  Sentry.init({
      Vue,
      dsn: SENTRY_DSN,
      release: RELEASE_VERSION,
      environment: SENTRY_ENVIRONMENT,
      attachStacktrace: true,
      tracingOptions: {
        trackComponents: true,
      },
      tracing: true,
      attachProps: true,
      logErrors: true,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.2,
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Google Analytics
        /googletagmanager.com\/gtag/i,
      ],
      ignoreErrors: [
        'Non-Error promise rejection captured', // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      ],
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        const ignores = [
          /Non-Error promise rejection captured with value/i,
          /should_do_lastpass_here/i,
          /ceCurrentVideo.currentTime/i,
          /Maximum call stack size exceeded/i,
          /ResizeObserver loop completed with undelivered notifications/i,
          /webkit.messageHandlers.loginsManagerMessageHandler.postMessage/i,
          /_AutofillCallbackHandler/i,
        ];
        if (error && error.response && error.response.status === 404) {
          return null;
        }
        if (error && error.message && ignores.filter(msg => error.message.match(msg)).length > 0) {
          return null;
        }
        return event;
      }
  });
}

export default () => {
  if (document.getElementById('app')) {
    Vue.use(BootstrapVue);
    Vue.use(Clipboard);
    Vue.use(Toasted, { router });
    Vue.use(VueYoutube);
    Vue.use(VueObserveVisibility);
    Vue.use(plugins.CompetitionPlugin);

    /* Defaults to the test sandbox for local/staging */
    Vue.use(VueIntercom, { appId : INTERCOM_APP_ID });

    /* No relevent for local/staging unless specifically implementing
     * event tracking features in which case any id could be used for
     * dev purposes */
    if (GOOGLE_ANALYTICS_ID) {
      Vue.use(VueAnalytics, {
          id : GOOGLE_ANALYTICS_ID,
          /* Handy for debugging in the browser console... */
          /*
          router,
          debug: {
            enabled: true
          }
         */
      });
    }

    Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);
    globalComponents.forEach(component => {
      Vue.component(component.name, component);
    });

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      computed: {
        email() {
          return this.$store.state.account.email;
        },
        isOrganizer() {
          return this.$store.state.account.isOrganizer;
        }
      },
      watch: {
        '$route' (route) {
            this.$intercom.update({
              hide_default_launcher: route.meta.public && !this.email
            });
        },
        email: {
          immediate: true,
          handler() {
            if (this.email) {
              this.$intercom.update({
                email: this.email,
                user_hash: this.$store.state.account.userhash,
              });

              Sentry.configureScope((scope) => {
                scope.setUser({
                  email: this.email
                });
                scope.setTag('cookiesEnabled', cookiesAreEnabled());
              });
            }
          }
        },
        isOrganizer: {
          immediate: true,
          handler() {
            this.bootIntercom();
          },
        }
      },
      methods: {
        bootIntercom() {
          if (this.$store.state.account.isOrganizer) {
            this.$intercom.boot({
                email: this.email,
                user_hash: this.$store.state.account.userhash,
                hide_default_launcher: this.$route.meta.public && !this.email
            });
          }
        },
      },
      mounted() {
        this.bootIntercom();
      },
      render: h => h(App),
      store,
      router,
      i18n
    });
  }
};
