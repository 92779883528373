<template>
  <div class="modal" v-if="show">
    <div class="modal-content">
      <h1>{{$t('youtubeEmbed')}}</h1>
      <b-form-group :label="$t('pasteYourYouTubeUrl')">
        <b-input v-model="url" id="url" />
      </b-form-group>
      <div class="modal-actions">
        <button class="btn btn-light" @click="show = false;">{{$t('cancel')}}</button>
        <button class="btn btn-primary ml-2" @click="insertVideo">{{$t('addVideo')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      command: null,
      show: false
    };
  },
  computed: {
    youtubeID() {
      // replace with this.$youtube.getIdFromUrl(this.url);
      return this.youtubeParser(this.url);
    }
  },
  methods: {
    youtubeParser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
    },
    insertVideo() {
      // Some check can be done here, like if `youtubeID` is not false.
      const data = {
        command: this.command,
        data: {
          src: this.youtubeID
        }
      };

      this.$emit('onConfirm', data);
      this.show = false;
      this.url = '';
    }
  }
};
</script>

<style scoped>
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    width: 100%;
    max-width: 600px;
    padding: 1.5rem 2rem;
    border-radius: 4px;
  }

  .modal-actions {
    text-align: right;
  }
</style>

<style>
  .video-container {
    margin-bottom: 20px;
  }
</style>
