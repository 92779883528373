<template>
    <div class="place-map" id="place-map"></div>
</template>
<script>
import loadGoogleMaps from '../googleMaps';

export default {
    name: 'google-map',
    props: ['place', 'name'],
    data() {
        return {
            marker: null,
            scriptLoaded: false,
            checkForLoadedScriptTimeout: null,
        }
    },
    methods: {
        drawMap() {
            const element = document.getElementById('place-map');
            if (window.google !== undefined && this.place && this.place.lat && this.place.lng) {
                const center = new window.google.maps.LatLng(
                    this.place.lat,
                    this.place.lng
                );
                const options = {zoom: 14, center}
                const map = new window.google.maps.Map(element, options);
                this.marker = new window.google.maps.Marker({
                    position: center,
                    map: map,
                    title: this.name
                });
            }
        },
        stopCheckingScriptLoaded() {
            if (this.checkForLoadedScriptTimeout !== null) {
                clearTimeout(this.checkForLoadedScriptTimeout);
            }
        },
        updateScriptLoaded() {
            if (window.google) {
                this.scriptLoaded = true;
                this.stopCheckingScriptLoaded();
            }
        }
    },
    watch: {
        place() {
           this.drawMap();
        },
        name() {
            if (this.marker !== null) {
                this.marker.title = this.name;  // not working; boo
            }
        },
        scriptLoaded: {
            immediate: true,
            handler() {
                if (this.scriptLoaded) {
                    this.drawMap();
                }
            }
        }
    },
    created() {
        loadGoogleMaps();
        this.updateScriptLoaded();
        this.checkForLoadedScriptTimeout = setTimeout(this.updateScriptLoaded.bind(this), 500);
    },
    destroyed() {
        this.stopCheckingScriptLoaded();
    }
}
</script>
<style scoped>
.place-map {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  background: gray;
}
</style>
