import 'core-js';

import 'scss/site.scss';
import 'images/site.webmanifest';

import loadApp from './app';

loadApp();

const ready = fn => {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
};

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

window.ready = ready;
