<template>
  <vue-timepicker
    class=""
    :value="model"
    @input="handleInput"
    :format="format"
    v-bind="$attrs"
  />
</template>

<script>
import VueTimepicker from 'vuejs-timepicker';

const military = window.ThrowdownsConfig.USE_TWENTY_FOUR_HOUR_CLOCKS;

export default {
  name: 'TimePicker',

  components: { VueTimepicker },

  props: ['value', 'part'],

  data() {
    return {
      model: this.parseModel(`${this.value}`)
    };
  },

  computed: {
    format() {
      if (military) return "HH:mm"
      return "hh:mm A"
    },

    mode() {
      if (typeof this.value === 'number') return 'minutes'
      return 'fullString'
    }
  },

  methods: {
    handleInput(model) {
      this.fillModel(model)
      this.model = model

      // If the value input is in minutes, the output is in minutes
      // If the input was a time string, the output is a time string
      this.$emit('input', model[this.mode])
    },

    parseModel(value) {
      const time = value ? value.split(' ').pop() : '08:00';
      const pieces = time.split(':');
      const mm = parseInt(pieces.pop(), 10)
      const HH = parseInt(pieces.pop() || 0, 10)
      const m = {
        HH: `${HH + Math.floor(mm / 60)}`.padStart(2, '0'),
        mm: `${mm % 60}`.padStart(2, '0')
      }
      this.fillTwentyFourHourModel(m)
      return m
    },

    fillModel(obj) {
      if (military) this.fillTwentyFourHourModel(obj)
      else this.fillTwelveHourModel(obj)
    },

    fillTwentyFourHourModel(obj) {
      const H = parseInt(obj.HH, 10);
      const h = (H % 12) || 12;
      const m = parseInt(obj.mm, 10);
      const A = H >= 12 ? 'PM': 'AM';

      obj.H = `${H}`
      obj.h = `${h}`
      obj.hh = obj.h.padStart(2, '0')
      obj.m = `${m}`
      obj.A = A
      obj.a = A.toLowerCase()
      obj.minutes = H * 60 + m

      this.fillTimeString(obj)
    },

    fillTwelveHourModel(obj) {
      const pm = obj.A == 'PM'
      const H = (parseInt(obj.hh, 10) % 12) + (pm ? 12 : 0)
      obj.HH = `${H}`.padStart(2, '0')
      this.fillTwentyFourHourModel(obj)
    },

    fillTimeString(obj) {
      obj.fullString = `${obj.HH}:${obj.mm}`;
    }


  }
}
</script>
