const { NODE_ENV, GOOGLE_API_KEY } = window.ThrowdownsConfig;

let isAppended;

export const loadGoogleMaps = () => {
  if (!isAppended) {
    if (GOOGLE_API_KEY) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
      document.head.appendChild(script);
      isAppended = true;
    } else {
      if (NODE_ENV && NODE_ENV === 'development') {
        console.warn(
          'Please add a GOOGLE_API_KEY value to use google places'
        );
      }
    }
  }
};

export default loadGoogleMaps;
