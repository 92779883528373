<template>
  <b-form-group :description="description" :label="label">
    <b-form-input :value="value" @input="value => $emit('input', value)" :state="state" />
    <b-form-invalid-feedback>
      Max length is 100 characters
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
  export default {
    props: {
      label: String,
      description: String,
      value: String
    },
    computed: {
      state() {
        return this.value.length > 100 ? false : null;
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
