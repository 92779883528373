<template>
    <div class="item-list">
        <slot />
    </div>
</template>
<script>
import { ContainerMixin } from 'vue-slicksort';
export default {
    name: 'item-list',
    mixins: [ContainerMixin]
}
</script>
