<template>
  <modal>
    <div class="image-container">
      <img :src="url || score.imageLink" />
    </div>
    <div class="button-container m-2">
      <button class="btn btn-primary btn-block" @click.prevent="onClose">{{$t('close')}}</button>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'score-image-modal',
    props: ['score', 'url'],
    methods: {
      onClose() {
        this.$emit('close');
      }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../scss/config';
  img {
    width: 100%;
  }
  .btn-primary {
    background: var(--primary-button-background-color, $primary);
    border-color: var(--primary-button-background-color, $primary);
    color: var(--primary-button-text-color, $white);
  }
</style>
