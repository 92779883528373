import { faArrowSquareUp } from '@fortawesome/pro-light-svg-icons/faArrowSquareUp';
import { faArrowSquareDown } from '@fortawesome/pro-light-svg-icons/faArrowSquareDown';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faRandom } from '@fortawesome/pro-light-svg-icons/faRandom';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-solid-svg-icons/faLockOpen';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons/faLocationArrow';
import { faRulerTriangle } from '@fortawesome/pro-light-svg-icons/faRulerTriangle';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faDumbbell } from '@fortawesome/pro-light-svg-icons/faDumbbell';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faLevelDown } from '@fortawesome/pro-light-svg-icons/faLevelDown';
import { faLevelUp } from '@fortawesome/pro-light-svg-icons/faLevelUp';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faLongArrowUp } from '@fortawesome/pro-light-svg-icons/faLongArrowUp';
import { faLongArrowDown } from '@fortawesome/pro-light-svg-icons/faLongArrowDown';
import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faMinus as faMinusSolid } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus as faPlusSolid } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPowerOff } from '@fortawesome/pro-light-svg-icons/faPowerOff';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { faGauge } from '@fortawesome/pro-light-svg-icons/faGauge';
import { faTicketSimple } from '@fortawesome/pro-light-svg-icons/faTicketSimple';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons/faCircleXmark';
import { faSquareXmark } from '@fortawesome/pro-light-svg-icons/faSquareXmark';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faShirt } from '@fortawesome/pro-light-svg-icons/faShirt';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faExclamation } from '@fortawesome/pro-light-svg-icons/faExclamation';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faPaintRoller } from '@fortawesome/pro-light-svg-icons/faPaintRoller';
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faWindow } from '@fortawesome/pro-light-svg-icons/faWindow';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faPerson } from '@fortawesome/pro-light-svg-icons/faPerson';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faVial } from '@fortawesome/pro-light-svg-icons/faVial';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faRocket } from '@fortawesome/pro-light-svg-icons/faRocket';
import { faSignInAlt } from '@fortawesome/pro-light-svg-icons/faSignInAlt';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faHourglass } from '@fortawesome/pro-light-svg-icons/faHourglass';
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faGripLinesVertical } from '@fortawesome/pro-light-svg-icons/faGripLinesVertical';
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faToggleOff } from '@fortawesome/pro-light-svg-icons/faToggleOff';
import { faToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn';
import { faTv } from '@fortawesome/pro-light-svg-icons/faTv';
import { faTableList } from '@fortawesome/pro-light-svg-icons/faTableList';
import { faGavel } from '@fortawesome/pro-light-svg-icons/faGavel';
import { faSortNumericUpAlt } from '@fortawesome/pro-light-svg-icons/faSortNumericUpAlt';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faMars } from '@fortawesome/pro-light-svg-icons/faMars';
import { faVenus } from '@fortawesome/pro-light-svg-icons/faVenus';
import { faAward } from '@fortawesome/pro-light-svg-icons/faAward';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faRectangleAd } from '@fortawesome/pro-light-svg-icons/faRectangleAd';
import { faConveyorBeltAlt } from '@fortawesome/pro-light-svg-icons/faConveyorBeltAlt';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTags } from '@fortawesome/pro-light-svg-icons/faTags';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons/faCircleSmall';
import { faPeopleCarryBox } from '@fortawesome/pro-light-svg-icons/faPeopleCarryBox';

import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic';
import { faStrikethrough } from '@fortawesome/pro-solid-svg-icons/faStrikethrough';
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline';
import { faParagraph } from '@fortawesome/pro-solid-svg-icons/faParagraph';
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faH1 } from '@fortawesome/pro-solid-svg-icons/faH1';
import { faH2 } from '@fortawesome/pro-solid-svg-icons/faH2';
import { faH3 } from '@fortawesome/pro-solid-svg-icons/faH3';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faHorizontalRule } from '@fortawesome/pro-solid-svg-icons/faHorizontalRule';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons/faRotateLeft';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons/faRotateRight';
import { faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons/faArrowRotateRight';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faCircleS } from '@fortawesome/pro-regular-svg-icons/faCircleS';
import { faCircleF } from '@fortawesome/pro-regular-svg-icons/faCircleF';
import { faCircleL } from '@fortawesome/pro-regular-svg-icons/faCircleL';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate';

import { faTie } from './tie';

faMinusSolid.iconName = 'minus-solid';
faPlusSolid.iconName = 'plus-solid';

const iconMap = [
  faEnvelopeOpenText,
  faArrowRotateRight,
  faAlignLeft,
  faPlusSolid,
  faGripLinesVertical,
  faLocationDot,
  faFlask,
  faRocket,
  faTwitter,
  faCamera,
  faSpinner,
  faGavel,
  faCode,
  faDownload,
  faFacebookF,
  faInstagram,
  faPiggyBank,
  faEnvelope,
  faCircle,
  faSortNumericUpAlt,
  faArrowSquareUp,
  faFloppyDisk,
  faArrowSquareDown,
  faLocationArrow,
  faSquare,
  faRandom,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faExclamation,
  faMagnifyingGlass,
  faArrowDown,
  faBan,
  faBell,
  faToggleOff,
  faToggleOn,
  faBuilding,
  faPeopleCarryBox,
  faBullhorn,
  faPrint,
  faCalendar,
  faCalendarCheck,
  faCalendarDays,
  faComment,
  faCaretLeft,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faLayerGroup,
  faReceipt,
  faCaretUp,
  faCaretRight,
  faChartLine,
  faCheck,
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faCircleXmark,
  faSquareCheck,
  faClipboardList,
  faClock,
  faLock,
  faLockOpen,
  faPaintRoller,
  faRulerTriangle,
  faAddressCard,
  faDumbbell,
  faFileContract,
  faQuestion,
  faLevelDown,
  faLevelUp,
  faLongArrowUp,
  faLongArrowDown,
  faPerson,
  faColumns,
  faCopy,
  faGear,
  faDollarSign,
  faEye,
  faEyeSlash,
  faHashtag,
  faTag,
  faTags,
  faDatabase,
  faPencil,
  faVial,
  faPlus,
  faMinus,
  faSlidersH,
  faGauge,
  faTicketSimple,
  faXmark,
  faSquareXmark,
  faTrashCan,
  faTrophy,
  faCircleSmall,
  faShirt,
  faTableList,
  faTv,
  faBars,
  faPowerOff,
  faStar,
  faUserCircle,
  faUsers,
  faUser,
  faUserSlash,
  faUserPlus,
  faCircleInfo,
  faSync,
  faTable,
  faUpload,
  faTie,
  faFileAlt,
  faExternalLink,
  faLink,
  faSparkles,
  faWindow,
  faSignInAlt,
  faSignOut,
  faYoutube,
  faStopwatch,
  faHourglass,
  faFileExport,
  faMars,
  faVenus,
  faAward,
  faUserGroup,
  faRectangleAd,
  faConveyorBeltAlt,
  faFilter,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faH1,
  faH2,
  faH3,
  faQuoteLeft,
  faHorizontalRule,
  faRotateLeft,
  faRotateRight,
  faImage,
  faMinusSolid,
  faCircleS,
  faCircleF,
  faCircleL,
  faArrowsRotate,
].reduce((map, obj) => {
  map[obj.iconName] = obj;
  return map;
}, {});

export default iconMap;
