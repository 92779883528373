<template>
<div ref="fileUploaderContainer">
    <div v-if="croppingImage" class="uploader" :style="{ 'width': `${cropperWidthConstraint}px` }">
      <div>
        <div :style="{ 'width': `${cropperWidthConstraint}px`, 'height': `${cropperHeightConstraint}px`, maxWidth: `${cropperMaxWidth}px` }">
          <img ref="imageUpload" id="uploadimage" :src="img.src">
        </div>
        <p v-if="cropper && cropper.ready" class="text-center mt-2 mb-0">
          <button @click="finishCrop" class="btn btn-primary">{{ $t('finishCropping') }}</button>
          <button @click="cancelCrop" class="btn btn">{{ $t("cancel") }}</button>
        </p>
      </div>
    </div>
    <div v-else class="dropzone uploader" :class="{ default: !img && !existingImageUrl }" :style="{ 'width': `${cropperWidthConstraint}px`, 'height': `${cropperHeightConstraint}px`, maxWidth: `${cropperMaxWidth}px` }">
        <input type="file" @change="filesChange($event.target.name, $event.target.files)" accept="image/x-png,image/gif,image/jpeg">
        <img ref="currentImage" v-if="img && !croppingImage" :src="img.src">
        <template v-else>
            <img v-if="!img" :src="placeholderImg" />
            <div class="message" :class="cropperMessageSize">{{ $t("dragOrClickPhoto") }}</div>
        </template>
    </div>
    <div v-if="warning" class="warning mt-1">{{ warning }}</div>
</div>
</template>
<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';
const defaultHexLogo = window.ThrowdownsConfig.LOGO_DARK;

export default {
    name: 'file-upload',
    props: ['value', 'existingImageUrl', 'cropperWidth', 'cropperHeight'],
    data() {
        return {
            img: null,
            cropper: null,
            inputFile: null,
            width: 0,
            height: 0,
            croppingImage: false,
            cropperWidthConstraint: this.cropperWidth,
            cropperHeightConstraint: this.cropperHeight,
            cropperMaxWidth: 768
        };
    },
    created() {
        window.addEventListener('resize', this.onResizeWindow);
    },
    destroyed() {
        window.removeEventListener('resize', this.onResizeWindow);
    },
    mounted() {
      this.$nextTick(() => this.onResizeWindow());
    },
    methods: {
        cancelCrop() {
          this.croppingImage = false;
          this.img = null;
        },
        finishCrop() {
          console.log('finish crop')
          this.cropper.getCroppedCanvas({
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high',
              width: this.cropperWidth,
              height: this.cropperHeight
          })
          .toBlob((blob) => {
            if (blob && blob.type && blob.type.indexOf('image') === 0) {
              this.img = new Image();
              this.img.src = URL.createObjectURL(blob);
              this.$emit('input', { meta: {}, file: blob, name: this.inputFile.name });
            }
            this.croppingImage = false;
           })
        },
        filesChange(name, files) {
            this.inputFile = files[0];
            this.img = new Image();
            this.img.src = URL.createObjectURL(files[0]);
            this.img.onload = () => {
                this.width = this.img.width;
                this.height = this.img.height;
                if (this.$refs.currentImage) {
                  const imageRatioDiff = (this.img.height / this.img.width) - 0.33;
                  const imageAdjustment = (imageRatioDiff + (imageRatioDiff / 2)) * -100;
                  this.$refs.currentImage.style.top = `${imageAdjustment}%`;
                }
            };

            if (!HTMLCanvasElement.prototype.toBlob) {
                const size = this.inputFile.size / 1000000;
                if (size > 10) {
                  alert("Your file is too large. Please choose a file smaller than 10mb")
                  this.img.onload = null;
                  this.img = null;
                  this.inputFile = null;
                } else {
                  this.$emit('input', {meta: {}, file: this.inputFile, name: this.inputFile.name});
                }
            } else {
              this.initCropper();
            }
        },
        initCropper() {
          this.croppingImage = true;
          this.$nextTick(function() {
            const uploadImage = this.$refs.imageUpload;
            this.cropper = new Cropper(uploadImage, {
              aspectRatio: this.cropperWidth / this.cropperHeight,
              crop(event) {}
            });
          })
        },
        onResizeWindow() {
          this.cropperMaxWidth = this.$refs.fileUploaderContainer.offsetWidth;
          this.cropperWidthConstraint = Math.min(window.innerWidth - 20, this.cropperWidth, this.cropperMaxWidth);
          this.cropperHeightConstraint = this.cropperWidthConstraint / (this.cropperWidth / this.cropperHeight);
        }
    },
    computed: {
        placeholderImg() {
          return (this.existingImageUrl && this.existingImageUrl.file ? URL.createObjectURL(this.existingImageUrl.file) : this.existingImageUrl) || defaultHexLogo;
        },
        warning() {
            if (this.img && (this.width < this.cropperWidth || this.height < this.cropperHeight)) {
              return this.$t("imageWarning", {
                width: this.width,
                height: this.height,
                recommendedWidth: this.cropperWidth,
                recommendedHeight: this.cropperHeight
              });
            }
        },
        cropperMessageSize() {
          if (this.cropperWidth >= 1200) {
            return 'cropper-message-large';
          }
          if (this.cropperWidth >= 600) {
            return 'cropper-message-medium';
          }
          if (this.cropperWidth >= 300) {
            return 'cropper-message-small';
          }

          return 'cropper-message-extra-small';
        }
    }
}
</script>
<style lang="scss" scoped="true">
.cropper-message-large {
  font-size: 36px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.cropper-message-medium {
  font-size: 26px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.cropper-message-small {
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.cropper-message-extra-small {
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.warning {
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
</style>
