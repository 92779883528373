import { toDate as toDateFNS, format } from 'date-fns-tz';
import addMinutes from 'date-fns/addMinutes';

// This list comes from Wikipedia deprecated timezones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
const deprecatedList = {
  'Australia/ACT': 'Australia/Sydney',
  'Australia/LHI': 'Australia/Lord_Howe',
  'Australia/North': 'Australia/Darwin',
  'Australia/NSW': 'Australia/Sydney',
  'Australia/Queensland': 'Australia/Brisbane',
  'Australia/South': 'Australia/Adelaide',
  'Australia/Tasmania': 'Australia/Hobart',
  'Australia/Victoria': 'Australia/Melbourne',
  'Australia/West': 'Australia/Perth',
  'Brazil/Acre': 'America/Rio_Branco',
  'Brazil/DeNoronha': 'America/Noronha',
  'Brazil/East': 'America/Sao_Paulo',
  'Brazil/West': 'America/Manaus',
  'Canada/Atlantic': 'America/Halifax',
  'Canada/Central': 'America/Winnipeg',
  'Canada/Eastern': 'America/Toronto',
  'Canada/Mountain': 'America/Edmonton',
  'Canada/Newfoundland': 'America/St_Johns',
  'Canada/Pacific': 'America/Vancouver',
  'Canada/Saskatchewan': 'America/Regina',
  'Canada/Yukon': 'America/Whitehorse',
  'CET': 'Europe/Paris',
  'Chile/Continental': 'America/Santiago',
  'Chile/EasterIsland': 'Pacific/Easter',
  'CST6CDT': 'America/Chicago',
  'Cuba': 'America/Havana',
  'EET': 'Europe/Sofia',
  'Egypt': 'Africa/Cairo',
  'Eire': 'Europe/Dublin',
  'EST': 'America/Cancun',
  'EST5EDT': 'America/New_York',
  'Etc/Greenwich': 'Etc/GMT',
  'Etc/Universal': 'Etc/UTC',
  'Etc/Zulu': 'Etc/UTC',
  'GB': 'Europe/London',
  'GB-Eire': 'Europe/London',
  'GMT+0': 'Etc/GMT',
  'GMT0': 'Etc/GMT',
  'GMT−0': 'Etc/GMT',
  'Greenwich': 'Etc/GMT',
  'Hongkong': 'Asia/Hong_Kong',
  'HST': 'Pacific/Honolulu',
  'Iceland': 'Atlantic/Reykjavik',
  'Iran': 'Asia/Tehran',
  'Israel': 'Asia/Jerusalem',
  'Jamaica': 'America/Jamaica',
  'Japan': 'Asia/Tokyo',
  'Kwajalein': 'Pacific/Kwajalein',
  'Libya': 'Africa/Tripoli',
  'MET': 'Europe/Paris',
  'Mexico/BajaNorte': 'America/Tijuana',
  'Mexico/BajaSur': 'America/Mazatlan',
  'Mexico/General': 'America/Mexico_City',
  'MST': 'America/Phoenix',
  'MST7MDT': 'America/Denver',
  'Navajo': 'America/Denver',
  'NZ': 'Pacific/Auckland',
  'NZ-CHAT': 'Pacific/Chatham',
  'Poland': 'Europe/Warsaw',
  'Portugal': 'Europe/Lisbon',
  'PRC': 'Asia/Shanghai',
  'PST8PDT': 'America/Los_Angeles',
  'ROC': 'Asia/Taipei',
  'ROK': 'Asia/Seoul',
  'Singapore': 'Asia/Singapore',
  'Turkey': 'Europe/Istanbul',
  'UCT': 'Etc/UCT',
  'Universal': 'Etc/UTC',
  'US/Alaska': 'America/Anchorage',
  'US/Aleutian': 'America/Adak',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/Eastern': 'America/New_York',
  'US/East-Indiana': 'America/Indiana/Indianapolis',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Indiana-Starke': 'America/Indiana/Knox',
  'US/Michigan': 'America/Detroit',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Pacific-New': 'America/Los_Angeles',
  'US/Samoa': 'Pacific/Pago_Pago',
  'WET': 'Europe/Lisbon',
  'W-SU': 'Europe/Moscow',
  'Zulu': 'Etc/UTC'
};

export const toDate = (dateString, timeZone) => {
  const replace = deprecatedList[timeZone];
  if (replace) timeZone = replace;

  try {
    return toDateFNS(dateString, { timeZone });
  } catch (e) {
    return toDateFNS(dateString, { timeZone: 'Etc/UTC' });
  }
};

export const parseDateParts = (dateString) => {
  // Assumes date string in yyyy-MM-dd HH:mm format
  const [date, time] = dateString.replace('T', ' ').split(' ');
  const [year, month, day] = date.split('-');
  const [hour, minute] = time.split(':');
  return new Date(...[
    parseInt(year, 10),
    parseInt(month, 10) - 1, // Date expects an month index
    parseInt(day, 10),
    parseInt(hour, 10),
    parseInt(minute, 10),
  ]);
};

export const addMin = (dateString, timeZone, minutes) => {
  // Can use Date() here because we are parsing right back to string so timezone doesn't matter
  return format(addMinutes(parseDateParts(dateString), minutes), 'yyyy-MM-dd HH:mm', { timeZone });
};

export const formatDate = (dateString, timeZone, formatString) => {
  return format(toDate(dateString, timeZone), formatString);
};

export const formatDateString = (dateString, timeZone, formatString) => {
  return format(parseDateParts(dateString), formatString, { timeZone });
};

export const minutesToDisplayTime = minutes => {
  if (minutes > 1440) return '??:??';
  const hours = `${Math.floor(minutes / 60)}`.padStart(2, '0');
  const mins = `${minutes % 60}`.padStart(2, '0');
  return `${hours}:${mins}`;
};
