<template>
<div ref="carouselRef" class="glide">
    <div data-glide-el="track" class="glide__track">
        <ul class="glide__slides">
            <li v-for="(slide, index) in slides" :key="index" class="glide__slide" :class="{ 'glide__slide--muted': muted }">
                <slot v-bind:slide="slide"></slot>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css';
import Glide from '@glidejs/glide';

export default {
    name: 'carousel',
    props: {
        slides: Array,
        slideWidth: Number,
        muted: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            element: null
        };
    },
    mounted() {
        this.element = new Glide(this.$refs.carouselRef, this.getGlideSettings()).mount();
        window.addEventListener('resize', this.onResizeWindow);
    },
    destroyed() {
        window.removeEventListener('resize', this.onResizeWindow);
    },
    watch: {
        slides() {
            this.refreshCarousel();
        }
    },
    methods: {
        onResizeWindow() {
            this.refreshCarousel();
        },
        getGlideSettings() {
          const slideLength = this.slides.length;
            const offsetWidth = this.$refs.carouselRef.offsetWidth;
            const slideWidth = this.slideWidth;
            const perView =  Math.min(6, slideLength, Math.max(1, Math.floor(offsetWidth / slideWidth)));
            this.$refs.carouselRef.children[0].children[0].querySelectorAll('img').forEach(img => img.style.maxWidth = `${this.slideWidth}px`);
            return {
              perView,
              autoplay: (this.slides.length > perView) && 3000,
              animationDuration: 800,
              type: 'carousel',
              gap: 0,
            }
        },
        calculateBreakpoints(width, perView) {
            const breakpoints = {};
            for (let breakpoint = 1; breakpoint <= perView; breakpoint++) {
                breakpoints[breakpoint  * width] = { perView: breakpoint };
            }

            return breakpoints;
        },
        refreshCarousel() {
            this.element.update(this.getGlideSettings())
        }
    }
}
</script>
<style lang="scss" scoped="true">
@import '../../../scss/config';

.glide {
    padding: 1rem 0;
    margin: 0 auto;

    ul {
        margin: 0;
    }

    .glide__slide {
        text-align: center;
        a {
            display: inline-block;
        }

        &--muted img{
            opacity: 0.2;
            filter: grayscale(1);
            &:hover {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }

}
</style>
