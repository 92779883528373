<template>
    <div class="item" :class="{expanded: expanded || deleteItem}">
        <div class="title-ribbon" v-if="showTitle">
            <span>
                <span v-if="noEditing && !notSortable" v-handle class="handle"></span>
                <span v-else class="no-handle"></span>
                <span class="item-title"><span>{{ title }}</span><slot name="extra-title"></slot></span>
            </span>
            <b-button v-if="expanded || deleteItem" variant="link" @click="cancel()">
                <icon v-if="cancelIcon" :name="cancelIcon" />
                <span v-else>{{ $t("cancel") }}</span>
            </b-button>
            <span class="item-actions" v-else>
                <slot name="details" />
                <b-button :disabled="disableActions" v-if="showEdit" variant="link" @click="edit()"><icon :name="editIcon" /></b-button>
                <b-button :disabled="disableActions" class="text-danger" v-if="noDeletion !== true" variant="link" @click="flagForDelete()"><icon name="trash-can" /></b-button>
            </span>
        </div>
        <div class="item-form" v-if="expanded && !deleteItem">
            <slot name="item-form"></slot>
            <submitting-button @click="save" v-if="!noSave" :disabled="canSubmit === false" :with-cancel="!showTitle" @cancel="cancel">{{ $t("save") }}</submitting-button>
        </div>
        <div class="item-form" v-if="deleteItem && requireConfirmationOnDelete">
            <slot v-if="disableDeletion" name="deletion-warning" />
            <div v-else>
                {{ $t("deleteAreYouSure") }} <slot name="extra-delete-description"></slot>
                <submitting-button variant="danger" :action-label="$t('deleting')" @click="confirmDelete">
                    {{ $t('yesDelete') }}
                </submitting-button>
            </div>
        </div>
    </div>
</template>
<script>
import { ElementMixin, HandleDirective } from 'vue-slicksort';

export default {
    name: 'item',
    directives: { handle: HandleDirective },
    data() {
        return {
            deleteItem: false,
            expanded: false
        }
    },
    mixins: [ElementMixin],
    props: {
        title: {
            type: String
        },
        noEditing: {
            type: Boolean,
        },
        competition: {
            type: Object,
        },
        canSubmit: {
            type: Boolean,
        },
        notSortable: {
            type: Boolean,
        },
        noDeletion: {
            type: Boolean,
        },
        noSave: {
            type: Boolean,
        },
        disableActions: {
            type: Boolean,
            default: false,
        },
        disableDeletion: {
            type: Boolean,
            default: false,
        },
        editIcon: {
            type: String,
            default: 'pencil'
        },
        cancelIcon: {
            type: String,
            default: null,
        },
        expand: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        showEdit: {
            type: Boolean,
            default: true
        },
        requireConfirmationOnDelete: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        expand(expand) {
            this.expanded = expand;
        }
    },
    created() {
        if (this.expand) {
            this.expanded = true;
            this.$emit('editing');
        }
    },
    methods: {
        flagForDelete() {
            this.deleteItem = true;
            if (this.requireConfirmationOnDelete) {
                this.$emit("editing");
            } else {
                this.confirmDelete();
            }
        },
        edit() {
            this.expanded = true;
            this.$emit("editing");
        },
        cancel() {
            this.expanded = false;
            this.deleteItem = false;
            this.$emit("cancelEditing");
        },
        save(done) {
            this.$emit('saveItem', () => {
                this.expanded = false;
                this.deleteItem = false;
                done();
            });
        },
        confirmDelete(done) {
            this.$emit('deleteItem', () => {
                this.expanded = false;
                this.deleteItem = false;
                if (typeof done === 'function') {
                    done();
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped="true">
.item {
    background-color: #FFF;
}
</style>
