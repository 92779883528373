import axios from 'axios';
import { id } from 'date-fns/locale';
import qs from 'qs';
import http from './http';

const { DOWNLOADS, HTTP, PUBLIC_HTTP } = http;
const { RELEASE_VERSION, API_BASE_URL } = window.ThrowdownsConfig;

let getDivisionScoreMatrixCancelToken;

const queryString = obj => qs.stringify(obj, {arrayFormat: 'repeat'});

const handlePublic = cb => r => {
  if (r.data.version && r.data.version !== RELEASE_VERSION) {
    throw { response: { status: 418 } };
  }
  return cb(r.data);
};

async function ms(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function getLeaderboard(url) {
  let response = await PUBLIC_HTTP.get(url);

  try {
    while (response.data.data.status === 'waiting') {
      await ms(3000);
      response = await PUBLIC_HTTP.get(url);
    }
  } catch (e) {
    console.warn(e);
  }

  return response;
}

export default {
  getPublicProfile: (username, cb) => PUBLIC_HTTP.get(`athletes/${username}/`).then(handlePublic(cb)),
  getPublicCompetition: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/`).then(handlePublic(cb)),
  getPublicCompetitionDivisions: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/divisions/`).then(handlePublic(cb)),
  getPublicCompetitionDivisionGroups: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/division-groups/`).then(handlePublic(cb)),
  getPublicDivisionLeaderboard: (id, query, cb) => getLeaderboard(`divisions/${id}/leaderboard/?${queryString(query)}`).then(handlePublic(cb)),
  getPublicDivisionGroupLeaderboard: (id, query, cb) => getLeaderboard(`division-groups/${id}/leaderboard/?${queryString(query)}`).then(handlePublic(cb)),
  getPublicCompetitionWorkouts: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/workouts/`).then(handlePublic(cb)),
  getPublicCompetitionScoringPolicies: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/scoring-policies/`).then(handlePublic(cb)),
  getPublicCompetitionFields: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/registration-fields/`).then(handlePublic(cb)),
  getPublicCompetitionSaleItems: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/merchandise/`).then(handlePublic(cb)),

  getPublicCompetitionDays: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/days/`).then(handlePublic(cb)),
  getPublicCompetitionStages: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/stages/`).then(handlePublic(cb)),
  getPublicCompetitionEventConfigs: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/event-configs/`).then(handlePublic(cb)),
  getPublicCompetitionTimeBlocks: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/time-blocks/`).then(handlePublic(cb)),
  getPublicCompetitionAthleteAssignments: (id, eventConfigId, email, cb) => PUBLIC_HTTP.get(`competitions/${id}/athlete-assignments/?event=${eventConfigId || ''}&email=${email || ''}`).then(handlePublic(cb)),
  getPublicCompetitionJudges: (id, eventConfigId, cb) => PUBLIC_HTTP.get(`competitions/${id}/judges/?event=${eventConfigId ? eventConfigId : null}`).then(handlePublic(cb)),

  getPublicCompetitionAdCampaigns: (id, cb) => PUBLIC_HTTP.get(`competitions/${id}/ad-campaigns/`).then(handlePublic(cb)),
  getPublicDivision: (id, cb) => PUBLIC_HTTP.get(`divisions/${id}/`).then(handlePublic(cb)),
  getPublicDivisionGroup: (id, cb) => PUBLIC_HTTP.get(`division-groups/${id}/`).then(handlePublic(cb)),

  getPublicRegistrationTeamMembers: (id, cb) => PUBLIC_HTTP.get(`registrations/${id}/team-members/`).then(handlePublic(cb)),

  downloadExport: (url, cb) => DOWNLOADS.get(url).then(r => cb(r)),

  logout: () => HTTP.post('logout/'),
  getTimezone: (lat, lng, cb) => HTTP.get(`timezones/${lat}/${lng}/`).then(response => cb(response.data)),
  getStaffMetrics: (search, cb) => HTTP.get(`staff-metrics/?${queryString({q: search})}`).then(r => cb(r.data)),
  getCompetitionCalendar: (cb) => HTTP.get('staff/competition-calendar/').then(r => cb(r.data)),
  getCompetitionMetrics: (id, cb) => HTTP.get(`competitions/${id}/metrics/`).then(r => cb(r.data)),
  getCompetitionFees: (id, cb) => HTTP.get(`competitions/${id}/staff-fees-config/`).then(r => cb(r.data)),
  updateCompetitionFees: (id, platformFeePercentage, platformFeeFlat, currency, cb) => HTTP.post(`competitions/${id}/staff-fees-config/`, {
    platformFeePercentage: platformFeePercentage || 0, platformFeeFlat: platformFeeFlat || 0, currency
  }).then(r => cb(r.data)),
  createCompetition: (title, bannerImage, virtual, venueName, venueAddress, venuePlace, timezone, startDate, endDate, cb) => {
    const data = new FormData();
    data.append('title', title);
    data.append('virtual', virtual);
    data.append('venueName', venueName);
    data.append('venueAddress', venueAddress);
    data.append('venuePlace', venuePlace);
    data.append('timezone', timezone);
    data.append('startDate', startDate);
    data.append('endDate', endDate);
    if (bannerImage) {
      data.append('bannerImage', bannerImage.file, bannerImage.name);
      data.append('crop', queryString(bannerImage.meta));
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post('competitions/', data, { headers }).then(r => cb(r.data));
  },
  getThemes: (cb) => HTTP.get('themes/').then(r => cb(r.data)),
  createAddress: (country, place, rawPlace, cb) => HTTP.post('addresses/', { country, place, rawPlace }).then(r => cb(r.data)),
  getAddress: (id, cb) => HTTP.get(`addresses/${id}/`).then(r => cb(r.data)),
  clearAddressValidations: (id, cb) => HTTP.post(`addresses/${id}/`, {}).then(r => cb(r.data)),
  updateAddress: (id, country, place, rawPlace, cb) => HTTP.post(`addresses/${id}/`, { country, place, rawPlace }).then(r => cb(r.data)),
  selectAddress: (id, validatedIndex, cb) => HTTP.post(`addresses/${id}/`, { validatedIndex }).then(r => cb(r.data)),
  getTeamMember: (id, cb) => HTTP.get(`team-members/${id}/`).then(r => cb(r.data)),
  teamMemberConsent: (id, cb) => HTTP.post(`team-members/${id}/`).then(r => cb(r.data)),
  teamMemberDelete: (id, cb) => HTTP.post(`team-members/${id}/delete/`).then(r => cb(r.data)),
  paymentIntent: (id, items, coupon, cb) => HTTP.post(`competitions/${id}/payment-intents/`, { items, coupon }).then(r => cb(r.data)),
  updateCompetition: (id, title, competitionEmail, competitionEmailSenderName, brandUrl, logoImage, bannerImage, mobileBannerImage, virtual, shortlinkId, plan, cb) => {
    const data = new FormData();
    data.append('title', title);
    data.append('competitionEmail', competitionEmail);
    data.append('competitionEmailSenderName', competitionEmailSenderName);
    data.append('virtual', virtual);
    data.append('plan', plan);
    data.append('brandUrl', brandUrl);
    if (shortlinkId) {
      data.append('shortlinkId', shortlinkId);
    }
    if (bannerImage) {
      data.append('bannerImage', bannerImage.file, bannerImage.name);
    }
    if (mobileBannerImage) {
      data.append('mobileBannerImage', mobileBannerImage.file, mobileBannerImage.name);
    }
    if (logoImage) {
      data.append('logoImage', logoImage.file, logoImage.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post(`competitions/${id}/update/`, data, { headers }).then(r => cb(r.data));
  },
  setProgrammingWizardComplete: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { programmingWizardComplete: true }).then(r => cb(r.data));
  },
  enableHeatScoring: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { heatScoringEnabled: true }).then(r => cb(r.data));
  },
  disableHeatScoring: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { heatScoringEnabled: false }).then(r => cb(r.data));
  },
  enableAutoPublish: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { enableAutoPublish: true }).then(r => cb(r.data));
  },
  disableAutoPublish: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { disableAutoPublish: true }).then(r => cb(r.data));
  },
  setRegistrationWizardComplete: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { registrationWizardComplete: true }).then(r => cb(r.data));
  },
  setPublishRequestModalViewed: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { publishRequestModalViewed: true }).then(r => cb(r.data));
  },
  turnOffAthleteUpdateRegistration: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { allowRegistrationUpdates: false }).then(r => cb(r.data));
  },
  turnOnAthleteUpdateRegistration: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { allowRegistrationUpdates: true }).then(r => cb(r.data));
  },
  turnOffLeaderboardVideoLinks: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { showVideoOnLeaderboard: false }).then(r => cb(r.data));
  },
  turnOnLeaderboardVideoLinks: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { showVideoOnLeaderboard: true }).then(r => cb(r.data));
  },
  turnOffCustomeRegistrationName: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { restrictCustomLeaderboardNames: false }).then(r => cb(r.data));
  },
  turnOnCustomeRegistrationName: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { restrictCustomLeaderboardNames: true }).then(r => cb(r.data));
  },
  turnOffRegistrationInviteRequired: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { registrationRequiresInvites: false }).then(r => cb(r.data));
  },
  turnOnRegistrationInviteRequired: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { registrationRequiresInvites: true }).then(r => cb(r.data));
  },
  setLeaderboardDisplayPreferences: (id, preferences, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { ...preferences }).then(r => cb(r.data));
  },
  getCompetitionIds: cb => HTTP.get('competitions/?m=brief').then(r => cb(r.data)),
  getAllCompetitions: cb => HTTP.get('competitions/?m=all').then(r => cb(r.data)),

  searchCompetitions: (query, lat, lng, cb) => HTTP.get(`competitions-search/?${queryString({q: query, lat, lng})}`).then(r => cb(r.data)),
  suggestCompetitions: (lat, lng, cb) => HTTP.get(`competitions-suggestions/?${queryString({ lat, lng })}`).then(r => cb(r.data)),

  getCompetition: (id, cb) => HTTP.get(`competitions/${id}/`).then(r => cb(r.data)),
  getFullCompetition: (id, cb) => HTTP.get(`competitions/${id}?full=1`).then(r => cb(r.data)),
  getCompetitionTrifecta: (id, cb) => HTTP.get(`competitions/${id}?trifecta=1`).then(r => cb(r.data)),

  getDivision: (id, cb) => HTTP.get(`divisions/${id}/`).then(r => cb(r.data)),
  getDivisionRegistrations: (id, tableOnly, search, seeding, page, cb) => HTTP.get(`divisions/${id}/registrations/?t=${tableOnly ? '1' : '0'}&seeding=${seeding ? '1' : '0'}&p=${page || '1'}&q=${search || ''}`).then(r => cb(r.data)),
  findAthletes: (id, scoringLinkId, query, cb) => HTTP.get(`divisions/${id}/find-athletes/?${queryString({ sl: scoringLinkId, query })}`).then(r => cb(r.data)),
  getLeaderboardRegistrations: (id, cb) => HTTP.get(`divisions/${id}/leaderboard-registrations/`).then(r => cb(r.data)),
  getDays: (id, cb) => HTTP.get(`competitions/${id}/days/`).then(r => cb(r.data)),
  getStages: (id, cb) => HTTP.get(`competitions/${id}/workout-locations/`).then(r => cb(r.data)),
  getWorkout: (id, cb) => HTTP.get(`workouts/${id}/`).then(r => cb(r.data)),
  getScoringPolicy: (id, cb) => HTTP.get(`scoring-policies/${id}/`).then(r => cb(r.data)),
  getCoupon: (id, cb) => HTTP.get(`coupons/${id}/`).then(r => cb(r.data)),
  getRegistration: (id, cb) => HTTP.get(`registrations/${id}/`).then(r => cb(r.data)),
  getScoringLink: (id, cb) => HTTP.get(`scoring-links/${id}/`).then(r => cb(r.data)),
  getGuestScoringLinks: cb => HTTP.get('guest-scoring-links/').then(r => cb(r.data)),

  deactivateScoringLink: (id, cb) => HTTP.post(`scoring-links/${id}/deactivate/`).then(r => cb(r.data)),
  activateScoringLink: (id, cb) => HTTP.post(`scoring-links/${id}/activate/`).then(r => cb(r.data)),

  createTicketLink: (id, cb) => HTTP.post(`competitions/${id}/ticket-links/`).then(r => cb(r.data)),
  getTicketLink: (id, cb) => HTTP.get(`ticket-links/${id}/`).then(r => cb(r.data)),
  getGuestTicketLinks: cb => HTTP.get('guest-ticket-links/').then(r => cb(r.data)),

  deactivateTicketLink: (id, cb) => HTTP.post(`ticket-links/${id}/deactivate/`).then(r => cb(r.data)),
  activateTicketLink: (id, cb) => HTTP.post(`ticket-links/${id}/activate/`).then(r => cb(r.data)),

  changeRegistrationDivision: (id, divisionId, cb) => HTTP.post(`registrations/${id}/update/`, {
    'division': divisionId
  }).then(r => cb(r.data)),
  updateRegistration: (id, data, cb) => HTTP.post(`registrations/${id}/update/`, data).then(r => cb(r.data)),
  deleteRegistration: (id, cb) => HTTP.post(`registrations/${id}/delete/`).then(r => cb(r.data)),
  undeleteRegistration: (id, cb) => HTTP.post(`registrations/${id}/un-delete/`).then(r => cb(r.data)),
  changeTeamMembers: (id, teamMembers, cb) => HTTP.post(`registrations/${id}/update/`, {
    'teamMembers': teamMembers
  }).then(r => cb(r.data)),

  getStripeAccounts: cb => HTTP.get('stripe-accounts/').then(r => cb(r.data)),

  includeScoringPolicy: (workoutId, divisionId, cb) => {
    return HTTP.post(`scoring-policies/${workoutId}/${divisionId}/create/`).then(r => cb(r.data));
  },
  excludeScoringPolicy: (workoutId, divisionId, cb) => {
    return HTTP.post(`scoring-policies/${workoutId}/${divisionId}/delete/`).then(r => cb(r.data));
  },
  updateScoringPolicy: (workoutId, divisionId, scoringPolicy, tiePolicy, pointsTable, customPoints, totalRepsInWorkout, timeCap, mwrThreshold, applyAll, cb) => {
    return HTTP.post(`scoring-policies/${workoutId}/${divisionId}/update/`, {
      scoringPolicy,
      tiePolicy,
      pointsTable,
      customPoints,
      totalRepsInWorkout,
      timeCap,
      mwrThreshold,
      applyAll,
    }).then(r => cb(r.data));
  },
  duplicateCompetition: (id, cb) => {
    return HTTP.post(`competitions/${id}/duplicate/`).then(r => cb(r.data));
  },
  getCompetitionHashtagSuggestions: (id, cb) => {
    return PUBLIC_HTTP.get(`competitions/${id}/hashtag-suggestions/`).then(r => cb ? cb(r.data) : r.data);
  },
  updateCompetitionFields: (id, fields, cb) => {
    return HTTP.post(`competitions/${id}/update/`, fields).then(r => cb(r.data));
  },
  publishCompetition: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, {publish: true}).then(r => cb(r.data));
  },
  unPublishCompetition: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, {publish: false}).then(r => cb(r.data));
  },
  enableDevKit: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { devKit: true }).then(r => cb(r.data));
  },
  disableDevKit: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { devKit: false }).then(r => cb(r.data));
  },
  enableTest: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { test: true }).then(r => cb(r.data));
  },
  disableTest: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { test: false }).then(r => cb(r.data));
  },
  enableRefunds: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { refunds: true }).then(r => cb(r.data));
  },
  disableRefunds: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { refunds: false }).then(r => cb(r.data));
  },
  enableScheduling: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { scheduling: true }).then(r => cb(r.data));
  },
  disableScheduling: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { scheduling: false }).then(r => cb(r.data));
  },
  enableSponsorship: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { sponsorship: true }).then(r => cb(r.data));
  },
  disableSponsorship: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, { sponsorship: false }).then(r => cb(r.data));
  },
  requestPublishCompetition: (id, cb) => {
    return HTTP.post(`competitions/${id}/update/`, {requestPublish: true}).then(r => cb(r.data));
  },
  createCoupon: (id, code, validFor, maxRedemptions, amountOff, percentOff, expires, cb) => {
    return HTTP.post(`competitions/${id}/coupons/`, {
      code, validFor, maxRedemptions, amountOff, percentOff, expires
    }).then(r => cb(r.data));
  },
  saveCoupon: (id, code, validFor, maxRedemptions, amountOff, percentOff, expires, cb) => {
    return HTTP.post(`coupons/${id}/update/`, {
      code, validFor, maxRedemptions, amountOff, percentOff, expires
    }).then(r => cb(r.data));
  },
  deleteCoupon: (id, cb) => HTTP.post(`/coupons/${id}/delete/`).then(r => cb(r.data)),

  createDay: (id, title, content, date, cb) => {
    return HTTP.post(`competitions/${id}/days/`, {
      title,
      content,
      date,
    }).then(r => cb(r.data));
  },
  saveDay: (id, title, content, date, cb) => {
    return HTTP.post(`days/${id}/update/`, {
      title,
      content,
      date,
    }).then(r => cb(r.data));
  },
  publishDay: (id, cb) => HTTP.post(`days/${id}/publish/`).then(r => cb(r.data)),
  unpublishDay: (id, cb) => HTTP.post(`days/${id}/unpublish/`).then(r => cb(r.data)),
  deleteDay: (id, cb) => HTTP.post(`days/${id}/delete/`).then(r => cb(r.data)),

  createStage: (id, title, content, cb) => {
    return HTTP.post(`competitions/${id}/workout-locations/`, {
      title,
      content,
    }).then(r => cb(r.data));
  },
  saveStage: (id, title, content, cb) => {
    return HTTP.post(`workout-locations/${id}/update/`, {
      title,
      content,
    }).then(r => cb(r.data));
  },
  deleteStage: (id, cb) => HTTP.post(`workout-locations/${id}/delete/`).then(r => cb(r.data)),
  createWorkout: (id, title, content, releaseTime, cb) => {
    return HTTP.post(`competitions/${id}/workouts/`, {
      title,
      content,
      releaseTime
    }).then(r => cb(r.data));
  },
  saveWorkout: (id, title, content, releaseTime, cb) => {
    return HTTP.post(`workouts/${id}/update/`, {
      title,
      content,
      releaseTime
    }).then(r => cb(r.data));
  },
  saveWorkoutHeatParams: (id, duration, transitionTime, numberOfLanes, laneAssignmentStrategy, cb) => {
    return HTTP.post(`workouts/${id}/update-heat-params/`, {
      duration,
      transitionTime,
      numberOfLanes,
      laneAssignmentStrategy,
    }).then(r => cb(r.data));
  },
  saveCustomLabels: (id, labels, cb) => HTTP.post(`competitions/${id}/custom-labels/`, {...labels}).then(r => cb(r.data)),
  deleteWorkout: (id, cb) => HTTP.post(`workouts/${id}/delete/`).then(r => cb(r.data)),
  getSaleItems: (id, cb) => HTTP.get(`competitions/${id}/merchandise/`).then(r => cb(r.data)),
  getPurchases: (id, cb) => HTTP.get(`merchandise/${id}/purchases/`).then(r => cb(r.data)),
  getTicketPurchases: (id, ticketLinkId, cb) => HTTP.get(`competitions/${id}/ticket-purchases/`, { params: { ticketLinkId } }).then(r => cb(r.data)),
  createSaleItem: (id, title, description, price, initialQuantity, exchangeable, image, is_ticket, cb) => {
    const data = new FormData();
    data.append('title', title);
    data.append('description', description);
    data.append('price', price);
    data.append('initialQuantity', initialQuantity);
    data.append('exchangeable', exchangeable);
    data.append('ticket', is_ticket === true);
    if (image) {
      data.append('image', image.file, image.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post(`competitions/${id}/merchandise/`, data, { headers }).then(r => cb(r.data));
  },
  saveSaleItem: (id, title, description, price, initialQuantity, exchangeable, image, cb) => {
    const data = new FormData();
    data.append('title', title);
    data.append('description', description);
    data.append('price', price);
    data.append('initialQuantity', initialQuantity);
    data.append('exchangeable', exchangeable);
    if (image) {
      data.append('image', image.file, image.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post(`merchandise/${id}/update/`, data, { headers }).then(r => cb(r.data));
  },
  deleteSaleItem: (id, cb) => HTTP.post(`merchandise/${id}/delete/`).then(r => cb(r.data)),

  setDefaultScoringConfig: (id, workouts, virtual, cb) => {
    return HTTP.post(`competitions/${id}/set-scoring-config/`, {
      workouts,
      virtual
    }).then(r => cb(r.data));
  },
  saveWorkoutOrder: (id, itemOrder, cb) => {
    return HTTP.post(`competitions/${id}/workouts/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },
  saveSegmentOrder: (id, itemOrder, cb) => {
    return HTTP.post(`competitions/${id}/segments/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },
  saveInviteeOrder: (id, itemOrder, cb) => {
    return HTTP.post(`invitations/${id}/invitees/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },
  createDivision: (id, title, content, maxRegistrations, teamSize, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy, cb) => {
    return HTTP.post(`competitions/${id}/divisions/`, {
      title,
      content,
      maxRegistrations,
      teamSize,
      category,
      leaderboardScoringPolicy,
      leaderboardTiebreakerPolicy
    }).then(r => cb(r.data));
  },
  saveDivision: (id, title, content, teamSize, maxRegistrations, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy, cb) => {
    return HTTP.post(`divisions/${id}/update/`, {
      title,
      content,
      teamSize,
      maxRegistrations,
      category,
      leaderboardScoringPolicy,
      leaderboardTiebreakerPolicy
    }).then(r => cb(r.data));
  },
  saveMaxRegistration: (id, maxRegistrations, cb) => {
    return HTTP.post(`divisions/${id}/update/`, {
      maxRegistrations,
    }).then(r => cb(r.data));
  },
  deleteDivision: (id, cb) => HTTP.post(`divisions/${id}/delete/`).then(r => cb(r.data)),
  saveDivisionOrder: (id, itemOrder, cb) => {
    return HTTP.post(`competitions/${id}/divisions/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },

  getDivisionGroups: (id, cb) => {
    return HTTP.get(`competitions/${id}/division-groups/`).then(r => cb(r.data));
  },
  createDivisionGroup: (id, title, divisions, cb) => {
    return HTTP.post(`competitions/${id}/division-groups/`, {
      title,
      divisions
    }).then(r => cb(r.data));
  },
  saveDivisionGroup: (id, title, divisions, cb) => {
    return HTTP.post(`division-groups/${id}/update/`, {
      title,
      divisions
    }).then(r => cb(r.data));
  },
  deleteDivisionGroup: (id, cb) => HTTP.post(`division-groups/${id}/delete/`).then(r => cb(r.data)),
  saveDivisionGroupOrder: (id, itemOrder, cb) => {
    return HTTP.post(`competitions/${id}/division-groups/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },

  saveLeaderboardScoringPolicy: (id, leaderboardScoringPolicy, cb) => {
    return HTTP.post(`divisions/${id}/update/`, {
      leaderboardScoringPolicy
    }).then(r => cb(r.data));
  },
  getDivisionScoreMatrix: (id, scoringLinkId, cb) => {
    if (getDivisionScoreMatrixCancelToken) {
      getDivisionScoreMatrixCancelToken.cancel();
    }
    getDivisionScoreMatrixCancelToken = axios.CancelToken.source();
    return HTTP.get(`divisions/${id}/scores/?${queryString({ sl: scoringLinkId })}`, {
      cancelToken: getDivisionScoreMatrixCancelToken.token
    })
    .then(r => cb(r.data))
    .catch(thrown => {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    });
  },
  getScoreSet: (id, cb) => {
    return HTTP.get(`score-sets/${id}/`).then(r => cb(r.data));
  },
  saveScoringMatrixRow: (id, scoreSet, registration, athlete, scoringLinkId, bib, cb) => {
    return HTTP.post(`divisions/${id}/scores/`, {
      scoreSet,
      athlete,
      registration,
      scoringLinkId,
      bib
    }).then(r => cb(r.data));
  },
  saveScore: (id, scoreSet, registration, athlete, score, scoringLinkId, evidence, cb) => {
    return HTTP.post(`divisions/${id}/scores/`, {
      scoreSet,
      registration,
      athlete,
      score,
      scoringLinkId,
      evidence
    }).then(r => cb(r.data));
  },

  checkAthlete: (athlete, scoresetId, divisionId, cb) => {
    const url = scoresetId ? `/score-sets/${scoresetId}/check-athlete/?athlete=${athlete}` : `/divisions/${divisionId}/check-athlete/?athlete=${athlete}`;
    return HTTP.get(url).then(r => cb(r.data));
  },
  checkBib: (bib, id, cb) => {
    return HTTP.get(`/score-sets/${id}/bib/${bib}/`, {
      id, bib,
    }).then(r => cb(r.data));
  },
  getScoreDetails: (id, cb) => HTTP.get(`scores/${id}/`).then(r => cb(r.data)),
  verifyScore: (score, rejectReason, verified, scoringLinkId, cb) => {
    return HTTP.post(`scores/${score.score}/verify/`, {
      ...score,
      rejectReason,
      verified,
      scoringLinkId
    }).then(r => cb(r.data));
  },
  getAthleteScores: (id, workoutId, cb) => {
    return HTTP.get(`registrations/${id}/scores/?w=${workoutId}`).then(r => cb(r.data));
  },
  saveAthleteScore: (id, teamMember, scoreData, cb) => {
    return HTTP.post(`registrations/${id}/scores/`, { teamMember, scoreData }).then(r => cb(r.data));
  },
  withdrawAthleteScore: (id, scoreId, cb) => {
    const url = `registrations/${id}/scores/${scoreId}/withdraw/`;
    return HTTP.post(url).then(r => cb(r.data));
  },

  fetchLeaderboard: (divisionId, scoringLinkId, page, sort, direction, cb) => {
    return HTTP.get(`divisions/${divisionId}/leaderboard/?${queryString({ sl: scoringLinkId, p: page, s: sort, d: direction })}`).then(r => cb(r.data));
  },
  forceRefreshLeaderboard: (divisionId, scoringLinkId, cb) => {
    return HTTP.post(`divisions/${divisionId}/leaderboard-force-refresh/`, { scoringLinkId }).then(r => cb(r.data));
  },
  // Deprecated: Remove along with backend and clean up.  Force refresh is the standard now, including no queuing.
  refreshLeaderboard: (divisionId, scoringLinkId, cb) => {
    return HTTP.post(`divisions/${divisionId}/leaderboard-refresh/`, { scoringLinkId }).then(r => cb(r.data));
  },
  publishLeaderboard: (divisionId, scoringLinkId, cb) => {
    return HTTP.post(`divisions/${divisionId}/leaderboard-publish/`, { scoringLinkId }).then(r => cb(r.data));
  },

  getCompetitionFields: (id, cb) => HTTP.get(`competitions/${id}/registration-fields/`).then(r => cb(r.data)),
  createField: (id, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices, cb) => {
    return HTTP.post(`competitions/${id}/registration-fields/`, {
      label,
      helpText,
      fieldType,
      required,
      team,
      canFilterLeaderboard,
      divisions,
      choices
    }).then(r => cb(r.data));
  },
  saveField: (id, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices, cb) => {
    return HTTP.post(`registration-fields/${id}/update/`, {
      label,
      helpText,
      fieldType,
      required,
      team,
      canFilterLeaderboard,
      divisions,
      choices
    }).then(r => cb(r.data));
  },
  deleteField: (id, cb) => {
    return HTTP.post(`registration-fields/${id}/delete/`).then(r => cb(r.data));
  },
  saveFieldOrder: (id, itemOrder, cb) => {
    return HTTP.post(`competitions/${id}/registration-fields/set-order/`, {
      itemOrder
    }).then(r => cb(r.data));
  },
  updateRegistrationFees: (id, fields, cb) => {
    return HTTP.post(`competitions/${id}/registration-fees/`, fields).then(r => cb(r.data));
  },
  uploadCompetitionImage: (id, file) => {
    const formData = new FormData();
    formData.append('image', file);
    return HTTP.post(`competitions/${id}/upload-image/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  athleteUploadRequest: (id, fileSize, cb) => {
    return HTTP.get(`competitions/${id}/athlete-uploads/?file_size_bytes=${fileSize}`).then(r => cb(r.data));
  },
  athleteUploadComplete: (id, uploadId, key, parts, cb) => {
    return HTTP.post(`competitions/${id}/athlete-uploads/`, { uploadId, key, parts }).then(r => cb(r.data));
  },
  getAccount: cb => HTTP.get('account/').then(r => cb(r.data)),
  recordPublicProfileHit: (id, cb) => {
    const { referrer, location } = document;
    const data = new FormData();
    data.append('path', location.pathname);
    data.append('referrer', referrer);
    const headers = { 'Content-Type': 'multipart/form-data' };

    return HTTP.post(`athletes/${id}/hits/`, data, { headers }).then(r => cb(r.data));
  },

  saveAccount: (fields, cb) => HTTP.post('account/', fields).then(r => cb(r.data)),
  saveProfile: (fields, cb) => {
    const data = new FormData();
    Object.keys(fields).filter(f => f !== 'avatar').forEach(fieldName =>
      data.append(fieldName, fields[fieldName])
    );

    if (fields.avatar && fields.avatar.file && fields.avatar.name) {
      data.append('avatar', fields.avatar.file, fields.avatar.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post('profile/', data, { headers }).then(r => cb(r.data));
  },
  registerForCompetition: (id, paymentIntent, registrationData, hashtags, inviteCode, cb) => HTTP.post(`competitions/${id}/register/`, {
    paymentIntent,
    name,
    registrationData,
    hashtags,
    inviteCode
  }).then(r => cb(r.data)),
  purchaseMerch: (id, paymentIntent, couponCode, cb) => HTTP.post(`registrations/${id}/purchase-merchandise/`, {
    paymentIntent,
    couponCode,
  }).then(r => cb(r.data)),
  purchaseTickets: (id, paymentIntent, name, email, cb) => HTTP.post(`competitions/${id}/purchase-tickets/`, {
    paymentIntent,
    name,
    email,
  }).then(r => cb(r.data)),
  athleteChangeDivision: (id, paymentIntent, division, cb) => HTTP.post(`registrations/${id}/change-division/`, {
    paymentIntent,
    division,
  }).then(r => cb(r.data)),
  updateRegistrationDetails: (id, leaderboardName, registrants, teamFields, hashtags, cb) => HTTP.post(`registrations/${id}/update-details/`, {
    leaderboardName,
    hashtags,
    registrants,
    teamFields
  }).then(r => cb(r.data)),

  validateCoupon: (code, divisionId, itemsToPurchase, includeRegistration, cb) => HTTP.post('coupons/validate/', { code, divisionId, itemsToPurchase, includeRegistration }).then(r => cb(r.data)),
  queueScoringExport: (id, cb) => HTTP.post(`competitions/${id}/queue-scoring-export/`).then(r => cb(r.data)),
  queueCompetitionsExport: (id, cb) => HTTP.post(`competitions/${id}/queue-registration-export/`).then(r => cb(r.data)),
  exportStatus: (id, cb) => HTTP.get(`competitions/${id}/registration-export-status/`).then(r => cb(r.data)),

  queueSpectatorsExport: (id, cb) => HTTP.post(`competitions/${id}/queue-spectators-export/`).then(r => cb(r.data)),
  spectatorsExportStatus: (id, cb) => HTTP.get(`competitions/${id}/spectators-export-status/`).then(r => cb(r.data)),

  getJudges: (id, day, stage, cb) => HTTP.get(`competitions/${id}/judges/?day=${day ? day : ''}&stage=${stage ? stage : ''}`, {}).then(r => cb(r.data)),
  assignJudge: (id, lane, registrationId, cb) => HTTP.post(`event-configs/${id}/lanes/${lane}/assign-judge/`, { registrationId }).then(r => cb(r.data)),
  removeJudge: (id, lane, cb) => HTTP.post(`event-configs/${id}/lanes/${lane}/remove-judge/`).then(r => cb(r.data)),
  getAthleteAssignments: (id, eventConfigId, division, workout, day, stage, label, cb) => HTTP.get(`competitions/${id}/athlete-assignments/?event=${eventConfigId ? eventConfigId : ''}&division=${division ? division : ''}&workout=${workout ? workout : ''}&day=${day ? day : ''}&stage=${stage ? stage : ''}&label=${label ? label : ''}`).then(r => cb(r.data)),
  getTimeBlocks: (id, day, stage, cb) => HTTP.get(`competitions/${id}/time-blocks/?day=${day ? day : ''}&stage=${stage ? stage : ''}`).then(r => cb(r.data)),
  createTimeBlock: (id, day, stage, title, start, end, cb) => HTTP.post(`competitions/${id}/time-blocks/`, { day, stage, title, start, end }).then(r => cb(r.data)),
  updateTimeBlock: (id, title, start, end, cb) => HTTP.post(`time-blocks/${id}/update/`, { title, start, end }).then(r => cb(r.data)),
  deleteTimeBlock: (id, cb) => HTTP.post(`time-blocks/${id}/delete/`).then(r => cb(r.data)),
  getTimeBlockForScoring: (id, cb) => HTTP.get(`time-blocks/${id}/for-scoring/`).then(r => cb(r.data)),
  createHeat: (id, day, stage, workout, title, start, end, cb) => HTTP.post(`competitions/${id}/time-blocks/`, { day, stage, workout, title, start, end }).then(r => cb(r.data)),
  generateHeats: (id, workout, division, cb) => HTTP.post(`competitions/${id}/heats/`, { workout, division }).then(r => cb(r.data)),
  generateEventHeats: (id, eventConfig, cb) => HTTP.post(`competitions/${id}/heats/`, { eventConfig }).then(r => cb(r.data)),

  saveHeatScore: (id, assignment, score, cb) => HTTP.post(`heats/${id}/scoring/`, { assignment, score }).then(r => cb(r.data)),

  getEventConfigs: (id, division, workout, day, stage, label, cb) => HTTP.get(`competitions/${id}/event-configs/?division=${division ? division : ''}&workout=${workout ? workout : ''}&day=${day ? day : ''}&stage=${stage ? stage : ''}&label=${label ? label : ''}`).then(r => cb(r.data)),
  createEventConfig: (id, day, division, workout, stage, start, mergeDivisions, fitToRegistrations, cb) => HTTP.post(`competitions/${id}/event-configs/`, { day, division, workout, stage, start, mergeDivisions, fitToRegistrations }).then(r => cb(r.data)),
  updateEventConfig: (id, day, start, stage, duration, transitionTime, numberOfLanes, laneAssignmentStrategy, cb) => HTTP.post(`event-configs/${id}/update/`, { day, start, stage, duration, transitionTime, numberOfLanes, laneAssignmentStrategy }).then(r => cb(r.data)),
  deleteEventConfig: (id, cb) => HTTP.post(`event-configs/${id}/delete/`).then(r => cb(r.data)),
  moveHeatsToEventConfig: (id, timeBlocks, cb) => HTTP.post(`event-configs/${id}/move-heats/`, { timeBlocks }).then(r => cb(r.data)),
  fitEventConfigToHeats: (id, cb) => HTTP.post(`event-configs/${id}/fit-to-heats/`).then(r => cb(r.data)),

  importAthletes: (id, athletes, scoringLinkId, cb) => HTTP.post(`divisions/${id}/import-athletes/`, { athletes, scoringLinkId }).then(r => cb(r.data)),

  acceptTerms: (cb) => HTTP.post('account/accept-terms/', {}).then(r => cb(r.data)),

  getInvitePreview: (id, message, cb) => HTTP.post(`competitions/${id}/invitation-preview/`, { message }).then(r => cb(r.data)),
  createInvitation: (id, label, message, expires, emailMustMatchToAccept, cb) => HTTP.post(`competitions/${id}/invitations/`, { label, message, expires, emailMustMatchToAccept }).then(r => cb(r.data)),
  saveInvitation: (id, label, message, expires, emailMustMatchToAccept, cb) => HTTP.post(`invitations/${id}/update/`, { label, message, expires, emailMustMatchToAccept }).then(r => cb(r.data)),
  addInvitees: (id, division, emails, registrations, cb) => HTTP.post(`invitations/${id}/add-invitees/`, { division, emails, registrations }).then(r => cb(r.data)),
  publishInvitation: (id, cb) => HTTP.post(`invitations/${id}/publish/`, {}).then(r => cb(r.data)),
  stopInvitation: (id, cb) => HTTP.post(`invitations/${id}/stop/`, {}).then(r => cb(r.data)),
  getInvitation: (id, withInvitees, cb) => {
    const url = withInvitees ? `invitations/${id}/?invitees=1` : `invitations/${id}/`;
    return HTTP.get(url).then(r => cb(r.data));
  },
  deleteInvitee: (code, cb) => HTTP.post(`invitees/${code}/delete/`).then(r => cb(r.data)),
  getInvitee: (code, cb) => HTTP.get(`invitees/${code}/`).then(r => cb(r.data)),

  findUsers: (id, query, cb) => HTTP.get(`competitions/${id}/find-users/?query=${query}`).then(r => cb(r.data)),
  getDelegates: (id, cb) => HTTP.get(`competitions/${id}/delegates/`).then(r => cb(r.data)),
  createDelegate: (id, user_pk, cb) => HTTP.post(`competitions/${id}/delegates/`, { user_pk }).then(r => cb(r.data)),
  deleteDelegate: (id, cb) => HTTP.post(`delegates/${id}/delete/`).then(r => cb(r.data)),

  createSponsor: (id, sponsor, cb) => {
    const data = new FormData();
    data.append('name', sponsor.name);
    data.append('link', sponsor.link);
    if (sponsor.logo) {
      data.append('logo', sponsor.logo.file, sponsor.logo.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };
    return HTTP.post(`competitions/${id}/sponsors/`, data, { headers }).then(r => cb(r.data));
  },
  getCompetitionSponsors: (id, cb) => HTTP.get(`competitions/${id}/sponsors/`).then(r => cb(r.data)),
  getCompetitionTransactions: (id, cb) => HTTP.get(`competitions/${id}/transactions/`).then(r => cb(r.data)),
  getRefundRequests: cb => HTTP.get('staff/refund-requests/').then(r => cb(r.data)),
  requestRefund: (id, amount, deleteObject, cb) => HTTP.post(`transactions/${id}/request-refund/`, { amount, deleteObject }).then(r => cb(r.data)),
  cancelRefund: (id, cb) => HTTP.post(`transactions/${id}/cancel-refund/`).then(r => cb(r.data)),
  reverseTransfer: (id, cb) => HTTP.post(`staff/refund-requests/${id}/reverse-transfer/`).then(r => cb(r.data)),
  acceptRefund: (id, cb) => HTTP.post(`staff/refund-requests/${id}/accept/`).then(r => cb(r.data)),
  rejectRefund: (id, cb) => HTTP.post(`staff/refund-requests/${id}/reject/`).then(r => cb(r.data)),
  saveSponsor: (id, sponsor, cb) => {
    const data = new FormData();
    data.append('name', sponsor.name);
    data.append('link', sponsor.link);
    if (sponsor.logo && sponsor.logo.file && sponsor.logo.name) {
      data.append('logo', sponsor.logo.file, sponsor.logo.name);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };

    return HTTP.post(`sponsors/${id}/update/`, data, { headers }).then(r => cb(r.data));
  },
  deleteSponsor: (id, cb) => HTTP.post(`sponsors/${id}/delete/`).then(r => cb(r.data)),

  createAdCarousel: (id, carousel, cb) => HTTP.post(`competitions/${id}/ad-carousels/`, { ...carousel, sponsors: carousel.sponsors.map(s => s.id) }).then(r => cb(r.data)),
  getCompetitionAdCarousels: (id, cb) => HTTP.get(`competitions/${id}/ad-carousels/`).then(r => cb(r.data)),
  saveAdCarousel: (id, carousel, cb) => HTTP.post(`ad-carousels/${id}/update/`, { ...carousel, sponsors: carousel.sponsors.map(s => s.id) }).then(r => cb(r.data)),
  deleteAdCarousel: (id, cb) => HTTP.post(`ad-carousels/${id}/delete/`).then(r => cb(r.data)),

  createAdCampaign: (id, adCampaign, cb) => HTTP.post(`competitions/${id}/ad-campaigns/`, adCampaign).then(r => cb(r.data)),
  getCompetitionAdCampaigns: (id, cb) => HTTP.get(`competitions/${id}/ad-campaigns/`).then(r => cb(r.data)),
  saveAdCampaign: (id, adCampaign, cb) => HTTP.post(`ad-campaigns/${id}/update/`, adCampaign).then(r => cb(r.data)),
  deleteAdCampaign: (id, cb) => HTTP.post(`ad-campaigns/${id}/delete/`).then(r => cb(r.data)),

  updateAssignment: (id, heat, lane, rank, remove, addBack, cb) => HTTP.post(`assignments/${id}/update/`, { heat, lane, rank, remove, addBack }).then(r => cb(r.data)),
  swapAssignments: (id, swapWith, cb) => HTTP.post(`assignments/${id}/swap/`, { swapWith }).then(r => cb(r.data)),
  reseedAssignments: (id, updateFromLeaderboard, cb) => HTTP.post(`event-configs/${id}/reseed/`, { updateFromLeaderboard }).then(r => cb(r.data)),

  getCompetitionSocialGraphicURL: (id) => `${API_BASE_URL}competitions/${id}/social-graphic/`,
  logCompetitionSocialGraphicDownload(id, context) {
    return HTTP.post(
      this.getCompetitionSocialGraphicURL(id),
      {context}
    );
  },
  startCompetitionTrial: (id, attribute, leadOnly) => HTTP.post(`competitions/${id}/trials/`, { attribute, leadOnly }).then(r => r.data),
  getStaffActivationLink: (email) => HTTP.post('staff/activation-link/', { email }).then(r => r.data)
};
