import api from '../api';
import {
  COMPETITION_FORM_DIRTY,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_FAIL,
  FETCH_TIMEZONE,
  FETCH_STRIPE_ACCOUNTS,
  FETCH_COMPETITIONS,
  FETCH_GUEST_SCORING_LINKS,
  FETCH_COMPETITION,
  FETCH_INITIAL_DATA,
  FETCH_LANDING_PAGE_INITIAL_DATA,
  FETCH_PUBLIC_PROFILE,
  FETCH_PUBLIC_COMPETITION_AD_CAMPAIGNS,
  FETCH_GUEST_TICKET_LINKS,
  FETCH_TICKET_LINK,
  RECORD_PUBLIC_PROFILE_HIT,
  CREATE_COMPETITION,
  CREATE_COUPON,
  CREATE_WORKOUT,
  CREATE_DIVISION,
  CREATE_DIVISION_GROUP,
  CREATE_FIELD,
  CREATE_SCORING_POLICY,
  CREATE_TICKET_LINK,
  SAVE_ACCOUNT,
  SAVE_COMPETITION_SETUP,
  SAVE_COMPETITION_CONTENT,
  SAVE_COMPETITION_HASHTAGS,
  SAVE_SCORING_POLICY,
  SAVE_LEADERBOARD_SCORING_POLICY,
  SAVE_TIME_AND_PLACE,
  SAVE_STRIPE_ACCOUNT,
  SAVE_REGISTRATION_DATES,
  SAVE_REGISTRATION_FEES,
  SAVE_COUPON,
  SAVE_WORKOUT,
  SAVE_WORKOUT_ORDER,
  SAVE_DIVISION,
  SAVE_DIVISION_ORDER,
  SAVE_DIVISION_GROUP,
  SAVE_DIVISION_GROUP_ORDER,
  SAVE_INVITEE_ORDER,
  SAVE_FIELD,
  SAVE_FIELD_ORDER,
  DELETE_COUPON,
  DELETE_WORKOUT,
  DELETE_DIVISION,
  DELETE_DIVISION_GROUP,
  DELETE_FIELD,
  LOGOUT,
  FETCH_COMPETITION_FIELDS,
  SET_LOADED,
  TOGGLE_NAV,
  CLOSE_NAV,
  DUPLICATE_COMPETITION,
  PUBLISH_COMPETITION,
  FETCH_DIVISION_GROUPS,
  UNPUBLISH_COMPETITION,
  STRIPE_CONNECT,
  FETCH_SCORE_SET,
  SAVE_SCORE,
  SAVE_SCORING_MATRIX_ROW,
  SWAP_ATHLETE_ASSIGNMENTS,
  SWAPPING_ATHLETE_ASSIGNMENTS,
  DELETE_TEAM_MEMBER,
  DELETE_SCORING_POLICY,
  SAVE_REGISTRATION_CONTENT,
  SAVE_REGISTRATION_WAIVER_CONTENT,
  SAVE_DISTRIBUTION_CONTENT,
  SAVE_LEADERBOARD_DISTRIBUTION_CONTENT,
  SAVE_ORGANIZATION_REGISTRATION_NOTIFICATIONS,
  SAVE_ATHLETE_SCORE,
  WITHDRAW_SCORE,
  FETCH_REGISTRATION,
  FETCH_DIVISION,
  FETCH_WORKOUT,
  FETCH_SCORING_POLICY,
  FETCH_COUPON,
  FETCH_PUBLIC_COMPETITION_FIELDS,
  CHANGE_REGISTRATION_DIVISION,
  UPDATE_REGISTRATION,
  CHANGE_TEAM_MEMBERS,
  FETCH_SCORING_LINK,
  ACTIVATE_SCORING_LINK,
  DEACTIVATE_SCORING_LINK,
  SET_DEFAULT_SCORING_CONFIG,
  SHOW_NEW_COMP_MODAL,
  HIDE_NEW_COMP_MODAL,
  SET_PROGRAMMING_WIZARD_COMPLETE,
  SET_REGISTRATION_WIZARD_COMPLETE,
  REQUEST_PUBLISH_COMPETITION,
  UPDATE_SCORE_VERIFICATION,
  FETCH_COMPETITION_FEES,
  UPDATE_COMPETITION_FEES,
  CLEAR_COMPETITION,
  SET_REGISTRATION_ERROR,
  CLEAR_REGISTRATION_ERROR,
  SET_TICKET_PURCHASE_ERROR,
  CLEAR_TICKET_PURCHASE_ERROR,
  FETCH_COMPETITION_TRIFECTA,
  SET_FETCHING_ACCOUNT,
  FETCH_PUBLIC_COMPETITION_SALE_ITEMS,
  CREATE_SALE_ITEM,
  SAVE_SALE_ITEM,
  DELETE_SALE_ITEM,
  NETWORK_ERROR,
  PAGE_NOT_FOUND_ERROR,
  ATHLETE_REGISTRATION_CREATE,
  ATHLETE_REGISTRATION_CHANGE_DIVISION,
  ATHLETE_REGISTRATION_BUY_MERCH,
  ATHLETE_REGISTRATION_UPDATE,
  // CREATE_HEAT_SCHEDULE,
  // FETCH_COMPETITION_SEGMENT_SCHEDULES,
  // FETCH_COMPETITION_HEATS,
  // FETCH_COMPETITION_LANES,
  FETCH_COMPETITION_ATHLETE_ASSIGNMENTS,
  FETCH_COMPETITION_JUDGES,
  // UPDATE_HEAT_SCHEDULE,
  // PUBLISH_HEAT_SCHEDULE,
  // PUBLISH_HEAT_ASSIGNMENTS,
  // UNPUBLISH_HEAT_ASSIGNMENTS,
  ENABLE_SCHEDULING,
  DISABLE_SCHEDULING,
  ENABLE_SPONSORSHIP,
  DISABLE_SPONSORSHIP,
  ENABLE_REFUNDS,
  DISABLE_REFUNDS,
  CREATE_STAGE,
  SAVE_STAGE,
  DELETE_STAGE,
  SAVE_WORKOUT_HEAT_PARAMS,
  FETCH_STAGES,
  ASSIGN_JUDGE,
  REMOVE_JUDGE,
  ENABLE_HEAT_SCORING,
  DISABLE_HEAT_SCORING,
  // CREATE_SEGMENT,
  // FETCH_SEGMENTS,
  // SAVE_SEGMENT,
  // DELETE_SEGMENT,
  FETCH_DIVISION_REGISTRATIONS,
  // UNPUBLISH_HEAT_SCHEDULE,
  IMPORT_ATHLETES,
  CLEAR_PUBLISH_REQUEST_MODAL,
  ACCEPT_TERMS,
  SET_REGISTRATION_CAP,
  TURN_ON_UPDATE_REGISTRATION,
  TURN_OFF_UPDATE_REGISTRATION,
  CREATE_INVITATION,
  FETCH_INVITATION,
  SAVE_INVITATION,
  ADD_INVITEES,
  TURN_ON_REGISTRATION_INVITE_REQUIRED,
  TURN_OFF_REGISTRATION_INVITE_REQUIRED,
  PURCHASE_COMPETITION_TICKETS,
  DELETE_INVITEE,
  PUBLISH_INVITATION,
  STOP_INVITATION,
  FETCH_PUBLIC_COMPETITION,
  FETCH_PUBLIC_COMPETITION_DIVISIONS,
  FETCH_PUBLIC_COMPETITION_WORKOUTS,
  FETCH_PUBLIC_COMPETITION_SCORING_POLICIES,
  FETCH_PUBLIC_COMPETITION_STAGES,
  FETCH_PUBLIC_COMPETITION_DAYS,
  FETCH_PUBLIC_COMPETITION_EVENT_CONFIGS,
  FETCH_PUBLIC_COMPETITION_TIME_BLOCKS,
  FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS,
  FETCH_PUBLIC_COMPETITION_JUDGES,
  FETCH_SALE_ITEMS,
  SAVE_PROFILE,
  SET_LEADERBOARD_DISPLAY_PREFERENCES,
  FETCH_SPONSORS,
  CREATE_SPONSOR,
  SAVE_SPONSOR,
  DELETE_SPONSOR,
  FETCH_AD_CAROUSELS,
  CREATE_AD_CAROUSEL,
  SAVE_AD_CAROUSEL,
  DELETE_AD_CAROUSEL,
  FETCH_AD_CAMPAIGNS,
  CREATE_AD_CAMPAIGN,
  SAVE_AD_CAMPAIGN,
  DELETE_AD_CAMPAIGN,
  SAVE_TICKETING_HEADER_CONTENT,
  SAVE_TICKETING_DATES,
  ENABLE_DEV_KIT,
  DISABLE_DEV_KIT,
  ENABLE_TEST,
  DISABLE_TEST,
  ACTIVATE_TICKET_LINK,
  DEACTIVATE_TICKET_LINK,
  // SAVE_HEAT,
  SAVE_SEGMENT_ORDER,
  UPDATE_ATHLETE_ASSIGNMENT,
  COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS,
  COMPETITION_SCHEDULE_UPDATE_FROM_LEADERBOARD,
  ENABLE_AUTO_PUBLISH,
  DISABLE_AUTO_PUBLISH,
  COMPETITION_EXPORT_QUEUE,

  SCORING_EXPORT_QUEUE,

  SPECTATORS_EXPORT_QUEUE,
  FETCH_SPECTATORS_EXPORT_STATUS,
  FETCH_REGISTRATION_EXPORT_STATUS,
  SET_NEW_VERSION_AVAILABLE,
  FETCH_PUBLIC_DIVISION,
  TURN_OFF_CUSTOM_REGISTRATION_NAMES,
  TURN_ON_CUSTOM_REGISTRATION_NAMES,
  TURN_OFF_LEADERBOARD_VIDEO_LINKS,
  TURN_ON_LEADERBOARD_VIDEO_LINKS,
  SAVE_THEME,
  START_TRIAL,
  FETCH_DAYS,
  CREATE_DAY,
  SAVE_DAY,
  PUBLISH_DAY,
  UNPUBLISH_DAY,
  DELETE_DAY,
  CREATE_EVENT_CONFIG,
  FETCH_EVENT_CONFIGS,
  SAVE_EVENT_CONFIG,
  DELETE_EVENT_CONFIG,
  FETCH_TIME_BLOCKS,
  GENERATE_HEATS,
  GENERATE_EVENT_HEATS,
  CREATE_TIME_BLOCK,
  UPDATE_TIME_BLOCK,
  DELETE_TIME_BLOCK,
  MOVE_HEATS_TO_EVENT_CONFIG,
  DELETE_REGISTRATION,
  FIT_EVENT_CONFIG_TO_HEATS,
  UNDELETE_REGISTRATION,
  SAVE_CUSTOM_LABELS
} from '../constants';

const logoutOnError = commit => error => {
  if (error.response && error.response.status === 401) {
    window.location = '/';
    commit(LOGOUT);
  } else {
    throw error;
  }
};

const handleNetworkError = commit => error => {
  if (error.request && error.request.status === 0 && error.response === undefined) {
    commit(NETWORK_ERROR);
  } else if (error.response && error.response.status === 418) {
    commit(SET_NEW_VERSION_AVAILABLE);
  } else {
    logoutOnError(commit)(error);
  }
};

const handleNetworkErrorOrRegistrationError = commit => error => {
  if (error.response && [200, 401, 418].indexOf(error.response.status) === -1) {
    commit(SET_REGISTRATION_ERROR, 'There was a connectivity error and your registration did not complete.  You have not been charged.  Please try again.');
  } else {
    handleNetworkError(commit)(error);
  }
};

const handlePageNotFound = commit => error => {
  if (error.response && error.response.status === 404) {
    commit(PAGE_NOT_FOUND_ERROR);
  }
  logoutOnError(commit)(error);
};

const handleTicketResponses = (commit, data) => {
  if (data.error) {
    commit(SET_TICKET_PURCHASE_ERROR, data.error);
    if (data.data.division) {
      commit(FETCH_DIVISION, data.data.division);
    }
    if (data.data.competition) {
      commit(FETCH_COMPETITION, data.data.competition);
    }
    if (data.data.registration) {
      commit(FETCH_REGISTRATION, data.data.registration);
    }
  } else {
    commit(FETCH_ACCOUNT, data.data.account);
    commit(FETCH_COMPETITION, data.data.competition);
    if (data.data.registration) {
      commit(FETCH_REGISTRATION, data.data.registration);
    }
    if (data.data.merch) {
      commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, data.data.merch);
    }
  }
};

const handleRegistrationResponses = (commit, data) => {
  if (data.error) {
    commit(SET_REGISTRATION_ERROR, data.error);
    if (data.data.division) {
      commit(FETCH_DIVISION, data.data.division);
    }
    if (data.data.competition) {
      commit(FETCH_COMPETITION, data.data.competition);
    }
    if (data.data.registration) {
      commit(FETCH_REGISTRATION, data.data.registration);
    }
  } else {
    commit(FETCH_ACCOUNT, data.data.account);
    commit(FETCH_COMPETITION, data.data.competition);
    if (data.data.registration) {
      commit(FETCH_REGISTRATION, data.data.registration);
    }
    if (data.data.merch) {
      commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, data.data.merch);
    }
  }
};

export default {
  [LOGOUT]: ({ commit }) => api.logout().then(() => commit(LOGOUT)).catch(handleNetworkError(commit)),
  [FETCH_STRIPE_ACCOUNTS]: ({ commit }) => api.getStripeAccounts(data => commit(FETCH_STRIPE_ACCOUNTS, data.data)).catch(handleNetworkError(commit)),

  [FETCH_COMPETITION_FEES]: ({ commit }, { id }) => api.getCompetitionFees(id, data => commit(FETCH_COMPETITION_FEES, { id, data: data.data })).catch(handleNetworkError(commit)),
  [UPDATE_COMPETITION_FEES]: ({ commit }, { id, platformFeePercentage, platformFeeFlat, currency }) => api.updateCompetitionFees(
    id, platformFeePercentage, platformFeeFlat, currency, data => commit(FETCH_COMPETITION_FEES, {id, data: data.data})
  ).catch(handleNetworkError(commit)),

  [FETCH_INITIAL_DATA]: ({ state, commit, dispatch }) => {
    dispatch(FETCH_ACCOUNT).then(() => {
      if (state.isAuthed) {
        Promise.all([
          dispatch(FETCH_STRIPE_ACCOUNTS),
          dispatch(FETCH_COMPETITIONS),
          dispatch(FETCH_GUEST_SCORING_LINKS),
          dispatch(FETCH_GUEST_TICKET_LINKS)
        ]).then(() => commit(SET_LOADED)).catch(logoutOnError(commit));
      }
    });
  },
  [FETCH_PUBLIC_COMPETITION]: ({ commit }, { id }) => api.getPublicCompetition(id, data => commit(FETCH_PUBLIC_COMPETITION, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_DIVISIONS]: ({ commit }, { id }) => api.getPublicCompetitionDivisions(id, data => commit(FETCH_PUBLIC_COMPETITION_DIVISIONS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_WORKOUTS]: ({ commit }, { id }) => api.getPublicCompetitionWorkouts(id, data => commit(FETCH_PUBLIC_COMPETITION_WORKOUTS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_SCORING_POLICIES]: ({ commit }, { id }) => api.getPublicCompetitionScoringPolicies(id, data => commit(FETCH_PUBLIC_COMPETITION_SCORING_POLICIES, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_DIVISION]: ({ commit }, { id }) => api.getPublicDivision(id, data => commit(FETCH_PUBLIC_DIVISION, data.data)).catch(handleNetworkError(commit)),

  [FETCH_PUBLIC_COMPETITION_FIELDS]: ({ commit }, { id }) => {
    return api.getPublicCompetitionFields(id, data => {
      commit(FETCH_PUBLIC_COMPETITION_FIELDS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_PUBLIC_COMPETITION_SALE_ITEMS]: ({ commit }, { id }) => api.getPublicCompetitionSaleItems(id, data => commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, data.data)),

  [FETCH_PUBLIC_COMPETITION_STAGES]: ({ commit }, { id }) => api.getPublicCompetitionStages(id, data => commit(FETCH_STAGES, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_DAYS]: ({ commit }, { id }) => api.getPublicCompetitionDays(id, data => commit(FETCH_DAYS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_EVENT_CONFIGS]: ({ commit }, { id }) => api.getPublicCompetitionEventConfigs(id, data => commit(FETCH_EVENT_CONFIGS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_TIME_BLOCKS]: ({ commit }, { id }) => api.getPublicCompetitionTimeBlocks(id, data => commit(FETCH_TIME_BLOCKS, { data: data.data })).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS]: ({ commit }, { id, eventConfigId, email }) => api.getPublicCompetitionAthleteAssignments(id, eventConfigId, email, data => commit(FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_PUBLIC_COMPETITION_JUDGES]: ({ commit }, { id, eventConfigId }) => api.getPublicCompetitionJudges(id, eventConfigId, data => commit(FETCH_PUBLIC_COMPETITION_JUDGES, data.data)).catch(handleNetworkError(commit)),

  [FETCH_LANDING_PAGE_INITIAL_DATA]: ({ commit, dispatch }, { competitionId }) => {
    return Promise.all([
      dispatch(FETCH_PUBLIC_COMPETITION, { id: competitionId }),
      dispatch(FETCH_PUBLIC_COMPETITION_DIVISIONS, { id: competitionId }),
      dispatch(FETCH_PUBLIC_COMPETITION_WORKOUTS, { id: competitionId }),
      dispatch(FETCH_PUBLIC_COMPETITION_SCORING_POLICIES, { id: competitionId }),
    ])
    .then(() => commit(SET_LOADED))
    .catch(handlePageNotFound(commit));
  },

  [FETCH_COMPETITION_FIELDS]: ({ commit }, { id }) => {
    return api.getCompetitionFields(id, data => {
      commit(FETCH_PUBLIC_COMPETITION_FIELDS, data.data);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_SALE_ITEMS]: ({ commit }, { id }) => api.getSaleItems(id, data => commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, data.data)).catch(handleNetworkError(commit)),
  [COMPETITION_FORM_DIRTY]: ({ commit }, { dirty }) => commit(COMPETITION_FORM_DIRTY, dirty),
  [FETCH_ACCOUNT]: ({ commit }) => {
    commit(SET_FETCHING_ACCOUNT, true);
    return api.getAccount(data => commit(FETCH_ACCOUNT, data.data))
      .then(() => commit(SET_FETCHING_ACCOUNT, false))
      .catch(e => {
        if (e.response && e.response.status === 401) {
          commit(FETCH_ACCOUNT_FAIL);
        } else {
          handleNetworkError(commit)(e);
        }
      });
  },
  [NETWORK_ERROR]: ({ commit }, { error }) => handleNetworkError(commit)(error),
  [FETCH_PUBLIC_PROFILE]: ({ commit }, { username }) => {
    return api.getPublicProfile(username, data => commit(FETCH_PUBLIC_PROFILE, data.data)
      ).catch(handleNetworkError(commit));
  },
  [RECORD_PUBLIC_PROFILE_HIT]: ({ commit}, { profileId }) => {
    return api.recordPublicProfileHit(profileId, () => {}).catch(handleNetworkError(commit));
  },
  [CREATE_SALE_ITEM]: ({ commit }, { id, title, description, price, initialQuantity, exchangeable, is_ticket, image }) => {
    return api.createSaleItem(id, title, description, price, initialQuantity, exchangeable, image, is_ticket, data => {
      commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_SALE_ITEM]: ({ commit }, { id, title, description, price, initialQuantity, exchangeable, image }) => {
    return api.saveSaleItem(id, title, description, price, initialQuantity, exchangeable, image, data => {
      commit(FETCH_PUBLIC_COMPETITION_SALE_ITEMS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_SALE_ITEM]: ({ commit }, { id }) => {
    return api.deleteSaleItem(id, data => {
      commit(DELETE_SALE_ITEM, {id, error: data.error});
    }).catch(handleNetworkError(commit));
  },

  [CLEAR_PUBLISH_REQUEST_MODAL]: ({ commit }, { id }) => api.setPublishRequestModalViewed(id, data => commit(FETCH_COMPETITION, data.data)).catch(handleNetworkError(commit)),

  [SAVE_ACCOUNT]: ({ commit }, fields) => api.saveAccount(fields, data => commit(FETCH_ACCOUNT, data.data)).catch(handleNetworkError(commit)),
  [SAVE_PROFILE]: ({ commit }, fields) => api.saveProfile(fields, data => commit(FETCH_ACCOUNT, data.data)).catch(handleNetworkError(commit)),
  [FETCH_COMPETITIONS]: ({ commit }) => api.getCompetitionIds(data => commit(FETCH_COMPETITIONS, data.data)).catch(handleNetworkError(commit)),
  [FETCH_COMPETITION]: ({ commit }, { id }) => api.getCompetition(id, data => {
    commit(FETCH_COMPETITION, data.data);
  }).catch(handleNetworkError(commit)),
  [FETCH_DIVISION_REGISTRATIONS]: ({ commit }, { id, tableOnly, search, seeding, page }) => {
    console.log({ search });
    return api.getDivisionRegistrations(id, tableOnly, search, seeding, page, data => {
      commit(FETCH_DIVISION_REGISTRATIONS, { data: data.data, division: id });
    }).catch(handleNetworkError(commit));
  },
  [FETCH_COMPETITION_TRIFECTA]: ({ commit }, { id }) => api.getCompetitionTrifecta(id, data => {
    commit(FETCH_COMPETITION_TRIFECTA, data.data);
  }).catch(handleNetworkError(commit)),
  [FETCH_DIVISION]: ({ commit }, { divisionId }) => {
    return api.getDivision(divisionId, data => {
      commit(FETCH_DIVISION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_WORKOUT]: ({ commit }, { workoutId }) => {
    return api.getWorkout(workoutId, data => {
      commit(FETCH_WORKOUT, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_SCORING_POLICY]: ({ commit }, { scoringPolicyId }) => {
    return api.getScoringPolicy(scoringPolicyId, data => {
      commit(FETCH_SCORING_POLICY, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_COUPON]: ({ commit }, { couponId }) => {
    return api.getCoupon(couponId, data => {
      commit(FETCH_COUPON, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_REGISTRATION]: ({ commit }, { registrationId }) => {
    return api.getRegistration(registrationId, data => {
      commit(FETCH_REGISTRATION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [CREATE_COUPON]: ({ commit }, { competitionId, code, validFor, maxRedemptions, amountOff, percentOff, expires }) => {
    return api.createCoupon(competitionId, code, validFor, maxRedemptions, amountOff, percentOff, expires, data => {
      commit(FETCH_COUPON, data.data);
      commit(CREATE_COUPON, data.data);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_COUPON]: ({ commit }, { id, code, validFor, maxRedemptions, amountOff, percentOff, expires }) => {
    return api.saveCoupon(id, code, validFor, maxRedemptions, amountOff, percentOff, expires, data => {
      commit(FETCH_COUPON, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_COUPON]: ({ commit }, { id }) => {
    return api.deleteCoupon(id, () => {
      commit(DELETE_COUPON, id);
    }).catch(handleNetworkError(commit));
  },

  [DELETE_TEAM_MEMBER]: ({ commit }, { id }) => {
    return api.teamMemberDelete(id, (data) => {
      commit(DELETE_TEAM_MEMBER, data.data);
    }).catch(handleNetworkError(commit));
  },

  [CREATE_WORKOUT]: ({ commit }, { competitionId, title, content, releaseTime }) => {
    return api.createWorkout(competitionId, title, content, releaseTime, data => {
      commit(FETCH_COMPETITION_TRIFECTA, data.data);
      commit(FETCH_COMPETITION, data.data.competition);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_WORKOUT]: ({ commit }, { itemId, title, content, releaseTime }) => {
    return api.saveWorkout(itemId, title, content, releaseTime, data => {
      commit(FETCH_WORKOUT, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_WORKOUT]: ({ commit }, { itemId }) => {
    return api.deleteWorkout(itemId, data => {
      commit(FETCH_COMPETITION_TRIFECTA, data.data);
      commit(FETCH_COMPETITION, data.data.competition);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_WORKOUT_HEAT_PARAMS]: ({ commit }, { id, duration, transitionTime, numberOfLanes, laneAssignmentStrategy }) => {
    return api.saveWorkoutHeatParams(id, duration, transitionTime, numberOfLanes, laneAssignmentStrategy, data => {
      commit(FETCH_WORKOUT, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_CUSTOM_LABELS]: ({ commit }, { id, labels }) => {
    return api.saveCustomLabels(id, labels, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [CREATE_DAY]: ({ commit }, { competitionId, title, content, date }) => {
    return api.createDay(competitionId, title, content, date, data => {
      commit(FETCH_DAYS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_DAY]: ({ commit }, { id, title, content, date }) => {
    return api.saveDay(id, title, content, date, data => {
      commit(FETCH_DAYS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [PUBLISH_DAY]: ({ commit }, { id }) => api.publishDay(id, data => commit(FETCH_DAYS, [data.data])),
  [UNPUBLISH_DAY]: ({ commit }, { id }) => api.unpublishDay(id, data => commit(FETCH_DAYS, [data.data])),
  [DELETE_DAY]: ({ commit }, { id }) => {
    return api.deleteDay(id, () => {
      commit(DELETE_DAY, id);
    }).catch(handleNetworkError(commit));
  },

  [CREATE_STAGE]: ({ commit }, { competitionId, title, content }) => {
    return api.createStage(competitionId, title, content, data => {
      commit(FETCH_STAGES, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_STAGE]: ({ commit }, { id, title, content }) => {
    return api.saveStage(id, title, content, data => {
      commit(FETCH_STAGES, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_STAGE]: ({ commit }, { id }) => {
    return api.deleteStage(id, () => {
      commit(DELETE_STAGE, id);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_DAYS]: ({ commit }, { id }) => {
    return api.getDays(id, data => {
      commit(FETCH_DAYS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_STAGES]: ({ commit }, { id }) => {
    return api.getStages(id, data => {
      commit(FETCH_STAGES, data.data);
    }).catch(handleNetworkError(commit));
  },

  [CREATE_DIVISION]: ({ commit, dispatch }, { competitionId, title, content, maxRegistrations, teamSize, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy }) => {
    return api.createDivision(competitionId, title, content, maxRegistrations, teamSize, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy, data => {
      dispatch(FETCH_COMPETITION_FIELDS, { id: competitionId });
      commit(FETCH_COMPETITION_TRIFECTA, data.data);
      commit(FETCH_COMPETITION, data.data.competition);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_DIVISION]: ({ commit }, { id, title, content, teamSize, maxRegistrations, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy }) => {
    return api.saveDivision(id, title, content, teamSize, maxRegistrations, category, leaderboardScoringPolicy, leaderboardTiebreakerPolicy, data => {
      commit(FETCH_DIVISION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_DIVISION]: ({ commit }, { id }) => {
    return api.deleteDivision(id, data => {
      commit(FETCH_COMPETITION_TRIFECTA, data.data);
      commit(FETCH_COMPETITION, data.data.competition);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_DIVISION_ORDER]: ({ commit }, { competitionId, itemOrder }) => {
    return api.saveDivisionOrder(competitionId, itemOrder, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_DIVISION_GROUPS]: ({ commit }, { competitionId }) => {
    return api.getDivisionGroups(competitionId, data => {
      commit(FETCH_DIVISION_GROUPS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [CREATE_DIVISION_GROUP]: ({ commit }, { competitionId, title, divisions }) => {
    return api.createDivisionGroup(competitionId, title, divisions, data => {
      commit(FETCH_DIVISION_GROUPS, [data.data.divisionGroup]);
      commit(FETCH_COMPETITION, data.data.competition);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_DIVISION_GROUP]: ({ commit }, { id, title, divisions }) => {
    return api.saveDivisionGroup(id, title, divisions, data => {
      commit(FETCH_DIVISION_GROUPS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_DIVISION_GROUP]: ({ commit }, { id }) => {
    return api.deleteDivisionGroup(id, () => {
      commit(DELETE_DIVISION_GROUP, id);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_DIVISION_GROUP_ORDER]: ({ commit }, { competitionId, itemOrder }) => {
    return api.saveDivisionGroupOrder(competitionId, itemOrder, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_LEADERBOARD_SCORING_POLICY]: ({ commit }, { divisionId, leaderboardScoringPolicy }) => {
    return api.saveLeaderboardScoringPolicy(divisionId, leaderboardScoringPolicy, data => {
      commit(FETCH_DIVISION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_SCORING_LINK]: ({ commit }, { id }) => api.getScoringLink(id, data => commit(FETCH_SCORING_LINK, data.data)).catch(handleNetworkError(commit)),
  [ACTIVATE_SCORING_LINK]: ({ commit }, { id }) => api.activateScoringLink(id, data => commit(FETCH_SCORING_LINK, data.data)).catch(handleNetworkError(commit)),
  [DEACTIVATE_SCORING_LINK]: ({ commit }, { id }) => api.deactivateScoringLink(id, data => commit(FETCH_SCORING_LINK, data.data)).catch(handleNetworkError(commit)),

  [SET_DEFAULT_SCORING_CONFIG]: ({ commit }, { id, workouts, virtual }) => {
    return api.setDefaultScoringConfig(id, workouts, virtual, data => {
      data.scoringPolicies.forEach(policy => commit(FETCH_SCORING_POLICY, policy));
      commit(FETCH_COMPETITION, data.competition);
    }).catch(handleNetworkError(commit));
  },

  [SHOW_NEW_COMP_MODAL]: ({ commit }) => commit(SHOW_NEW_COMP_MODAL),
  [HIDE_NEW_COMP_MODAL]: ({ commit }) => commit(HIDE_NEW_COMP_MODAL),

  [CREATE_SCORING_POLICY]: ({ commit }, { divisionId, workoutId }) => {
    return api.includeScoringPolicy(workoutId, divisionId, data => {
      data.data.policies.map(policy => commit(FETCH_SCORING_POLICY, policy));
      data.data.policies.map(policy => commit(CREATE_SCORING_POLICY, policy));
    }).catch(handleNetworkError(commit));
  },
  [SAVE_SCORING_POLICY]: ({ commit }, { workoutId, divisionId, scoringPolicy, tiePolicy, pointsTable, customPoints, totalRepsInWorkout, timeCap, mwrThreshold, applyAll }) => {
    return api.updateScoringPolicy(workoutId, divisionId, scoringPolicy, tiePolicy, pointsTable, customPoints, totalRepsInWorkout, timeCap, mwrThreshold, applyAll, data => {
      data.data.policies.map(policy => commit(FETCH_SCORING_POLICY, policy));
    }).catch(handleNetworkError(commit));
  },
  [DELETE_SCORING_POLICY]: ({ commit }, { workoutId, divisionId }) => api.excludeScoringPolicy(workoutId, divisionId, data => commit(DELETE_SCORING_POLICY, data.data.id)).catch(handleNetworkError(commit)),
  [CHANGE_REGISTRATION_DIVISION]: ({ commit }, { id, divisionId }) => api.changeRegistrationDivision(id, divisionId, data => {
    commit(FETCH_REGISTRATION, data.data);
    commit(CHANGE_REGISTRATION_DIVISION, data.data);
  }).catch(handleNetworkError(commit)),
  [UPDATE_REGISTRATION]: ({ commit }, { id, name, hashtags }) => api.updateRegistration(id, { name, hashtags }, data => {
    commit(FETCH_REGISTRATION, data.data);
  }).catch(handleNetworkError(commit)),
  [DELETE_REGISTRATION]: ({ commit }, { id }) => api.deleteRegistration(id, data => {
    commit(FETCH_REGISTRATION, data.data);
  }).catch(handleNetworkError(commit)),
  [UNDELETE_REGISTRATION]: ({ commit }, { id }) => api.undeleteRegistration(id, data => {
    commit(FETCH_REGISTRATION, data.data);
  }).catch(handleNetworkError(commit)),
  [CHANGE_TEAM_MEMBERS]: ({ commit }, { id, teamMembers }) => api.changeTeamMembers(id, teamMembers, data => {
    commit(FETCH_REGISTRATION, data.data);
  }).catch(handleNetworkError(commit)),

  [SAVE_COMPETITION_SETUP]: ({ commit }, { competitionId, title, competitionEmail, competitionEmailSenderName, brandUrl, logoImage, bannerImage, mobileBannerImage, virtual, shortlinkId, plan }) => {
    let returnedData = {};
    return api.updateCompetition(competitionId, title, competitionEmail, competitionEmailSenderName, brandUrl, logoImage, bannerImage, mobileBannerImage, virtual, shortlinkId, plan, data => {
      returnedData = { ...data.data };
      commit(FETCH_COMPETITION, data.data);
    }).then(() => {
      return { data: returnedData };
    }).catch(handleNetworkError(commit));
  },
  [SET_PROGRAMMING_WIZARD_COMPLETE]: ({ commit }, { competitionId }) => {
    return api.setProgrammingWizardComplete(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [SET_REGISTRATION_WIZARD_COMPLETE]: ({ commit }, { competitionId }) => {
    return api.setRegistrationWizardComplete(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [CREATE_COMPETITION]: ({ commit }, { title, bannerImage, virtual, venueName, venueAddress, venuePlace, timezone, startDate, endDate, redirect }) => {
    return api.createCompetition(title, bannerImage, virtual, venueName, venueAddress, venuePlace, timezone, startDate, endDate, data => {
      commit(FETCH_COMPETITION, data.data);
      redirect(data.data.id);
    }).catch(handleNetworkError(commit));
  },
  [DUPLICATE_COMPETITION]: ({ commit }, { shortlinkId, redirect }) => {
    return api.duplicateCompetition(shortlinkId, data => {
      commit(FETCH_COMPETITION, data.data);
      redirect(data.data.id);
    }).catch(handleNetworkError(commit));
  },
  [PUBLISH_COMPETITION]: ({ commit }, { competitionId }) => {
    return api.publishCompetition(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [UNPUBLISH_COMPETITION]: ({ commit }, { competitionId }) => {
    return api.unPublishCompetition(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_HEAT_SCORING]: ({ commit }, { competitionId }) => {
    return api.enableHeatScoring(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_HEAT_SCORING]: ({ commit }, { competitionId }) => {
    return api.disableHeatScoring(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_SCHEDULING]: ({ commit }, { competitionId }) => {
    return api.enableScheduling(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_SCHEDULING]: ({ commit }, { competitionId }) => {
    return api.disableScheduling(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_SPONSORSHIP]: ({ commit }, { competitionId }) => {
    return api.enableSponsorship(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_SPONSORSHIP]: ({ commit }, { competitionId }) => {
    return api.disableSponsorship(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_DEV_KIT]: ({ commit }, { competitionId }) => {
    return api.enableDevKit(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_DEV_KIT]: ({ commit }, { competitionId }) => {
    return api.disableDevKit(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_TEST]: ({ commit }, { competitionId }) => {
    return api.enableTest(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_TEST]: ({ commit }, { competitionId }) => {
    return api.disableTest(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [ENABLE_REFUNDS]: ({ commit }, { competitionId }) => {
    return api.enableRefunds(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_REFUNDS]: ({ commit }, { competitionId }) => {
    return api.disableRefunds(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [REQUEST_PUBLISH_COMPETITION]: ({ commit }, { competitionId }) => {
    return api.requestPublishCompetition(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_WORKOUT_ORDER]: ({ commit }, { competitionId, itemOrder }) => {
    return api.saveWorkoutOrder(competitionId, itemOrder, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_SEGMENT_ORDER]: ({ commit }, { competitionId, itemOrder }) => {
    return api.saveSegmentOrder(competitionId, itemOrder, data => {
      commit(SAVE_SEGMENT_ORDER, {competitionId, data: data.data});
    }).catch(handleNetworkError(commit));
  },

  [SAVE_INVITEE_ORDER]: ({ commit }, { id, itemOrder }) => {
    return api.saveInviteeOrder(id, itemOrder, data => {
      commit(FETCH_INVITATION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [DELETE_INVITEE]: ({ commit }, { code }) => {
    return api.deleteInvitee(code, data => {
      commit(FETCH_INVITATION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_TIMEZONE]: ({ commit }, { lat, lng }) => {
    return api.getTimezone(lat, lng, data => commit(FETCH_TIMEZONE, data.timezone)).catch(handleNetworkError(commit));
  },

  [SAVE_TIME_AND_PLACE]: ({ commit }, { competitionId, fields }) => {
    return api.updateCompetitionFields(competitionId, fields, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [STRIPE_CONNECT]: ({ commit }, { competitionId }) => {
    return api.updateCompetitionFields(competitionId, { stripeConnect: true }, () => { }).catch(handleNetworkError(commit));
  },
  [SAVE_STRIPE_ACCOUNT]: ({ commit }, { competitionId, stripeAccount, address }) => {
    return api.updateCompetitionFields(competitionId, { stripeAccount, address }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_REGISTRATION_DATES]: ({ commit }, { competitionId, fields }) => {
    return api.updateCompetitionFields(competitionId, fields, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_TICKETING_DATES]: ({ commit }, { competitionId, fields }) => {
    return api.updateCompetitionFields(competitionId, fields, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_REGISTRATION_FEES]: ({ commit }, { competitionId, fields }) => {
    // fields: {currency: '', divisions: [{id, registrationFee}]}
    const feeData = {
      currency: fields.currency,
      divisions: fields.divisions.map(d => {
        return {id: d.id, registrationFee: d.registrationFee};
      }),
    };
    return api.updateRegistrationFees(competitionId, feeData, data => {
      commit(FETCH_COMPETITION, data.data.competition);
      data.data.divisions.map(d => commit(FETCH_DIVISION, d));
    }).catch(handleNetworkError(commit));
  },

  [FETCH_SCORE_SET]: ({ commit }, { scoreSetId }) => {
    return api.getScoreSet(scoreSetId, data => {
      commit(FETCH_SCORE_SET, data.data);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_SCORING_MATRIX_ROW]: ({ commit }, { id, scoreSet, registration, athlete, scoringLinkId, bib }) => {
    let scoreSetMatrix;
    return api.saveScoringMatrixRow(id, scoreSet, registration, athlete, scoringLinkId, bib, data => {
      scoreSetMatrix = data.data;
    }).then(() => {
      return scoreSetMatrix;
    }).catch(handleNetworkError(commit));
  },
  [SAVE_SCORE]: ({ commit }, { id, scoreSet, registration, athlete, score, scoringLinkId, evidence}) => {
    let scoreSetMatrix;
    return api.saveScore( id, scoreSet, registration, athlete, score, scoringLinkId, evidence, data => {
      scoreSetMatrix = data.data;
    }).then(() => {
      return scoreSetMatrix;
    }).catch(handleNetworkError(commit));
  },
  [UPDATE_SCORE_VERIFICATION]: ({ commit }, { score, rejectReason, verified, scoringLinkId }) => {
    let scoreSetMatrix;
    return api.verifyScore(score, rejectReason, verified, scoringLinkId, data => {
      scoreSetMatrix = data.data;
    }).then(() => {
      return scoreSetMatrix;
    }).catch(handleNetworkError(commit));
  },

  [SAVE_ATHLETE_SCORE]: ({ commit }, { registrationId, teamMember, scoreData }) => {
    return api.saveAthleteScore(registrationId, teamMember, scoreData, data => {
      commit(SAVE_ATHLETE_SCORE, data.data);
    }).catch(handleNetworkError(commit));
  },
  [WITHDRAW_SCORE]: ({ commit }, { registrationId, scoreData }) => {
    return api.withdrawAthleteScore(registrationId, scoreData.id, data => {
      commit(SAVE_ATHLETE_SCORE, data.data);
    }).catch(handleNetworkError(commit));
  },

  [FETCH_GUEST_SCORING_LINKS]: ({ commit }) => api.getGuestScoringLinks(data => commit(FETCH_GUEST_SCORING_LINKS, data.data)).catch(handleNetworkError(commit)),

  [CREATE_FIELD]: ({ commit }, { competitionId, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices }) => {
    return api.createField(competitionId, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices, data => {
      commit(FETCH_PUBLIC_COMPETITION_FIELDS, [data.data]);
      commit(CREATE_FIELD, data.data);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_FIELD]: ({ commit }, { id, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices }) => {
    return api.saveField(id, label, helpText, fieldType, required, team, canFilterLeaderboard, divisions, choices, data => {
      commit(FETCH_PUBLIC_COMPETITION_FIELDS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_FIELD]: ({ commit }, { id }) => api.deleteField(id, () => {
      commit(DELETE_FIELD, id);
  }).catch(handleNetworkError(commit)),
  [SAVE_FIELD_ORDER]: ({ commit }, { competitionId, itemOrder }) => {
    return api.saveFieldOrder(competitionId, itemOrder, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [ENABLE_AUTO_PUBLISH]: ({ commit }, { competitionId }) => {
    return api.enableAutoPublish(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [DISABLE_AUTO_PUBLISH]: ({ commit }, { competitionId }) => {
    return api.disableAutoPublish(competitionId, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_COMPETITION_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_COMPETITION_HASHTAGS]: ({ commit }, { competitionId, hashtags }) => {
    return api.updateCompetitionFields(competitionId, { hashtags }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_THEME]: ({ commit }, { competitionId, theme, themeOverrides }) => {
    return api.updateCompetitionFields(competitionId, { theme, themeOverrides }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_REGISTRATION_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { registrationContent: content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_TICKETING_HEADER_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { ticketHeaderContent: content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_REGISTRATION_WAIVER_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { registrationWaiverContent: content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_DISTRIBUTION_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { distributionContent: content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_LEADERBOARD_DISTRIBUTION_CONTENT]: ({ commit }, { competitionId, content }) => {
    return api.updateCompetitionFields(competitionId, { distributionLeaderboardContent: content }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SAVE_ORGANIZATION_REGISTRATION_NOTIFICATIONS]: ({ commit }, { competitionId, sendOrganizationRegistrationNotifications }) => {
    return api.updateCompetitionFields(competitionId, { sendOrganizationRegistrationNotifications }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [SET_REGISTRATION_CAP]: ({ commit }, { competitionId, registrationCap }) => {
    return api.updateCompetitionFields(competitionId, { registrationCap }, data => {
      commit(FETCH_COMPETITION, data.data);
    }).catch(handleNetworkError(commit));
  },

  [CLEAR_TICKET_PURCHASE_ERROR]: ({ commit }) => commit(CLEAR_TICKET_PURCHASE_ERROR),
  [CLEAR_REGISTRATION_ERROR]: ({ commit }) => commit(CLEAR_REGISTRATION_ERROR),


  [ATHLETE_REGISTRATION_CREATE]: ({ commit }, { competitionId, paymentIntent, registrationData, hashtags, inviteCode }) => {
    return api.registerForCompetition(competitionId, paymentIntent, registrationData, hashtags, inviteCode, data => {
      handleRegistrationResponses(commit, data);
    }).catch(handleNetworkErrorOrRegistrationError(commit));
  },
  [ATHLETE_REGISTRATION_CHANGE_DIVISION]: ({ commit }, { registrationId, paymentIntent, divisionId }) => {
    return api.athleteChangeDivision(registrationId, paymentIntent, divisionId, data => {
      handleRegistrationResponses(commit, data);
    }).catch(handleNetworkErrorOrRegistrationError(commit));
  },
  [ATHLETE_REGISTRATION_BUY_MERCH]: ({ commit }, { registrationId, paymentIntent, couponCode }) => {
    return api.purchaseMerch(registrationId, paymentIntent, couponCode, data => {
      handleRegistrationResponses(commit, data);
    }).catch(handleNetworkErrorOrRegistrationError(commit));
  },
  [PURCHASE_COMPETITION_TICKETS]: ({ commit }, { competitionId, paymentIntent, name, email }) => {
    return api.purchaseTickets(competitionId, paymentIntent, name, email, data => {
      handleTicketResponses(commit, data);
    }).catch(handleNetworkErrorOrRegistrationError(commit));
  },
  [ATHLETE_REGISTRATION_UPDATE]: ({ commit }, { registrationId, leaderboardName, registrants, teamFields, hashtags }) => {
    return api.updateRegistrationDetails(registrationId, leaderboardName, registrants, teamFields, hashtags, data => {
      handleRegistrationResponses(commit, data);
    }).catch(handleNetworkError(commit));
  },

  [TOGGLE_NAV]: ({ commit }) => commit(TOGGLE_NAV),
  [CLOSE_NAV]: ({ commit }) => commit(CLOSE_NAV),

  [CLEAR_COMPETITION]: ({ commit }, { id }) => commit(CLEAR_COMPETITION, id),

  // [CREATE_HEAT_SCHEDULE]: ({ commit }, { id, config, transitionTime }) => api.createSchedule(id, config, transitionTime, data => {
  //   commit(CREATE_HEAT_SCHEDULE, data.data);
  // }).catch(handleNetworkError(commit)),

  // [FETCH_COMPETITION_SEGMENT_SCHEDULES]: ({ commit }, { id }) => api.fetchSchedules(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, data.data);
  // }).catch(handleNetworkError(commit)),
  // [FETCH_COMPETITION_HEATS]: ({ commit }, { id }) => api.fetchHeats(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_HEATS, data.data);
  // }).catch(handleNetworkError(commit)),
  // [FETCH_COMPETITION_LANES]: ({ commit }, { id }) => api.fetchLanes(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_LANES, data.data);
  // }).catch(handleNetworkError(commit)),
  [FETCH_COMPETITION_ATHLETE_ASSIGNMENTS]: ({ commit }, { id, eventConfigId, division, workout, day, stage, label }) => api.getAthleteAssignments(id, eventConfigId, division, workout, day, stage, label, data => {
    commit(FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS, data.data);
  }).catch(handleNetworkError(commit)),
  [FETCH_COMPETITION_JUDGES]: ({ commit }, { id, day, stage }) => api.getJudges(id, day, stage, data => {
    commit(FETCH_PUBLIC_COMPETITION_JUDGES, data.data);
  }).catch(handleNetworkError(commit)),

  [SCORING_EXPORT_QUEUE]: ({ commit }, { competitionId }) => api.queueScoringExport(competitionId, data => {
    commit(FETCH_COMPETITION, data.data);
  }).catch(handleNetworkError(commit)),

  [COMPETITION_EXPORT_QUEUE]: ({ commit }, { competitionId }) => api.queueCompetitionsExport(competitionId, data => {
    commit(FETCH_COMPETITION, data.data);
  }).catch(handleNetworkError(commit)),

  [FETCH_REGISTRATION_EXPORT_STATUS]: ({ commit }, { id }) => api.exportStatus(id, data => {
    commit(FETCH_REGISTRATION_EXPORT_STATUS, { id, ...data.data });
  }).catch(handleNetworkError(commit)),

  [SPECTATORS_EXPORT_QUEUE]: ({ commit }, { competitionId }) => api.queueSpectatorsExport(competitionId, data => {
    commit(FETCH_COMPETITION, data.data);
  }).catch(handleNetworkError(commit)),

  [FETCH_SPECTATORS_EXPORT_STATUS]: ({ commit }, { id }) => api.spectatorsExportStatus(id, data => {
    commit(FETCH_SPECTATORS_EXPORT_STATUS, { id, ...data.data });
  }).catch(handleNetworkError(commit)),

  [UPDATE_ATHLETE_ASSIGNMENT]: ({ commit }, { id, heat, lane, rank, remove, addBack }) => api.updateAssignment(id, heat, lane, rank, remove, addBack, data => {
    commit(UPDATE_ATHLETE_ASSIGNMENT, data.data);
  }).catch(handleNetworkError(commit)),
  [SWAP_ATHLETE_ASSIGNMENTS]: ({ commit }, { id, swapWith }) => {
    commit(SWAPPING_ATHLETE_ASSIGNMENTS, { id, swapWith });
    return api.swapAssignments(id, swapWith, data => {
      commit(SWAP_ATHLETE_ASSIGNMENTS, data.data);
    }).catch(handleNetworkError(commit));
  },

  [COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS]: ({ commit }, { id }) => api.reseedAssignments(id, false, data => {
    commit(FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS, data.data.assignments);
    commit(FETCH_TIME_BLOCKS, { data: data.data.timeBlocks });
    commit(COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS, data.data.toRemove);
  }).catch(handleNetworkError(commit)),
  [COMPETITION_SCHEDULE_UPDATE_FROM_LEADERBOARD]: ({ commit }, { id }) => api.reseedAssignments(id, true, data => {
    commit(FETCH_PUBLIC_COMPETITION_ATHLETE_ASSIGNMENTS, data.data.assignments);
    commit(FETCH_TIME_BLOCKS, { data: data.data.timeBlocks });
    commit(COMPETITION_SCHEDULE_RECALC_ASSIGNMENTS, data.data.toRemove);
  }).catch(handleNetworkError(commit)),

  // [SAVE_HEAT]: ({ commit }, { id, startAt, endAt }) => api.updateHeat(id, startAt, endAt, data => {
  //   commit(SAVE_HEAT, data.data);
  // }).catch(handleNetworkError(commit)),

  // [UPDATE_HEAT_SCHEDULE]: ({ commit, dispatch }, { id, config, transitionTime }) => api.updateSchedule(id, config, transitionTime, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, [data.data]);
  //   dispatch(FETCH_COMPETITION_HEATS, { id: data.data.competition });
  //   dispatch(FETCH_COMPETITION_LANES, { id: data.data.competition });
  // }).catch(handleNetworkError(commit)),

  // [PUBLISH_HEAT_SCHEDULE]: ({ commit }, { id }) => api.publishSchedule(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, [data.data]);
  // }).catch(handleNetworkError(commit)),
  // [UNPUBLISH_HEAT_SCHEDULE]: ({ commit }, { id }) => api.unpublishSchedule(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, [data.data]);
  // }).catch(handleNetworkError(commit)),
  // [PUBLISH_HEAT_ASSIGNMENTS]: ({ commit }, { id }) => api.publishHeatAssignments(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, [data.data]);
  // }).catch(handleNetworkError(commit)),
  // [UNPUBLISH_HEAT_ASSIGNMENTS]: ({ commit }, { id }) => api.unpublishHeatAssignments(id, data => {
  //   commit(FETCH_PUBLIC_COMPETITION_SEGMENT_SCHEDULES, [data.data]);
  // }).catch(handleNetworkError(commit)),

  [ASSIGN_JUDGE]: ({ commit }, { eventConfigId, lane, registrationId }) => api.assignJudge(eventConfigId, lane, registrationId, data => {
    commit(ASSIGN_JUDGE, data.data);
  }).catch(handleNetworkError(commit)),
  [REMOVE_JUDGE]: ({ commit }, { eventConfigId, lane }) => api.removeJudge(eventConfigId, lane, data => {
    commit(REMOVE_JUDGE, data.data);
  }).catch(handleNetworkError(commit)),

  [CREATE_TIME_BLOCK]: ({ commit }, { id, day, stage, title, start, end }) => api.createTimeBlock(id, day, stage, title, start, end, data => {
    commit(FETCH_TIME_BLOCKS, { data: [data.data] });
  }),
  [UPDATE_TIME_BLOCK]: ({ commit }, { id, title, start, end }) => api.updateTimeBlock(id, title, start, end, data => {
    commit(FETCH_TIME_BLOCKS, { data: [data.data] });
  }),
  [DELETE_TIME_BLOCK]: ({ commit }, { id }) => api.deleteTimeBlock(id, () => commit(DELETE_TIME_BLOCK, id)),
  [FETCH_TIME_BLOCKS]: ({ commit }, { id, day, stage }) => api.getTimeBlocks(id, day, stage, data => {
    commit(FETCH_TIME_BLOCKS, { data: data.data, day, stage });
  }),
  [GENERATE_EVENT_HEATS]: ({ commit }, { id, eventConfig }) => api.generateEventHeats(id, eventConfig, data => {
    commit(GENERATE_HEATS, data.data);
  }),
  [GENERATE_HEATS]: ({ commit }, { id, workout, division }) => api.generateHeats(id, workout, division, data => {
    commit(GENERATE_HEATS, data.data);
  }),

  [MOVE_HEATS_TO_EVENT_CONFIG]: ({ commit }, { id, timeBlocks }) => {
    return api.moveHeatsToEventConfig(id, timeBlocks, data => {
      commit(FETCH_EVENT_CONFIGS, data.data.events);
      commit(FETCH_TIME_BLOCKS, { data: data.data.timeBlocks });
    }).catch(handleNetworkError(commit));
  },
  [FIT_EVENT_CONFIG_TO_HEATS]: ({ commit }, { id }) => {
    return api.fitEventConfigToHeats(id, data => {
      commit(FETCH_EVENT_CONFIGS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_EVENT_CONFIGS]: ({ commit }, { id, division, workout, day, stage, label }) => api.getEventConfigs(id, division, workout, day, stage, label, data => {
    commit(FETCH_EVENT_CONFIGS, data.data);
  }).catch(handleNetworkError(commit)),
  [CREATE_EVENT_CONFIG]: ({ commit }, { id, day, division, workout, stage, start, mergeDivisions, fitToRegistrations }) => api.createEventConfig(id, day, division, workout, stage, start, mergeDivisions, fitToRegistrations, data => {
    commit(FETCH_EVENT_CONFIGS, [data.data]);
  }).catch(handleNetworkError(commit)),
  [SAVE_EVENT_CONFIG]: ({ commit }, { id, day, start, stage, duration, transitionTime, numberOfLanes, laneAssignmentStrategy }) => api.updateEventConfig(id, day, start, stage, duration, transitionTime, numberOfLanes, laneAssignmentStrategy, data => {
    commit(FETCH_EVENT_CONFIGS, [data.data]);
  }).catch(handleNetworkError(commit)),
  [DELETE_EVENT_CONFIG]: ({ commit }, { id }) => api.deleteEventConfig(id, () => {
    commit(DELETE_EVENT_CONFIG, id);
  }).catch(handleNetworkError(commit)),

  [IMPORT_ATHLETES]: ({ commit }, { id, athletes, scoringLinkId }) => api.importAthletes(id, athletes, scoringLinkId, data => {
    commit(FETCH_DIVISION, data.data);
  }).catch(handleNetworkError(commit)),

  [ACCEPT_TERMS]: ({ commit }) => api.acceptTerms(() => {
    commit(ACCEPT_TERMS);
  }).catch(handleNetworkError(commit)),

  [TURN_ON_LEADERBOARD_VIDEO_LINKS]: ({ commit }, { id }) => api.turnOnLeaderboardVideoLinks(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_OFF_LEADERBOARD_VIDEO_LINKS]: ({ commit }, { id }) => api.turnOffLeaderboardVideoLinks(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_ON_CUSTOM_REGISTRATION_NAMES]: ({ commit }, { id }) => api.turnOnCustomeRegistrationName(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_OFF_CUSTOM_REGISTRATION_NAMES]: ({ commit }, { id }) => api.turnOffCustomeRegistrationName(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_ON_UPDATE_REGISTRATION]: ({ commit }, { id }) => api.turnOnAthleteUpdateRegistration(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_OFF_UPDATE_REGISTRATION]: ({ commit }, { id }) => api.turnOffAthleteUpdateRegistration(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_ON_REGISTRATION_INVITE_REQUIRED]: ({ commit }, { id }) => api.turnOnRegistrationInviteRequired(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [TURN_OFF_REGISTRATION_INVITE_REQUIRED]: ({ commit }, { id }) => api.turnOffRegistrationInviteRequired(id, data => commit(FETCH_COMPETITION, data.data))
    .catch(handleNetworkError(commit)),
  [SET_LEADERBOARD_DISPLAY_PREFERENCES]: ({ commit }, { id, preferences }) => api.setLeaderboardDisplayPreferences(id, preferences, data => commit(FETCH_COMPETITION, data.data)),
  [CREATE_INVITATION]: ({ commit }, { id, label, message, expires, emailMustMatchToAccept }) => {
    let invitationId;
    return api.createInvitation(id, label, message, expires, emailMustMatchToAccept, data => {
      commit(FETCH_INVITATION, data.data);
      invitationId = data.data.id;
    }).then(() => {
      return { id: invitationId };
    }).catch(handleNetworkError(commit));
  },
  [SAVE_INVITATION]: ({ commit }, { id, message, label, expires, emailMustMatchToAccept }) => {
    return api.saveInvitation(id, label, message, expires, emailMustMatchToAccept, data => {
      commit(FETCH_INVITATION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [PUBLISH_INVITATION]: ({ commit }, { id }) => api.publishInvitation(id, data => {
    commit(FETCH_INVITATION, data.data);
  }).catch(handleNetworkError(commit)),
  [STOP_INVITATION]: ({ commit }, { id }) => api.stopInvitation(id, data => {
    commit(FETCH_INVITATION, data.data);
  }).catch(handleNetworkError(commit)),
  [FETCH_INVITATION]: ({ commit }, { id, withInvitees }) => api.getInvitation(id, withInvitees, data => {
    commit(FETCH_INVITATION, data.data);
  }).catch(handleNetworkError(commit)),
  [ADD_INVITEES]: ({ commit }, { id, division, emails, registrations }) => {
    return api.addInvitees(id, division, emails, registrations, data => {
      commit(FETCH_INVITATION, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_SPONSORS]: ({ commit }, { id }) => {
    return api.getCompetitionSponsors(id, data => {
      commit(FETCH_SPONSORS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [CREATE_SPONSOR]: ({ commit }, { competitionId, sponsor }) => {
    return api.createSponsor(competitionId, sponsor, data => {
      commit(FETCH_SPONSORS, [data.data]);
    }).catch(handleNetworkError(commit));
  }, [SAVE_SPONSOR]: ({ commit }, { id, sponsor }) => {
    return api.saveSponsor(id, sponsor, data => {
      commit(FETCH_SPONSORS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_SPONSOR]: ({ commit }, { id }) => {
    return api.deleteSponsor(id, () => {
      commit(DELETE_SPONSOR, id);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_AD_CAROUSELS]: ({ commit }, { id }) => {
    return api.getCompetitionAdCarousels(id, data => {
      commit(FETCH_AD_CAROUSELS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [CREATE_AD_CAROUSEL]: ({ commit }, { competitionId, adCarousel }) => {
    return api.createAdCarousel(competitionId, adCarousel, data => {
      commit(FETCH_AD_CAROUSELS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_AD_CAROUSEL]: ({ commit }, { id, adCarousel }) => {
    return api.saveAdCarousel(id, adCarousel, data => {
      commit(FETCH_AD_CAROUSELS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_AD_CAROUSEL]: ({ commit }, { id }) => {
    return api.deleteAdCarousel(id, () => {
      commit(DELETE_AD_CAROUSEL, id);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_AD_CAMPAIGNS]: ({ commit }, { id }) => {
    return api.getCompetitionAdCampaigns(id, data => {
      commit(FETCH_AD_CAMPAIGNS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_PUBLIC_COMPETITION_AD_CAMPAIGNS]: ({ commit }, { id }) => {
    return api.getPublicCompetitionAdCampaigns(id, data => {
      commit(FETCH_AD_CAMPAIGNS, data.data);
    }).catch(handleNetworkError(commit));
  },
  [CREATE_AD_CAMPAIGN]: ({ commit }, { competitionId, adCampaign }) => {
    return api.createAdCampaign(competitionId, adCampaign, data => {
      commit(FETCH_AD_CAMPAIGNS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [SAVE_AD_CAMPAIGN]: ({ commit }, { id, adCampaign }) => {
    return api.saveAdCampaign(id, adCampaign, data => {
      commit(FETCH_AD_CAMPAIGNS, [data.data]);
    }).catch(handleNetworkError(commit));
  },
  [DELETE_AD_CAMPAIGN]: ({ commit }, { id }) => {
    return api.deleteAdCampaign(id, () => {
      commit(DELETE_AD_CAMPAIGN, id);
    }).catch(handleNetworkError(commit));
  },
  [FETCH_GUEST_TICKET_LINKS]: ({ commit }) => api.getGuestTicketLinks(data => commit(FETCH_GUEST_TICKET_LINKS, data.data)).catch(handleNetworkError(commit)),

  [FETCH_TICKET_LINK]: ({ commit }, { id }) => api.getTicketLink(id, data => commit(FETCH_TICKET_LINK, data.data)).catch(handleNetworkError(commit)),
  [CREATE_TICKET_LINK]: ({ commit }, { competitionId }) => api.createTicketLink(competitionId, data => commit(FETCH_TICKET_LINK, data.data)).catch(handleNetworkError(commit)),
  [ACTIVATE_TICKET_LINK]: ({ commit }, { id }) => api.activateTicketLink(id, data => commit(FETCH_TICKET_LINK, data.data)).catch(handleNetworkError(commit)),
  [DEACTIVATE_TICKET_LINK]: ({ commit }, { id }) => api.deactivateTicketLink(id, data => commit(FETCH_TICKET_LINK, data.data)).catch(handleNetworkError(commit)),

  [START_TRIAL]: ({ commit }, { id, attribute, leadOnly }) => api.startCompetitionTrial(id, attribute, leadOnly).then((data) => commit(FETCH_COMPETITION, data.data)).catch(handleNetworkError(commit))
};
