<template>
    <div class="campaign-carousel">
        <carousel v-if="isActive" :slides="campaign.carousel.sponsors" :muted="campaign.carousel.muteLogos" :slideWidth="115">
            <template v-slot="slide">
                <component :is="slide.slide.link ? 'a' : 'div'" :href="slide.slide.link" target="_blank">
                    <img :src="slide.slide.logo">
                </component>
            </template>
        </carousel>
    </div>
</template>

<script>
import { toDate } from '../time';

export default {
    name: 'ad-campaign-carousel',
    props: ['campaign', 'preview'],
    computed: {
      timezone() {
        return this.$store.state.competitions[this.campaign.competitionId].timezone;
      },
      isActive() {
        if (this.preview) {
            return true;
        }
        const now = new Date();
        const start = toDate(this.campaign.startDate, this.timezone);
        const end = toDate(this.campaign.endDate, this.timezone);
        return start <= now && end >= now;
      }
    }
}
</script>
<style lang="scss">
@import '../../../scss/config';
</style>
