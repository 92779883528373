<template>
    <div class="date-time-picker">
      <datepicker
        :language="currentLocale"
        v-model="model.date"
        :inline="true"
        :placeholder="$t('selectDate')"
      />
      <time-picker
        class="mt-1 mb-2"
        v-model="model.time"
        :minute-interval="timeIncrement || 15"
        hide-clear-button
      />
    </div>
</template>
<script>
import TimePicker from 'throwdowns/components/TimePicker.vue';
import Datepicker from 'vuejs-datepicker';
import {en, es, pt, nbNO as nb} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'date-time-picker',
    data() {
        return {
            en,
            es,
            pt,
            nb,
            model: {
                date: null,
                time: '8:00',
            },
            windowWidth: 0,
        }
    },
    components: {
        Datepicker,
        TimePicker
    },
    props: {
        initialValue: String,
        timeIncrement: Number,
    },
    computed: {
        competition() {
            return this.$store.state.competitions[this.$route.params.competitionId];
        },
        currentLocale() {
          return this[this.$i18n.locale]
        },
        fullDate() {
            const { date, time } = this.model;
            if (!date || !time) return

            const year = date.getFullYear();
            const month = (date.getMonth() + 1 + '').padStart(2, '0');
            const day = (date.getDate() + '').padStart(2, '0');

            return `${year}-${month}-${day} ${time}`;
        },
        isDesktop() {
            return this.windowWidth > 767
        }
    },
    watch: {
        fullDate(newVal, oldVal) {
            this.$emit('changed', this.fullDate);
        }
    },
    created() {
        const [dateStr, time] = this.initialValue ? this.initialValue.split(' ') : [null, '08:00'];

        let date = null;
        if (dateStr !== null) {
          const [year, month, day] = dateStr.split('-');
          date = new Date(year, parseInt(month, 10) - 1, day);
        }
        this.model = { date, time }

        this.windowWidth = window.innerWidth
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        });
    }
}
</script>

<style lang="scss" scoped>
</style>
