import { render, staticRenderFns } from "./OptionsSelector.vue?vue&type=template&id=8dad15ca&scoped=true&"
import script from "./OptionsSelector.vue?vue&type=script&lang=js&"
export * from "./OptionsSelector.vue?vue&type=script&lang=js&"
import style0 from "./OptionsSelector.vue?vue&type=style&index=0&id=8dad15ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dad15ca",
  null
  
)

export default component.exports