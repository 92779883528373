<template>
    <div class="comp-card search-comp-card">
        <div class="inner-card-container">
            <lazy-image v-if="bannerImagePosterUrl" classes="card-img-top" :src="bannerImagePosterUrl" />
            <lazy-image v-else classes="card-img-top" :src="defaultImg" />
            <div class="comp-card-body">
                <h4 class="comp-card-title">
                    <router-link class="" target="_blank" :to="{name: 'compLandingPage', params: {competitionId: competition.id}}">
                        {{ title }}
                    </router-link>
                </h4>
                <div class="venue">
                    <div class="venue--name">{{ venueName }}</div>
                    <div class="venue--address">{{ venueAddress }}</div>
                </div>
                <div class="when">
                    <icon name="calendar" />
                    <formatted-date v-if="startDate" :date="startDate" :endDate="endDate" :timezone="competition.timezone" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import defaultImg from '../../../images/default-banner.jpg';

export default {
    name: 'search-competition-card',
    props: ['competition'],
    computed: {
        defaultImg() {
            return defaultImg;
        },
        newComp() {
            return false;
        },
        bannerImagePosterUrl() {
            return this.competition.bannerImagePosterUrl;
        },
        title() {
            return this.competition.title;
        },
        startDate() {
            return this.competition.startDate;
        },
        endDate() {
            return this.competition.endDate;
        },
        venueName() {
            return this.competition.venueName;
        },
        venueAddress() {
            return this.competition.venueAddress;
        },
    }
}
</script>


<style lang="scss" scoped>
    .search-comp-card {
        max-width: 375px;
        h4.comp-card-title {
            font-size: 24px;
        }
    }
    .venue {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .venue--name {
        font-size: 110%;
    }
//   .venue-map {
//       position: relative;
//       overflow: hidden;
//       height: 100px;
//       img {
//         position: absolute;
//         top: calc(50% - 300px);
//         left: calc(50% - 640px);
//       }
//   }
</style>
