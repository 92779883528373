<template>
    <div class="loader" v-show="!loaded"></div>
</template>
<script>
export default {
    name: 'loader',
    props: ['loaded']
}
</script>

