import { toDate } from '../time';
import { REGISTRATION_CATEGORY_ATHLETE } from '../constants';

const loaded = obj => Object.keys(obj).filter(k => k && obj[k] && Object.keys(obj[k]).length > 0);

const childLoaded = (getters, competition, key) => {
  return getters[key](competition).filter(o => o !== undefined && Object.keys(o).length > 0).length === competition[key].length;
};

const FIELDS_TO_LOAD = ['divisions', 'workouts', 'coupons', 'registrationFields', 'scoringPolicies'];

const isScoringOpen = (policy, timeZone) => {
  const now = new Date();
  const { scoreSubmissionOpens, scoreSubmissionCloses } = policy;
  const open = scoreSubmissionOpens && toDate(scoreSubmissionOpens, timeZone);
  const close = scoreSubmissionCloses && toDate(scoreSubmissionCloses, timeZone);
  return policy.athleteSubmits && now > open && now < close;
};

export default {
  totalItems: state => {
    return state.competitionOrder.length + FIELDS_TO_LOAD.reduce((acc, key) => {
      acc += Object.keys(state[key]).length;
      return acc;
    }, 0);
  },
  loadedItems: state => {
    return loaded(state.competitions).length + FIELDS_TO_LOAD.reduce((acc, key) => {
      acc += loaded(state[key]).length;
      return acc;
    }, 0);
  },
  competitionTotalItems: (_) => comp => {
    return FIELDS_TO_LOAD.reduce((acc, key) => {
      acc += comp[key].length;
      return acc;
    }, 0);
  },
  competitionLoadedItems: state => comp => {
    return FIELDS_TO_LOAD.reduce((acc, key) => {
      acc += comp[key].filter(id => Object.keys(state[key][id]).length > 0 ).length;
      return acc;
    }, 0);
  },
  competitionLoaded: (_, getters) => comp => {
    return getters.competitionLoadedItems(comp) === getters.competitionTotalItems(comp);
  },
  allCompetitionsLoaded: state => Object.keys(state.competitions).length === state.competitionOrder.length && state.loaded,
  registrationsByCompetition: state => {
    return state.account.registrations && state.account.registrations.reduce((map, r) => {
      map[r.competitionId] = [...(map[r.competitionId] || []), r];
      return map;
    }, {});
  },
  scoreSetsByCompetition: state => {
    return state.account.scoreSets && state.account.scoreSets.reduce((map, r) => {
      map[r.competitionId] = r;
      return map;
    }, {});
  },
  scoreSetsByRegistration: state => {
    return state.account.scoreSets && state.account.scoreSets.reduce((map, r) => {
      map[r.registration] = r;
      return map;
    }, {});
  },
  divisions: state => competition => competition.divisions.map(id => state.divisions[id]),
  divisionGroups: state => competition => competition.divisionGroups.map(id => state.divisionGroups[id]),
  saleItems: state => competition => competition.saleItems.map(id => state.saleItems[id]),
  athleteDivisions: (_, getters) => competition => getters.divisions(competition).filter(d => d.category === REGISTRATION_CATEGORY_ATHLETE),
  coupons: state => competition => competition.coupons.map(id => state.coupons[id]),
  stages: state => competition => competition.stages.map(id => state.stages[id]),
  segments: state => competition => competition.segments.map(id => state.segments[id]),
  workouts: state => competition => competition.workouts.map(id => state.workouts[id]),
  releasedWorkouts: state => competition => competition.workouts.map(id => state.workouts[id]).filter(w => w.released),
  registrationFields: state => competition => competition.registrationFields.map(id => state.registrationFields[id]).filter(f => f !== undefined),
  editableRegistrationFields: (_, getters) => competition => getters.registrationFields(competition).filter(f => f .editable),
  scoringPolicies: state => competition => {
    const policies = competition.scoringPolicies.map(id => state.scoringPolicies[id]);
    return policies.slice().sort((a, b) => competition.workouts.indexOf(a.workout) - competition.workouts.indexOf(b.workout));
  },
  // schedules: state => competition => competition.schedules.map(id => state.schedules[id]).filter(s => s && s.id !== undefined),
  // scheduleForSegment: (state, getters) => segment => getters.schedules(state.competitions[segment.competition]).filter(s => {
  //   return s.segment === segment.id;
  // })[0],

  publishedBlocks: state => competition => {
    // A block is either an EventConfig or a TimeBlock without a heat
    const configs = Object.keys(state.eventConfigs)
      .map(id => state.eventConfigs[id]);
    const blocks = Object.keys(state.timeBlocks)
      .map(id => state.timeBlocks[id]);

    return [ ...configs, ...blocks]
      .filter(block => block.published)
      .filter(block => block.competitionId === competition.id);
  },

  // athleteSchedule: (state, getters) => (competition, registration) => getters.publishedSchedules(competition).map(schedule => {
  //   if (schedule === null || schedule.publishedHeatAssignments === false) {
  //     return null;
  //   }
  //   const workouts = [];
  //   const athleteHeats = schedule.heats
  //     .filter(h => h.original_division === registration.division);

  //   athleteHeats.forEach(heat => {
  //     heat.athleteAssignments.forEach(athleteId => {
  //       const athlete = state.athleteAssignments[athleteId];
  //       if (athlete && athlete.registration === registration.id) {
  //         workouts.push({
  //           heatOffsets: [heat.startAt, heat.endAt],
  //           time: [heat.startAt, heat.endAt],
  //           division: state.divisions[athlete.division],
  //           workout: state.workouts[event.workout],
  //           lane: state.lanes[athlete.lane],
  //           heat,
  //           heatObj: heat,
  //         });
  //       }
  //     });
  //   });
  //   // workouts.sort((a, b) => a.heatOffsets[0] - b.heatOffsets[0]);
  //   return {
  //     ...schedule,
  //     workouts
  //   };
  // }).filter(s => s !== null),

  registrations: state => division => division.registrations.map(id => state.registrations[id]),
  scoreSets: state => division => division.scoreSets.map(id => state.scoreSets[id]),
  sponsors: state => competition => competition.sponsors.map(id => state.sponsors[id]),
  adCarousels: state => competition => competition.adCarousels.map(id => state.adCarousels[id]),
  adCampaigns: state => competition => competition.adCampaigns.map(id => state.adCampaigns[id]),

  registrationForCompetition: state => competition => {
    const userRegistrationIds = state.account.registrations.map(r => r.id);
    return competition.registrations && competition.registrations.filter(id => userRegistrationIds.indexOf(id) > -1)[0];
  },

  registrationOpen: () => competition => competition.isRegistrationOpen,

  ticketSalesOpen: () => competition => {
    if (competition.saleItems.length === 0) {
      return false;
    }
    const now = new Date();
    if (competition.ticketSalesOpen === null || competition.ticketSalesClose === null) {
      return false;
    }
    const opens = toDate(competition.ticketSalesOpen, competition.timezone);
    const closes = toDate(competition.ticketSalesClose, competition.timezone);
    return now > opens && now < closes;
  },

  scoringOpenForRegistration: state => registration => {
    const competition = state.competitions[registration.competitionId];
    const workouts = competition.workouts.map(id => state.workouts[id]);
    const division = state.divisions[registration.division];

    // @@@ could probably bail early from this.  i think this is pretty slow
    const isOpen = workouts.reduce((map, workout) => {
      const policy = competition.scoringPolicies
        .map(id => state.scoringPolicies[id])
        .filter(policy => policy.division === division.id && policy.workout === workout.id)[0];

      if (policy) {
        map |= isScoringOpen(policy, competition.timezone);
      } else {
        map |= false;
      }
      return map;
    }, false);
    return !!isOpen;
  },

  scoringOpenForWorkout: state => workout => {
    const competition = state.competitions[workout.competitionId];
    const policy = competition.scoringPolicies
      .map(id => state.scoringPolicies[id])
      .filter(policy => policy.workout === workout.id)[0];
    return isScoringOpen(policy, competition.timezone);
  },

  feeForCurrency: state => (fee, currency) => {
    if (state.constants.zeroCurrencies === undefined) {
      return;
    }
    const isZeroBased = state.constants.zeroCurrencies.indexOf(currency) > -1;

    if (!isZeroBased) {
      return fee / 100;
    }
    return fee;
  },
  rawFeeForCurrency: state => (feeForCurrency, currency) => {
    if (state.constants.zeroCurrencies === undefined) {
      return;
    }
    const isZeroBased = state.constants.zeroCurrencies.indexOf(currency) > -1;

    if (!isZeroBased) {
      return feeForCurrency * 100;
    }
    return feeForCurrency;
  },
  registrationFeeForDivision: (_, getters) => (division, currency) => {
    return getters.feeForCurrency(division.registrationFee, currency);
  },
  trifectaLoaded: (_, getters) => competition => {
    return getters.divisionsLoaded(competition) && getters.workoutsLoaded(competition) && getters.scoringPoliciesLoaded(competition);
  },
  divisionsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'divisions'),
  couponsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'coupons'),
  // stagesLoaded: (_, getters) => competition => childLoaded(getters, competition, 'stages'),
  // segmentsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'segments'),
  workoutsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'workouts'),
  registrationFieldsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'registrationFields'),
  scoringPoliciesLoaded: (_, getters) => competition => childLoaded(getters, competition, 'scoringPolicies'),
  // schedulesLoaded: (_, getters) => competition => childLoaded(getters, competition, 'schedules'),

  registrationsLoaded: (_, getters) => division => childLoaded(getters, division, 'registrations'),
  scoreSetsLoaded: (_, getters) => division => childLoaded(getters, division, 'scoreSets'),
  sponsorsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'sponsors'),
  adCarouselsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'adCarousels'),
  adCampaignsLoaded: (_, getters) => competition => childLoaded(getters, competition, 'adCampaigns'),

  permissions: state => {
    const config = window.ThrowdownsConfig || {};
    return {
      // Whether or not the user can act as an organizer
      organizer: config.CONSTANTS.staffOnlyOrganizer === false || state.account.staff || state.account.managedCompetitions.length > 0,
      staffDashboard: state.account.canViewStaffDashboard,
    };
  }
};
