<template>
  <modal>
    <div class="comments-container m-2">
      <h4>{{ $t('athleteComment') }}</h4>
      <span>{{ comments }}</span>
    </div>
    <div class="button-container m-2">
      <button class="btn btn-primary btn-block" @click.prevent="onClose">{{ $t('scoresubModalClose') }}</button>
    </div>
  </modal>
</template>
<script>
  export default {
    name: 'comments-modal',
    props: ['comments'],
    computed: {
      videoComments() {
        return this.comments.charAt(0).toUpperCase() + this.comments.slice(1);
      },
    },
    methods: {
      onClose() {
        this.$emit('commentsClose');
      }
    }
  }
</script>
