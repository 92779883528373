<template>
    <router-view />
</template>
<script>
import { Alert } from './components/Alert'

export default {
    created() {
      if(window.navigator.userAgent.includes("Trident")) {
        Alert({
          title: this.$t('unsupportedBrowHead'),
          label: this.$t('unsupportedBrowButton'),
          message: this.$t('unsupportedBrowDescr'),
          onok() {
            this.close();
          }
        })
      }
    },
    watch: {
        account: {
            immediate: true,
            handler() {
                if (this.account && this.account.language) {
                  this.$i18n.locale = this.account.language;
                }
            }
        },
        hasSetPassword: {
            immediate: true,
            handler() {
                if (this.hasSetPassword === false) {
                    this.$toasted.show(this.$t('needPassword'), {
                        position: 'bottom-center',
                        duration: null,
                        action: {
                            text: this.$t('setPassword'),
                            push: {
                                name: 'account'
                            }
                        }
                    });
                }
            }
        },
        networkErrored: {
            immediate: true,
            handler() {
                if (this.networkErrored) {
                    Alert({
                      title: this.$t('networkErrorHead'),
                      label: this.$t('networkErrorButton'),
                      message: this.$t('networkErrorDescr'),
                      onok() {
                        window.location = window.location;
                      }
                    })
                }
            }
        },
        newVersionAvailable: {
            immediate: true,
            handler() {
                if (this.newVersionAvailable) {
                    this.$toasted.show('There is a new version available.', {
                        position: 'top-center',
                        className: 'new-version-toast',
                        singleton: true,
                        duration: null,
                        action: {
                            text: 'Get it now!',
                            onClick: () => (window.location.reload(true)),
                        }
                    });
                }
            }
        }
    },
    computed: {
        newVersionAvailable() {
            return this.$store.state.newVersionAvailable;
        },
        networkErrored() {
            return this.$store.state.networkErrored;
        },
        browswerLang() {
            return navigator.language === 'en-US' ? 'en' : navigator.language;
        },
        locale() {
            return this.$i18n.locale;
        },
        account() {
            return this.$store.state.account;
        },
        hasSetPassword() {
            return this.$store.state.account && this.$store.state.account.hasSetPassword;
        }
    }
}
</script>

<style lang="scss">
.ql-align-center {
    text-align: center;
}
.ql-align-right {
    text-align: right;
}
blockquote {
    border-left: 6px solid #DDD;
    padding-left: 12px;
    font-style: italic;
    font-size: 1.2rem;
    margin-left: 24px;
}


@media (min-width: 600px) {
  div.toasted-container.top-center {
    left: 0; right: 0; top: 0;
    transform: none;
  }

  .toasted-container .toasted.new-version-toast {
    margin-top: 0;
    justify-content: center;
    font-size: 125%;
    padding: 40px;
  }
}

.alertify .ajs-modal {
    z-index: 10000;
}
.content-wrapper {
    p img {
        width: 100%;
    }
    ul[data-checked] {
        list-style: none;
        margin-left: 0;
        padding-inline-start: 0;
    }
    ul[data-checked=true] > li::before {
        content: '\2611';
        margin-right: 5px;
    }
    ul[data-checked=false] > li::before {
        content: '\2610';
        margin-right: 5px;
    }
}
</style>
