<template>
    <div v-if="!competition.published" class="published-competition-warning-overlay">
        <div class="message">
            <p class="lead">{{ $t('publishWarningHeader') }}</p>
            <p>{{ $t('publishWarningDescr') }}</p>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'published-competition-warning',
    props: ['competition'],
  }
</script>

<style lang="scss">
  @import '../../../scss/config';

  .published-competition-warning-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($gray-400, 0.6);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message {
        padding: 20px 30px;
        margin: 50px auto;
        background: $gray-100;
        display: inline-block;
        width: 400px;
        border-radius: 5px;
        box-shadow: 0 50px 150px rgba(0, 0, 0, 0.2);
    }
  }
</style>
