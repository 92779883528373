import Vue from 'vue';
import Vuex from 'vuex';

import { actions, getters, mutations, state } from './vuex';

const { NODE_ENV } = window.ThrowdownsConfig;

Vue.use(Vuex);

const debug = NODE_ENV !== 'production';

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  strict: true,
  debug
});
