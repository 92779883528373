<template>
    <div class="division-switch-item" @click.prevent="$emit('selected', division)">
        <icon name="check" v-if="selected" />
        {{ division.title }}
    </div>
</template>
<script>
export default {
    name: 'division-switch-item',
    props: ['selected', 'division'],
}
</script>
