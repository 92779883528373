<template>
    <span class="formatted-date">{{ formatted }}</span>
</template>
<script>
import { toDate } from '../time';
import VueI18n from 'vue-i18n';

export default {
    name: 'formatted-date',
    props: ['date', 'endDate', 'timezone', 'showTime'],
    computed: {
        competition() {
           return this.$store.state.competitions[this.$route.params.competitionId];
        },
        formatted() {
            const hasTime = this.date instanceof Date || this.date.indexOf('T') > -1 || this.date.indexOf(' ') > -1;
            const tz = this.timezone || (this.competition && this.competition.timezone);
            if (VueI18n.availabilities.dateTimeFormat) {
                const fmt = this.showTime ? 'long' : 'short';
                const start = this.$d(toDate(this.date, tz), fmt);
                if (!hasTime && !this.endDate) {
                  return this.$d(new Date(this.date + 'T00:00'), 'short');
                }
                if (this.endDate && start !== this.$d(toDate(this.endDate, this.timezone), fmt)) {
                    const end = this.$d(toDate(this.endDate, tz), fmt);
                    return `${start}-${end}`;
                }
                return start;
            } else {
                return this.endDate ? `${this.date}-${this.endDate}` : `${this.date}`;
            }
        }
    }
}
</script>

