import { LOWER_IS_BETTER } from './constants';

const validateHigherIsBetterScore = value => {
  const val = value.trim();
  return (val[val.length - 1] === '*' && !isNaN(val.slice(0, -1))) || !isNaN(val);
};

const validateEmail = value => {
  const reEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return reEmail.test(value);
};

const validateLowerIsBetterScore = value => {
  let valid = false;
  let val = value.trim();

  if (val[val.length - 1] === '*') {
    val = val.slice(0, -1).trim();
  }

  if (val.toLowerCase().indexOf('cap+') === 0 && !isNaN(val.slice(4))) {
    valid = true;
  } else if (((val.match(/:/g) || []).length === 1 || (val.match(/:/g) || []).length === 2) && val.indexOf(':') > 0) {
    const vals = val.split(':');
    if (vals.length === 2) {
      const [mins, secs] = vals;
      valid =
        !isNaN(mins) &&
        !isNaN(secs) &&
        secs.split('.')[0].length === 2 &&
        parseInt(mins, 10) < 60 &&
        parseInt(secs, 10) < 60 &&
        !val.endsWith('.');
    } else if (vals.length === 3) {
      const [hours, mins, secs] = vals;
      valid =
        !isNaN(hours) &&
        !isNaN(mins) &&
        !isNaN(secs) &&
        mins.length === 2 &&
        secs.split('.')[0].length === 2 &&
        parseInt(mins, 10) < 60 &&
        parseFloat(secs, 10) < 60 &&
        !val.endsWith('.');
    }
  } else {
    valid = !isNaN(val);
  }

  return valid;
};

const getDaysArray = (start, end) => {
  const arr = [];
  for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(dt);
  }
  return arr;
};

const positiveIntegerFormatter = (value) => {
  if (value === '') {
    return value;
  }
  if (isNaN(value) || value === null) {
    return '';
  }
  if (typeof value === 'string' && value.indexOf('.') > -1) {
    return '';
  }
  return value < 1 ? 1 : value;
};

const positiveIntegerFormatterZero = value => {
  if (value === '') {
    return 0;
  }
  if (isNaN(value) || value === null) {
    return 0;
  }
  if (typeof value === 'string' && value.indexOf('.') > -1) {
    return 0;
  }
  return value < 0 ? 0 : parseInt(value, 10);
};

const positiveDecimalFormatter = (value) => {
  if (value === '') {
    return value;
  }
  if (isNaN(value) || value === null) {
    return '';
  }
  return value < 1 ? 1 : value;
};

const usernameFormatter = (username) => {
  return username.match(/^[a-zA-Z0-9-_.]+$/) ? username : username.slice(0, username.length - 1);
}

const timeIsValidScore = (minutes, seconds) =>
  Math.sign(minutes) > -1 && Math.sign(seconds) > - 1 && seconds < 60;

const isValidUrl = (url) => !!url.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

const scoreFormatter = (score, policy) => {
  if (policy !== LOWER_IS_BETTER) {
    return score;
  }

  if (score === '') return score;

  /*
    00:10:07.980 should be trimmed to 10:07.98.
    00:00:59 should be trimmed to 00:59
    01:00:01.010 should be trimmed to 1:00:01.01
    00:01:07.100 Should be trimmed to 1:07.1

    Currently compromising on the millisecond 0's so that we don't take away the
    zeros in the seconds placeholder:

    00:05:30 becomes 5:30 (instead of previously, 5:3)
    00:05:00 becomes 5:00 (instead of previously, 5:)

    0 should remain 0.
  */
  score = score
    .replace(/^0+/, '')
    .replace(/\.0+$/, '')
    .replace(/^:/, '')
    .replace(/^0/, '')
    .replace(/^0/, '')
    .replace(/\.$/, '')
    .replace(/^:/, '00:');

  return score || '0';
};

export {
  validateEmail,
  validateHigherIsBetterScore,
  validateLowerIsBetterScore,
  getDaysArray,
  positiveIntegerFormatter,
  positiveDecimalFormatter,
  positiveIntegerFormatterZero,
  usernameFormatter,
  timeIsValidScore,
  isValidUrl,
  scoreFormatter,
};
